import * as generalConstants from "../../../../../_constants/generalConstants";
import { v4 as uuidv4 } from "uuid";
const copyMedicine = (orderInfo, copyInfo) => {
  //only can change group name is same!!!!
  const curMedicineResultWODelete = orderInfo.store_medicine_headers.filter(
    (item) => item.id !== 0
  );

  let curMedicineResult = curMedicineResultWODelete.map((item) => {
    const tempMedicineDetailResult = item.store_medicine_details?.map(
      (itemDetail) => {
        const detailResult = {
          ...itemDetail,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
        return detailResult;
      }
    );

    const tempMedicineDetailResultWONew = tempMedicineDetailResult.filter(
      (item) => item.id !== 0
    );

    const result = {
      ...item,
      status_type: generalConstants.DELETED_STATUS_TYPE,
      store_medicine_details: tempMedicineDetailResultWONew,
    };

    return result;
  });

  const tempMedicineResultWODelete = copyInfo.store_medicine_headers.filter(
    (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
  );

  //add new group
  const tempMedicineGroupResult = tempMedicineResultWODelete.map((item) => {
    const repeatDeletedMedicineIndex = curMedicineResult.findIndex(
      (indexItem) => indexItem.name === item.name
    );
    if (repeatDeletedMedicineIndex !== -1) {
      //change repeat deleted group name
      curMedicineResult[repeatDeletedMedicineIndex].name = uuidv4();
    }

    //filter group details
    const tempMedicineDetailResultWODelete = item.store_medicine_details.filter(
      (itemDetail) =>
        itemDetail.status_type === generalConstants.APPROVED_STATUS_TYPE
    );

    const tempMedicineDetailResult = tempMedicineDetailResultWODelete.map(
      (itemDetail) => {
        const detailResult = {
          branch_medicine: itemDetail.branch_medicine,
          id: 0,
          default_instruction: itemDetail.default_instruction,
          desc: itemDetail.branch_medicine.medicines.desc,
          dosage: itemDetail.branch_medicine.medicines.dosage,
          measure_unit: itemDetail.branch_medicine.medicines.measure_unit,
          medicine_name: itemDetail.branch_medicine.medicines.name,
          quantity_per_day: itemDetail.quantity_per_day,
          unit_selling_price:
            itemDetail.branch_medicine.medicines.unit_selling_price,
          status_type: itemDetail.status_type,
          remarks: itemDetail.remarks,
        };
        return detailResult;
      }
    );

    //result with details
    const result = {
      ...item,
      id: 0,
      delivery_owner: [],
      delivery_owned: null,
      // delivery_owned: item.delivery_owned?.id
      //   ? { ...item.delivery_owned, id: 0 }
      //   : null,
      store_medicine_details: tempMedicineDetailResult,
    };
    return result;
  });

  const copyMedicineInfo = {
    ...orderInfo,
    store_medicine_headers: [...curMedicineResult, ...tempMedicineGroupResult],
  };

  return copyMedicineInfo;
};

export default copyMedicine;
