import { createAsyncTypes } from "../_helper/Utilities";

export const PUT_INVOICEWIPMODE_ASYNC = createAsyncTypes("PUT_INVOICEWIPMODE");

export const PUT_INVOICEPENDINGSTATUS_ASYNC = createAsyncTypes(
  "PUT_INVOICEPENDINGSTATUS"
);

export const GET_INVOICELIST_ASYNC = createAsyncTypes("GET_INVOICELIST");

export const GET_INVOICESUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_INVOICESUMMARYLIST"
);

export const GET_INVOICEBYID_ASYNC = createAsyncTypes("GET_INVOICEBYID");

export const GET_INVOICEBYORDERNUM_ASYNC = createAsyncTypes(
  "GET_INVOICEBYORDERNUM"
);

export const POST_SUBMITINVOICE_ASYNC = createAsyncTypes("POST_SUBMITINVOICE");

export const POST_CREATEINVOICEBYID_ASYNC = createAsyncTypes(
  "POST_CREATEINVOICEBYID"
);

export const PUT_INVOICEPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_INVOICEPRINTDATE"
);

export const POST_OVERRIDEINVOICEAMOUNT_ASYNC = createAsyncTypes(
  "POST_OVERRIDEINVOICEAMOUNT"
);
