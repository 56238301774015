import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const invoiceApis = {
  putInvoiceWIPModeApi,
  putInvoicePendingStatusApi,
  getInvoiceListApi,
  getInvoiceSummaryListApi,
  getInvoiceByIdApi,
  getInvoiceByOrderNumApi,
  postSubmitInvoiceApi,
  postCreateInvoiceByIdApi,
  putInvoicePrintDateApi,
  postOverrideInvoiceAmountApi,
};

async function putInvoiceWIPModeApi(model) {
  const url = apiConstants.apiPutInvoiceWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
async function putInvoicePendingStatusApi(model) {
  const url = apiConstants.apiPutInvoicePendingStatus + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetInvoiceList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetInvoiceSummaryList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceByIdApi(model) {
  const url = apiConstants.apiGetInvoiceById + model;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getInvoiceByOrderNumApi(model) {
  const url = apiConstants.apiGetInvoiceByOrderNum + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitInvoiceApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSubmitInvoice, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postCreateInvoiceByIdApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostCreateInvoiceById, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putInvoicePrintDateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(apiConstants.apiPutInvoicePrintDate, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postOverrideInvoiceAmountApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostOverrideInvoiceAmount,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default invoiceApis;
