//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
//actions

import { getDeliveryList } from "../../../actions/deliveryActions";

import {
  getDeliveryCourierList,
  postSubmitDeliveryOrder,
  postSaveDeliveryOrder,
} from "../../../actions/deliveryOrderActions";

//components
import { ReactComponent as RemoveLogo } from "../../../assets/coolicons/basic/trash_empty.svg";
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import * as pageConfigure from "../../../_constants/pageConstants";
import ConfirmDialog from "../../../components/ConfirmDialog";
import * as generalConstants from "../../../_constants/generalConstants";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import PageAction from "../../../components/PageAction";
import { useStyles } from "../../../components/globalStyles";
import StyledCheckbox from "../../../components/StyledCheckbox";
import DetailDeliveryPage from "./DetailDeliveryPage";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import SingleGroupOrderDetailPage from "../Store/SingleGroupOrderDetailPage";

export default function EditDeliveryPage({
  orderInfo,
  setDeliveryOrderListView,
}) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();

  const columns = [
    {
      Header: "",
      accessor: "checkDeliveryOrder",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledCheckbox
              checked={deliveryOrderDetailInfo.some(
                (e) =>
                  e.delivery_header.id === row.original.id &&
                  e.status_type === generalConstants.APPROVED_STATUS_TYPE
              )}
              name="checkDeliveryOrder"
              onChange={(e) => handleDeliveryOrderCheck(e, row)}
            />
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
      sortable: true,
    },
    {
      Header: t("translation:Group"),
      sortable: false,
      accessor: "store_medicine_header.name",
    },
    {
      Header: t("translation:Recipient"),
      sortable: false,
      accessor: "store_medicine_header.delivery_name",
      Cell: ({ row }) => {
        if (row.original.store_medicine_header.is_delivery_services === true) {
          return row.original.store_medicine_header.delivery_name;
        } else {
          return row.original.store_medicine_header.delivery_owned
            ? row.original.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_name
            : "";
        }
      },
    },
    {
      Header: t("translation:Postal Code"),
      sortable: false,
      accessor: "store_medicine_header.delivery_postal_code",
      Cell: ({ row }) => {
        if (row.original.store_medicine_header.is_delivery_services === true) {
          return row.original.store_medicine_header.delivery_postal_code;
        } else {
          return row.original.store_medicine_header.delivery_owned
            ? row.original.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_postal_code
            : "";
        }
      },
    },
    {
      Header: t("translation:Address"),
      sortable: false,
      accessor: "store_medicine_header.delivery_address",
      Cell: ({ row }) => {
        if (row.original.store_medicine_header.is_delivery_services === true) {
          return row.original.store_medicine_header.delivery_address;
        } else {
          return row.original.store_medicine_header.delivery_owned
            ? row.original.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_address
            : "";
        }
      },
    },
    {
      Header: t("translation:Remarks"),
      sortable: false,
      accessor: "store_medicine_header.delivery_remarks",

      Cell: ({ row }) => {
        if (row.original.store_medicine_header.is_delivery_services === true) {
          return row.original.store_medicine_header.delivery_remarks;
        } else {
          return row.original.store_medicine_header.delivery_owned
            ? row.original.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_remarks
            : "";
        }
      },
    },
    {
      Header: t("translation:Link Delivery Services"),
      Cell: ({ row }) => {
        if (
          row.original.store_medicine_header.delivery_owned?.status_type ===
          generalConstants.APPROVED_STATUS_TYPE
        ) {
          return (
            row.original.store_medicine_header.delivery_owned
              .delivery_linked_store_medicine_header.store_header.order_number +
            "/" +
            row.original.store_medicine_header.delivery_owned
              .delivery_linked_store_medicine_header.name
          );
        } else if (
          row.original.store_medicine_header.delivery_owner.some(
            (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
          )
        ) {
          let result = row.original.store_medicine_header.delivery_owner.reduce(
            (a, b) => {
              if (b.status_type === generalConstants.APPROVED_STATUS_TYPE) {
                return a + 1;
              }
              return a;
            },
            0
          );
          return result + " " + t("translation:Group");
        } else return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
    {
      Header: t("translation:Delivery Order"),
      accessor: "approved_delivery_order_details",
      sortable: false,
      Cell: ({ cell }) => {
        let deliveryOrderString = null;
        cell.value.map(
          (item) =>
            (deliveryOrderString =
              (deliveryOrderString ? deliveryOrderString + " / " : "") +
              item.delivery_order_header.order_number)
        );

        return deliveryOrderString;
      },
    },
  ];

  const viewColumns = [
    {
      Header: "",
      accessor: "Remove",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => {
              handleDeleteDO(row.original);
            }}>
            <RemoveLogo />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original.delivery_header)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: t("translation:Store Order No"),
      sortable: false,
      accessor: "delivery_header.store_header.order_number",
    },

    {
      Header: t("translation:Group"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.name",
    },
    {
      Header: t("translation:Recipient"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.delivery_name",
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_name;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_name
            : "";
        }
      },
    },
    {
      Header: t("translation:Postal Code"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.delivery_postal_code",
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_postal_code;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_postal_code
            : "";
        }
      },
    },
    {
      Header: t("translation:Address"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.delivery_address",
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_address;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_address
            : "";
        }
      },
    },
    {
      Header: t("translation:Remarks"),
      sortable: false,
      accessor: "delivery_header.store_medicine_header.delivery_remarks",

      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header
            .is_delivery_services === true
        ) {
          return row.original.delivery_header.store_medicine_header
            .delivery_remarks;
        } else {
          return row.original.delivery_header.store_medicine_header
            .delivery_owned
            ? row.original.delivery_header.store_medicine_header.delivery_owned
                .delivery_linked_store_medicine_header.delivery_remarks
            : "";
        }
      },
    },
    {
      Header: t("translation:Link Delivery Services"),
      Cell: ({ row }) => {
        if (
          row.original.delivery_header.store_medicine_header.delivery_owned
            ?.status_type === generalConstants.APPROVED_STATUS_TYPE
        ) {
          return (
            row.original.delivery_header.store_medicine_header.delivery_owned
              .delivery_linked_store_medicine_header.store_header.order_number +
            "/" +
            row.original.delivery_header.store_medicine_header.delivery_owned
              .delivery_linked_store_medicine_header.name
          );
        } else if (
          row.original.delivery_header.store_medicine_header.delivery_owner.some(
            (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
          )
        ) {
          let result =
            row.original.delivery_header.store_medicine_header.delivery_owner.reduce(
              (a, b) => {
                if (b.status_type === generalConstants.APPROVED_STATUS_TYPE) {
                  return a + 1;
                }
                return a;
              },
              0
            );
          return result + " " + t("translation:Group");
        } else return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      sortable: false,
      accessor: (d) => {
        if (d.delivery_header)
          return moment
            .utc(d.delivery_header.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "delivery_header.status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    search_delivery_order_number_value: "",
    //only serach new, and pending
    search_status_type_value: [
      generalConstants.PENDING_STATUS_TYPE,
      generalConstants.NEW_STATUS_TYPE,
    ],
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_store_order_number_value: null,
    order_by_field: null,
    order_by_direction: null,
  });

  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_store_order_number_value: null,
    order_by_field: null,
    order_by_direction: null,
  });

  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const delivery = useSelector((state) => state.delivery);
  const deliveryOrder = useSelector((state) => state.deliveryOrder);

  const common = useSelector((state) => state.common);

  const [orderSubmitView, setOrderSubmitView] = useState(false);
  const [orderSaveView, setOrderSaveView] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [deliveryCourier, setDeliveryCourier] = useState(orderInfo.courier);
  const [deliveryOrderDetailInfo, setDeliveryOrderDetailInfo] = useState(
    orderInfo.delivery_order_details
  );
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [saveSuccessAlarm, setSaveSuccessAlarm] = useState(false);
  const [saveErrorAlarm, setSaveErrorAlarm] = useState(false);

  const [orderDetailView, setOrderDetailView] = useState(false);

  const [orderDetailInfo, setOrderDetailInfo] = useState([]);
  const [orderSubmitInfo, setOrderSubmitInfo] = useState([]);
  const [orderSaveInfo, setOrderSaveInfo] = useState([]);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
  const prevSubmitDeliveryOrderStatus = useRef();
  const prevSaveDeliveryOrderStatus = useRef();
  //functions
  const onSave = (event) => {
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();

    if (checkVal) {
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
      let submission = null;
      submission = {
        delivery_order_header: {
          delivery_order_details: deliveryOrderDetailInfo,
          courier: deliveryCourier,
          id: orderInfo.id,
          order_number: orderInfo.order_number,
        },
      };
      //
      dispatch(postSaveDeliveryOrder.pending(submission));
    }
  };

  const onSubmit = (event) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    let submission = null;
    submission = {
      delivery_order_header: {
        delivery_order_details: deliveryOrderDetailInfo,
        courier: deliveryCourier,
        id: orderInfo.id,
        order_number: orderInfo.order_number,
      },
    };
    dispatch(postSubmitDeliveryOrder.pending(submission));
  };
  const onConfirmSubmit = (event, action) => {
    setConfirmSubmitOpen(true);
  };
  const handleDeliveryOrderCheck = (e, row) => {
    const repeatedExistingSelectionIndex = deliveryOrderDetailInfo.findIndex(
      (item) => item.delivery_header.id === row.original.id && item.id !== 0
    );
    if (repeatedExistingSelectionIndex !== -1) {
      const deliveryOrderDetail = deliveryOrderDetailInfo;

      if (e.target.checked) {
        deliveryOrderDetail[repeatedExistingSelectionIndex] = {
          ...deliveryOrderDetail[repeatedExistingSelectionIndex],
          status_type: generalConstants.APPROVED_STATUS_TYPE,
        };
        setDeliveryOrderDetailInfo([...deliveryOrderDetail]);
      } else {
        deliveryOrderDetail[repeatedExistingSelectionIndex] = {
          ...deliveryOrderDetail[repeatedExistingSelectionIndex],
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
        setDeliveryOrderDetailInfo([...deliveryOrderDetail]);
      }
    } else {
      if (e.target.checked) {
        setDeliveryOrderDetailInfo((deliveryOrderDetailInfo) => [
          ...deliveryOrderDetailInfo,
          {
            delivery_header: row.original,
            id: 0,
            status_type: generalConstants.APPROVED_STATUS_TYPE,
          },
        ]);
      } else {
        setDeliveryOrderDetailInfo((deliveryOrderDetailInfo) => [
          ...deliveryOrderDetailInfo.filter(
            (e) => e.delivery_header.id !== row.original.id
          ),
        ]);
      }
    }
  };

  const handleDeleteDO = (data) => {
    const deliveryOrderDetail = deliveryOrderDetailInfo;
    if (data.id) {
      const deletedDeliveryOrderDetailInfo = deliveryOrderDetailInfo.findIndex(
        (item) => item.id === data.id
      );
      deliveryOrderDetail[deletedDeliveryOrderDetailInfo] = {
        ...deliveryOrderDetail[deletedDeliveryOrderDetailInfo],
        status_type: generalConstants.DELETED_STATUS_TYPE,
      };
      setDeliveryOrderDetailInfo([...deliveryOrderDetail]);
    } else {
      const deletedDeliveryOrderDetailInfo = deliveryOrderDetail.filter(
        (item) => item.delivery_header.id !== data.delivery_header.id
      );

      setDeliveryOrderDetailInfo(deletedDeliveryOrderDetailInfo);
    }
  };

  const handleRowDetail = (value) => {
    //

    setOrderDetailInfo(value);
    setOrderDetailView(true);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_store_order_number_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_store_order_number_value: null,
      });
    }
  };

  //useEffects
  useEffect(() => {
    prevSubmitDeliveryOrderStatus.current = false;
    prevSaveDeliveryOrderStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingSubmitDeliveryOrder !==
      prevSubmitDeliveryOrderStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitDeliveryOrderStatus.current =
        deliveryOrder.isLoadingSubmitDeliveryOrder;

      if (
        deliveryOrder.postSubmitDeliveryOrderIsSuccess === true &&
        deliveryOrder.isLoadingSubmitDeliveryOrder === false
      ) {
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        setOrderSubmitInfo(
          deliveryOrder.submitDeliveryOrder.delivery_order_header
        );
        setOrderSubmitView(true);
        window.scrollTo(0, 0);
      }
      if (
        deliveryOrder.postSubmitDeliveryOrderIsSuccess === false &&
        deliveryOrder.isLoadingSubmitDeliveryOrder === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(deliveryOrder.error.postSubmitDeliveryOrder);
        window.scrollTo(0, 0);
      }
    }
  }, [
    deliveryOrder.postSubmitDeliveryOrderIsSuccess,
    deliveryOrder.isLoadingSubmitDeliveryOrder,
    deliveryOrder.error,
    deliveryOrder.submitDeliveryOrder,
  ]);

  useEffect(() => {
    if (
      deliveryOrder.isLoadingSaveDeliveryOrder !==
      prevSaveDeliveryOrderStatus.current
    ) {
      //check the previous ref with current state
      prevSaveDeliveryOrderStatus.current =
        deliveryOrder.isLoadingSaveDeliveryOrder;

      if (
        deliveryOrder.postSaveDeliveryOrderIsSuccess === true &&
        deliveryOrder.isLoadingSaveDeliveryOrder === false
      ) {
        setSaveErrorAlarm(false);
        setSaveSuccessAlarm(true);
        setOrderSaveInfo(deliveryOrder.saveDeliveryOrder.delivery_order_header);
        setOrderSaveView(true);
        window.scrollTo(0, 0);
      }
      if (
        deliveryOrder.postSaveDeliveryOrderIsSuccess === false &&
        deliveryOrder.isLoadingSaveDeliveryOrder === false
      ) {
        setSaveErrorAlarm(true);
        setSaveSuccessAlarm(false);
        setMsgAlarm(deliveryOrder.error.postSaveDeliveryOrder);
        window.scrollTo(0, 0);
      }
    }
  }, [
    deliveryOrder.postSaveDeliveryOrderIsSuccess,
    deliveryOrder.isLoadingSaveDeliveryOrder,
    deliveryOrder.error,
    deliveryOrder.saveDeliveryOrder,
  ]);

  useEffect(() => {
    dispatch(getDeliveryList.pending(pageInfo));
    dispatch(getDeliveryCourierList.pending());
  }, [dispatch, pageInfo]);

  if (
    common.getGeneralStatusIsSuccess === true &&
    !orderSaveView &&
    !orderSubmitView &&
    !orderDetailView
  ) {
    return (
      <>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmSubmitOpen}
          setOpen={setConfirmSubmitOpen}
          onConfirm={onSubmit}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <Collapse in={saveErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSaveErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <br />
        <Typography display="block" variant="h4">
          {orderInfo.order_number}
        </Typography>

        <form onSubmit={handleSubmit(onConfirmSubmit)}>
          <Grid item xs={12}>
            <SortingSearchTable
              searchValue={searchValue}
              searchPlaceholder={t("translation:Store Order No")}
              columns={columns}
              data={
                delivery.deliveryList
                  ? delivery.deliveryList.delivery_headers
                  : []
              }
              pageInfo={pageInfo}
              setPageInfo={setPageInfo}
              pageCount={
                delivery.deliveryList ? delivery.deliveryList.total_pages : 0
              }
              loading={delivery.isLoadingDeliveryList}
              totalItems={
                delivery.deliveryList ? delivery.deliveryList.total_items : 0
              }
              handleSearch={handleSearch}
              searchDate={true}
              searchDatePlaceholder={t("translation:Creation Date")}
            />
          </Grid>
          {deliveryOrder.deliveryCourierList && (
            <Grid item xs={12} className={classes.userInfoField}>
              <Autocomplete
                value={deliveryCourier}
                disableClearable
                name="courier"
                id="courier"
                options={deliveryOrder.deliveryCourierList.couriers}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                style={{ width: "100%" }}
                onChange={(e, option) => setDeliveryCourier(option)}
                renderInput={(params) => (
                  <TextField
                    required
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Courier")}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}

          {deliveryOrderDetailInfo.filter(
            (e) => e.status_type === generalConstants.APPROVED_STATUS_TYPE
          ).length > 0 && (
            <Grid item xs={12}>
              <SortingSearchTable
                disabledSearch
                statusOption={common.generalStatusList.status_general}
                columns={viewColumns}
                data={deliveryOrderDetailInfo.filter(
                  (e) => e.status_type === generalConstants.APPROVED_STATUS_TYPE
                )}
                pageInfo={viewPageInfo}
                setPageInfo={setViewPageInfo}
                totalItems={deliveryOrderDetailInfo.length}
                disabledFooter
              />
            </Grid>
          )}

          <PageAction
            onSave={onSave}
            setView={() => setDeliveryOrderListView(false)}
          />
        </form>
      </>
    );
  } else if (orderSubmitInfo && orderSubmitView) {
    return (
      <div>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            onClick={() => {
              setSubmitSuccessAlarm(false);
            }}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            cy_data="alertSubmitOrder">
            {t("translation:Order has been submitted")}
          </Alert>
        </Collapse>
        {deliveryOrder.submitDeliveryOrder && (
          <DetailDeliveryPage
            orderInfo={orderSubmitInfo}
            setDeliveryOrderListView={setDeliveryOrderListView}
          />
        )}
      </div>
    );
  } else if (orderSaveInfo && orderSaveView) {
    return (
      <div>
        <Collapse in={saveSuccessAlarm}>
          <Alert
            onClick={() => {
              setSaveSuccessAlarm(false);
            }}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            cy_data="alertSaveOrder">
            {t("translation:Order has been saved")}
          </Alert>
        </Collapse>
        {deliveryOrder.saveDeliveryOrder && (
          <DetailDeliveryPage
            orderInfo={orderSaveInfo}
            setDeliveryOrderListView={setDeliveryOrderListView}
          />
        )}
      </div>
    );
  } else if (orderDetailView) {
    return (
      <SingleGroupOrderDetailPage
        orderDetailInfo={orderDetailInfo}
        setOrderDetailView={setOrderDetailView}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
