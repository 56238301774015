import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import StoreInvoiceReportPage from "./ReportSummary/StoreInvoiceReportPage";
import VisitInvoiceReportPage from "./ReportSummary/VisitInvoiceReportPage";
import StoreMedicineCostingInvoiceReportPage from "./ReportSummary/StoreMedicineCostingInvoiceReportPage";
import VisitMedicineCostingInvoiceReportPage from "./ReportSummary/VisitMedicineCostingInvoiceReportPage";

import DeliveryRecipientReportPage from "./ReportSummary/DeliveryRecipientReportPage";

import OrderDetailsReportPage from "./ReportSummary/OrderDetailsReportPage";

import AcupiontReportPage from "./ReportSummary/AcupiontReportPage";

import TreatmentReportPage from "./ReportSummary/TreatmentReportPage";

import MedicineReportPage from "./ReportSummary/MedicineReportPage";

import FixedMedicinePriceReportPage from "./ReportSummary/FixedMedicinePriceReportPage";

import FormulasReportPage from "./ReportSummary/FormulasReportPage";

import FormulaMedicineReportPage from "./ReportSummary/FormulaMedicineReportPage";

import BranchMedicineReportPage from "./ReportSummary/BranchMedicineReportPage";

import VisitReportPage from "./ReportSummary/VisitReportPage";

import UserCreditsReportPage from "./ReportSummary/UserCreditsReportPage";

import PurchaseOrderReportPage from "./ReportSummary/PurchaseOrderReportPage";

export default function ReportPage() {
  return (
    <Switch>
      <Redirect exact={true} from="/report" to="/report/SIR" />
      <Route path="/report/SIR" component={StoreInvoiceReportPage} />
      <Route
        path="/report/SMCIR"
        component={StoreMedicineCostingInvoiceReportPage}
      />
      <Route path="/report/VIR" component={VisitInvoiceReportPage} />
      <Route
        path="/report/VMCIR"
        component={VisitMedicineCostingInvoiceReportPage}
      />
      <Route
        path="/report/deliveryRecipient"
        component={DeliveryRecipientReportPage}
      />

      <Route path="/report/Visit" component={VisitReportPage} />

      <Route path="/report/orderDetails" component={OrderDetailsReportPage} />

      <Route path="/report/Acupoint" component={AcupiontReportPage} />

      <Route path="/report/Treatment" component={TreatmentReportPage} />

      <Route path="/report/Medicine" component={MedicineReportPage} />

      <Route path="/report/Formulas" component={FormulasReportPage} />

      <Route
        path="/report/FormulaMedicine"
        component={FormulaMedicineReportPage}
      />

      <Route
        path="/report/BranchMedicine"
        component={BranchMedicineReportPage}
      />

      <Route
        path="/report/BranchMedicine"
        component={BranchMedicineReportPage}
      />

      <Route
        path="/report/FixedMedicinePrice"
        component={FixedMedicinePriceReportPage}
      />

      <Route path="/report/PurchaseOrder" component={PurchaseOrderReportPage} />

      <Route path="/report/UserCredits" component={UserCreditsReportPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
