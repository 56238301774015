import { createAsyncTypes } from "../_helper/Utilities";

export const POST_CREATEPRESIGNEDURL_ASYNC = createAsyncTypes(
  "POST_CREATEPRESIGNEDURL"
);

export const POST_CREATEPRESIGNEDJSON_ASYNC = createAsyncTypes(
  "POST_CREATEPRESIGNEDJSON"
);

export const POST_CREATEPRESIGNEDURLPHOTO_ASYNC = createAsyncTypes(
  "POST_CREATEPRESIGNEDURLPHOTO"
);

export const POST_CREATEPRESIGNEDJSONPHOTO_ASYNC = createAsyncTypes(
  "POST_CREATEPRESIGNEDJSONPHOTO"
);

export const POST_CREATEPRESIGNEDURLSIGNATURE_ASYNC = createAsyncTypes(
  "POST_CREATEPRESIGNEDURLSIGNATURE"
);

export const POST_CREATEPRESIGNEDJSONSIGNATURE_ASYNC = createAsyncTypes(
  "POST_CREATEPRESIGNEDJSONSIGNATURE"
);

export const POST_CREATEPRESIGNEDURLORGANISATIONLOGO_ASYNC = createAsyncTypes(
  "POST_CREATEPRESIGNEDURLORGANISATIONLOGO"
);
