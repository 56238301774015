import { createAsyncTypes } from "../_helper/Utilities";

export const GET_DELIVERYLIST_ASYNC = createAsyncTypes("GET_DELIVERYLIST");

export const POST_SUBMITDELIVERY_ASYNC = createAsyncTypes(
  "POST_SUBMITDELIVERY"
);

export const PUT_DELIVERYPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYPRINTDATE"
);
