import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const dispensaryApis = {
  putStoreOrderStatusApi,
  getDispensaryListApi,
  getDispensaryByIdApi,
  putDispensaryWIPModeApi,
  postSubmitDispensaryApi,
  getDispensarySummaryListApi,
  putDispensaryPendingStatusApi,
  putDispensaryPrintDateApi,
  putDispensaryVisitPrintDateApi,
  putDispensaryStorePrintDateApi,
  getDispensaryInvoiceOrderApi,
  putDispensaryInvoicePrintDateApi,
};

async function putStoreOrderStatusApi(model) {
  const url = apiConstants.apiPutStoreOrderStatusById + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDispensaryPendingStatusApi(model) {
  const url = apiConstants.apiPutDispensaryPendingStatus + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDispensaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetDispensaryList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDispensarySummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDispensarySummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDispensaryByIdApi(id) {
  const url = apiConstants.apiGetDispensaryById + id;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDispensaryWIPModeApi(model) {
  const url = apiConstants.apiPutDispensaryWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitDispensaryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSubmitDispensary, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDispensaryPrintDateApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(apiConstants.apiPutDispensaryPrintDate, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDispensaryVisitPrintDateApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(
      apiConstants.apiPutDispensaryVisitPrintDate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDispensaryStorePrintDateApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(
      apiConstants.apiPutDispensaryStorePrintDate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDispensaryInvoiceOrderApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDispensaryInvoiceOrder,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDispensaryInvoicePrintDateApi(model) {
  const requestOptions = JSON.stringify(model);
  return await axios
    .put(
      apiConstants.apiPutDispensaryInvoicePrintDate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export default dispensaryApis;
