import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
//actions
import {
  postReportingVerifyCSVFixedMedicinePrice,
  postReportingImportCSVFixedMedicinePrice,
  getReportingExportFixedMedicinePrice,
} from "../../../../actions/reportingActions";
//style
import { useStyles } from "../../../../components/globalStyles";

//components
import * as generalConstants from "../../../../_constants/generalConstants";
import DropZoneDialog from "../../../../components/DropZoneDialog";
import Panel from "../../../../components/Panel";
import UploadCSVDialog from "../../../../components/UploadCSVDialog";
export default function ImportFixedMedicinePrice({ medicineFixedPriceHeader }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reporting = useSelector((state) => state.reporting);
  const curUser = useSelector((state) => state.curUser);
  const exportAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.REPORT_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.READ_PERMISSION_TYPE
      )
    : false;

  const importAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.REPORT_MEDICINE_FIXED_SELLING_PRICE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const [confirmUploadOpen, setConfirmUploadOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [fileObjects, setFileObjects] = React.useState([]);

  const [msgUploadAlarm, setMsgUploadAlarm] = React.useState(null);
  const [alertMsgUpload, setAlertMsgUpload] = React.useState(false);
  const [alertTypeUpload, setAlertTypeUpload] = React.useState(null);

  const [msgImportAlarm, setMsgImportAlarm] = React.useState(null);
  const [alertMsgImport, setAlertMsgImport] = React.useState(false);
  const [alertTypeImport, setAlertTypeImport] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);
  const [verifyLoading, setVerifyLoading] = React.useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState(false);
  const [errorAlarm, setErrorAlarm] = React.useState(false);
  const prevVerifyStatus = useRef();
  const prevImportStatus = useRef();

  const [verifyInfo, setVerifyInfo] = useState({
    totalItemCount: 0,
    createItem: [],
    createItemCount: 0,
    updateItem: [],
    updateItemCount: 0,
    errorItem: [],
    errorItemCount: 0,
  });

  const verifyFileOnHandle = () => {
    setAlertMsgUpload(false);
    setMsgUploadAlarm(null);
    setAlertTypeUpload(null);

    dispatch(
      postReportingVerifyCSVFixedMedicinePrice.pending({
        file: fileObjects,
        id: medicineFixedPriceHeader.id,
      })
    );
  };

  const uploadFileOnHandle = () => {
    dispatch(
      postReportingImportCSVFixedMedicinePrice.pending({
        file: fileObjects,
        id: medicineFixedPriceHeader.id,
      })
    );
  };

  const downloadFileOnHandle = () => {
    //dispatch(getReportingVerifyFixedMedicinePriceSync.pending());
    dispatch(
      getReportingExportFixedMedicinePrice.pending({
        is_pagination: true,
        order_by_direction: null,
        order_by_field: null,
        page_number: null,
        page_size: null,
        search_id_value: medicineFixedPriceHeader.id,
        search_status_type_value: null,
      })
    );
  };

  useEffect(() => {
    //set ref when first render
    prevVerifyStatus.current = false;
    prevImportStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      reporting.isLoadingPostReportingVerifyCSVFixedMedicinePrice !==
      prevVerifyStatus.current
    ) {
      //check the previous ref with current state
      prevVerifyStatus.current =
        reporting.isLoadingPostReportingVerifyCSVFixedMedicinePrice;
      setVerifyLoading(
        reporting.isLoadingPostReportingVerifyCSVFixedMedicinePrice
      );
      if (
        reporting.postReportingVerifyCSVFixedMedicinePriceIsSuccess === true &&
        reporting.isLoadingPostReportingVerifyCSVFixedMedicinePrice === false
      ) {
        setVerifyInfo({
          totalItemCount:
            reporting.postReportingVerifyCSVFixedMedicinePrice
              .import_total_count,
          createItem:
            reporting.postReportingVerifyCSVFixedMedicinePrice
              .import_creation_details,
          createItemCount:
            reporting.postReportingVerifyCSVFixedMedicinePrice
              .import_creation_count,
          updateItem:
            reporting.postReportingVerifyCSVFixedMedicinePrice
              .import_update_details,
          updateItemCount:
            reporting.postReportingVerifyCSVFixedMedicinePrice
              .import_update_count,
          errorItem:
            reporting.postReportingVerifyCSVFixedMedicinePrice
              .import_error_details,
          errorItemCount:
            reporting.postReportingVerifyCSVFixedMedicinePrice
              .import_error_count,
        });

        setConfirmUploadOpen(true);
        setOpen(false);
      }
      if (
        reporting.postReportingVerifyCSVFixedMedicinePriceIsSuccess === false &&
        reporting.isLoadingPostReportingVerifyCSVFixedMedicinePrice === false
      ) {
        setAlertTypeUpload("error");
        setAlertMsgUpload(true);
        setMsgUploadAlarm(
          reporting.error.postReportingVerifyCSVFixedMedicinePriceError
        );
      }
    }
  }, [reporting]);

  useEffect(() => {
    if (
      reporting.isLoadingPostReportingImportCSVFixedMedicinePrice !==
      prevImportStatus.current
    ) {
      //check the previous ref with current state
      prevImportStatus.current =
        reporting.isLoadingPostReportingImportCSVFixedMedicinePrice;
      setImportLoading(
        reporting.isLoadingPostReportingImportCSVFixedMedicinePrice
      );
      if (
        reporting.postReportingImportCSVFixedMedicinePriceIsSuccess === true &&
        reporting.isLoadingPostReportingImportCSVFixedMedicinePrice === false
      ) {
        setVerifyInfo({
          totalItemCount:
            reporting.postReportingImportCSVFixedMedicinePrice
              .import_total_count,
          createItem:
            reporting.postReportingImportCSVFixedMedicinePrice
              .import_creation_details,
          createItemCount:
            reporting.postReportingImportCSVFixedMedicinePrice
              .import_creation_count,
          updateItem:
            reporting.postReportingImportCSVFixedMedicinePrice
              .import_update_details,
          updateItemCount:
            reporting.postReportingImportCSVFixedMedicinePrice
              .import_update_count,
          errorItem:
            reporting.postReportingImportCSVFixedMedicinePrice
              .import_error_details,
          errorItemCount:
            reporting.postReportingImportCSVFixedMedicinePrice
              .import_error_count,
        });
        setAlertTypeImport("success");
        setAlertMsgImport(true);
        setMsgImportAlarm(
          t("translation:Medicine Fixed Selling Price has been updated")
        );
      }
      if (
        reporting.postReportingImportCSVFixedMedicinePriceIsSuccess === false &&
        reporting.isLoadingPostReportingImportCSVFixedMedicinePrice === false
      ) {
        setAlertTypeImport("error");
        setAlertMsgImport(true);
        setMsgImportAlarm(
          reporting.error.postReportingImportCSVFixedMedicinePriceError
        );
      }
    }
  }, [reporting, t]);

  return (
    <Panel heading={t("translation:Details")}>
      <Grid item xs={12}>
        <Collapse in={errorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        {!reporting.isLoadingGetReportingExportFixedMedicinePrice &&
          exportAccess && (
            <Link className={classes.boldMaroon075}>
              <Button
                className={classes.boldMaroon075}
                name="downloadButton"
                onClick={() => downloadFileOnHandle()}>
                {t("translation:EXPORT")}
              </Button>
            </Link>
          )}

        {reporting.isLoadingGetReportingExportFixedMedicinePrice && (
          <>{t("translation:Loading")}...</>
        )}
        {importAccess && (
          <Link className={classes.boldMaroon075}>
            <Button
              className={classes.boldMaroon075}
              name="uploadButton"
              onClick={() => setOpen(true)}>
              {t("translation:IMPORT")}
            </Button>
          </Link>
        )}

        {open && (
          <DropZoneDialog
            dialogTitle={<span>{t("translation:Upload")}</span>}
            fileObjects={fileObjects}
            fullWidth
            cancelButtonText={t("translation:BACK")}
            submitButtonText={t("translation:VERIFY")}
            acceptedFiles={[".csv"]}
            supportedFileType={".csv"}
            maxFileSize={5000000}
            filesLimit={1}
            dropzoneText={t("translation:Drag and drop a file here or click")}
            previewText={""}
            maxWidth="lg"
            open={open}
            showAlerts={false}
            useChipsForPreview={true}
            onAdd={(newFileObjs) => {
              setAlertMsgUpload(false);
              setMsgUploadAlarm("");

              setFileObjects([...newFileObjs]);
            }}
            onDelete={(deleteFileObj) => {
              setFileObjects([]);
            }}
            onClose={() => {
              setOpen(false);
              setAlertMsgUpload(false);
              setFileObjects([]);
            }}
            onSave={() => {
              verifyFileOnHandle();
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
            msgAlarm={msgUploadAlarm}
            alertMsg={alertMsgUpload}
            setAlertMsg={setAlertMsgUpload}
            alertType={alertTypeUpload}
            dialogLoading={verifyLoading}
            getDropRejectMessage={(
              rejectedFile,
              acceptedFiles,
              maxFileSize
            ) => {
              setMsgUploadAlarm(
                rejectedFile.name + " " + t("translation:is rejected")
              );
              setAlertMsgUpload(true);
              setAlertTypeUpload("warning");
            }}
            getFileLimitExceedMessage={(filesLimit) => {
              setMsgUploadAlarm(
                t("translation:more than") +
                  " " +
                  filesLimit +
                  " " +
                  t("translation:file(s)")
              );
              setAlertMsgUpload(true);
              setAlertTypeUpload("warning");
            }}
          />
        )}

        {confirmUploadOpen && (
          <UploadCSVDialog
            maxWidth="md"
            open={confirmUploadOpen}
            verifyInfo={verifyInfo}
            msgAlarm={msgImportAlarm}
            alertMsg={alertMsgImport}
            alertType={alertTypeImport}
            onClose={() => {
              setConfirmUploadOpen(false);
              setAlertMsgImport(false);
              setFileObjects([]);
            }}
            onSave={() => {
              uploadFileOnHandle();
            }}
            cancelButtonText={t("translation:BACK")}
            submitButtonText={t("translation:IMPORT")}
            dialogLoading={importLoading}
          />
        )}
      </Grid>
    </Panel>
  );
}
