import * as types from "../_constants/deliveryOrderConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderSummaryListSuccess: false,
        isLoadingDeliveryOrderSummaryList: true,
        //may have issue to submit order
        deliveryOrderSummaryList: null,
      };

    case types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderSummaryListSuccess: false,
        isLoadingDeliveryOrderSummaryList: false,
        deliveryOrderSummaryList: null,
        error: { ...state.error, deliveryOrderSummaryListError: action.error },
      };

    case types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderSummaryListSuccess: true,
        isLoadingDeliveryOrderSummaryList: false,
        deliveryOrderSummaryList: action.data,
      };

    case types.GET_DELIVERYORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderListSuccess: false,
        isLoadingDeliveryOrderList: true,
        //may have issue to submit order
        deliveryOrderList: null,
      };

    case types.GET_DELIVERYORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderListSuccess: false,
        isLoadingDeliveryOrderList: false,
        deliveryOrderList: null,
        error: { ...state.error, deliveryOrderListError: action.error },
      };

    case types.GET_DELIVERYORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderListSuccess: true,
        isLoadingDeliveryOrderList: false,
        deliveryOrderList: action.data,
      };

    case types.POST_SAVEDELIVERYORDER_ASYNC.PENDING:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: false,
        isLoadingSaveDeliveryOrder: true,
        saveDeliveryOrder: null,
      };

    case types.POST_SAVEDELIVERYORDER_ASYNC.ERROR:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: false,
        isLoadingSaveDeliveryOrder: false,
        saveDeliveryOrder: null,
        error: {
          ...state.error,
          postSaveDeliveryOrder: action.error,
        },
      };

    case types.POST_SAVEDELIVERYORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postSaveDeliveryOrderIsSuccess: true,
        isLoadingSaveDeliveryOrder: false,
        saveDeliveryOrder: action.data,
      };

    case types.POST_SUBMITDELIVERYORDER_ASYNC.PENDING:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: false,
        isLoadingSubmitDeliveryOrder: true,
        submitDeliveryOrder: null,
      };

    case types.POST_SUBMITDELIVERYORDER_ASYNC.ERROR:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: false,
        isLoadingSubmitDeliveryOrder: false,
        submitDeliveryOrder: null,
        error: { ...state.error, postSubmitDeliveryOrder: action.error },
      };

    case types.POST_SUBMITDELIVERYORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitDeliveryOrderIsSuccess: true,
        isLoadingSubmitDeliveryOrder: false,
        submitDeliveryOrder: action.data,
      };
    case types.POST_SUBMITALLDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        postSubmitAllDeliveryIsSuccess: false,
        isLoadingSubmitAllDelivery: true,
        submitAllDelivery: null,
      };

    case types.POST_SUBMITALLDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        postSubmitAllDeliveryIsSuccess: false,
        isLoadingSubmitAllDelivery: false,
        submitAllDelivery: null,
        error: { ...state.error, postSubmitAllDelivery: action.error },
      };

    case types.POST_SUBMITALLDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitAllDeliveryIsSuccess: true,
        isLoadingSubmitAllDelivery: false,
        submitAllDelivery: action.data,
      };
    case types.PUT_DELIVERYORDEREDITMODE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderEditModeSuccess: false,
        isLoadingDeliveryOrderEditMode: true,
        putDeliveryOrderEditMode: null,
      };

    case types.PUT_DELIVERYORDEREDITMODE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderEditModeSuccess: false,
        isLoadingDeliveryOrderEditMode: false,
        error: { ...state.error, deliveryOrderEditModeError: action.error },
        putDeliveryOrderEditMode: null,
      };

    case types.PUT_DELIVERYORDEREDITMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderEditModeSuccess: true,
        isLoadingDeliveryOrderEditMode: false,
        putDeliveryOrderEditMode: action.data,
      };

    case types.PUT_DELIVERYORDERWIPMODE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderWIPModeSuccess: false,
        isLoadingDeliveryOrderWIPMode: true,
        putDeliveryOrderWIPMode: null,
      };

    case types.PUT_DELIVERYORDERWIPMODE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderWIPModeSuccess: false,
        isLoadingDeliveryOrderWIPMode: false,
        putDeliveryOrderWIPMode: null,
        error: { ...state.error, deliveryOrderWIPModeError: action.error },
      };

    case types.PUT_DELIVERYORDERWIPMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderWIPModeSuccess: true,
        isLoadingDeliveryOrderWIPMode: false,
        putDeliveryOrderWIPMode: action.data,
      };

    case types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderPendingModeSuccess: false,
        isLoadingDeliveryOrderPendingMode: true,
        deliveryOrderPendingMode: null,
      };

    case types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderPendingModeSuccess: false,
        isLoadingDeliveryOrderPendingMode: false,
        deliveryOrderPendingMode: null,
        error: { ...state.error, deliveryOrderPendingModeError: action.error },
      };

    case types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderPendingModeSuccess: true,
        isLoadingDeliveryOrderPendingMode: false,
        deliveryOrderPendingMode: action.data,
      };

    case types.GET_DELIVERYCOURIERLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryCourierListSuccess: false,
        isLoadingDeliveryCourierList: true,
        deliveryCourierList: null,
      };

    case types.GET_DELIVERYCOURIERLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryCourierListSuccess: false,
        isLoadingDeliveryCourierList: false,
        deliveryCourierList: null,
        error: { ...state.error, deliveryCourierListError: action.error },
      };

    case types.GET_DELIVERYCOURIERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryCourierListSuccess: true,
        isLoadingDeliveryCourierList: false,
        deliveryCourierList: action.data,
      };

    case types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryCourierByBranchIsSuccess: false,
        isLoadingDeliveryCourierByBranch: true,
        deliveryCourierByBranch: null,
      };

    case types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryCourierByBranchIsSuccess: false,
        isLoadingDeliveryCourierByBranch: false,
        deliveryCourierByBranch: null,
        error: { ...state.error, deliveryCourierByBranchError: action.error },
      };

    case types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryCourierByBranchIsSuccess: true,
        isLoadingDeliveryCourierByBranch: false,
        deliveryCourierByBranch: action.data,
      };

    case types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryOrderListByStoreHeaderIdSuccess: false,
        isLoadingDeliveryOrderListByStoreHeaderId: true,
        //may have issue to submit order
        getDeliveryOrderListByStoreHeaderId: null,
      };

    case types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryOrderListByStoreHeaderIdSuccess: false,
        isLoadingDeliveryOrderListByStoreHeaderId: false,
        getDeliveryOrderListByStoreHeaderId: null,
        error: {
          ...state.error,
          deliveryOrderListByStoreHeaderIdError: action.error,
        },
      };

    case types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryOrderListByStoreHeaderIdSuccess: true,
        isLoadingDeliveryOrderListByStoreHeaderId: false,
        getDeliveryOrderListByStoreHeaderId: action.data,
      };

    case types.GET_STOREHEADERLIST_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderListSuccess: false,
        isLoadingStoreOrderList: true,
        //may have issue to submit order
        storeOrderList: null,
      };

    case types.GET_STOREHEADERLIST_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderListSuccess: false,
        isLoadingStoreOrderList: false,
        storeOrderList: null,
        error: { ...state.error, storeOrderListError: action.error },
      };

    case types.GET_STOREHEADERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderListSuccess: true,
        isLoadingStoreOrderList: false,
        storeOrderList: action.data,
      };

    case types.GET_STOREHEADERSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderSummaryListSuccess: false,
        isLoadingStoreOrderSummaryList: true,
        //may have issue to submit order
        storeOrderSummaryList: null,
      };

    case types.GET_STOREHEADERSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderSummaryListSuccess: false,
        isLoadingStoreOrderSummaryList: false,
        storeOrderSummaryList: null,
        error: { ...state.error, storeOrderSummaryListError: action.error },
      };

    case types.GET_STOREHEADERSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderSummaryListSuccess: true,
        isLoadingStoreOrderSummaryList: false,
        storeOrderSummaryList: action.data,
      };

    case types.PUT_DELIVERYORDERPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderPrintDateIsSuccess: false,
        isLoadingDeliveryOrderPrintDate: true,
        deliveryOrderPrintDate: null,
      };

    case types.PUT_DELIVERYORDERPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderPrintDateIsSuccess: false,
        isLoadingDeliveryOrderPrintDate: false,
        deliveryOrderPrintDate: null,
        error: { ...state.error, deliveryOrderPrintDateError: action.error },
      };

    case types.PUT_DELIVERYORDERPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderPrintDateIsSuccess: true,
        isLoadingDeliveryOrderPrintDate: false,
        deliveryOrderPrintDate: action.data,
      };

    case types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryOrderUpdateStoreAddressIsSuccess: false,
        isLoadingDeliveryOrderUpdateStoreAddress: true,
        deliveryOrderUpdateStoreAddress: null,
      };

    case types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryOrderUpdateStoreAddressIsSuccess: false,
        isLoadingDeliveryOrderUpdateStoreAddress: false,
        deliveryOrderUpdateStoreAddress: null,
        error: {
          ...state.error,
          deliveryOrderUpdateStoreAddressError: action.error,
        },
      };

    case types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryOrderUpdateStoreAddressIsSuccess: true,
        isLoadingDeliveryOrderUpdateStoreAddress: false,
        deliveryOrderUpdateStoreAddress: action.data,
      };

    case types.POST_EXPORTDELIVERYORDERS_ASYNC.PENDING:
      return {
        ...state,
        postExportDeliveryOrdersIsSuccess: false,
        isLoadingExportDeliveryOrders: true,
        exportDeliveryOrders: null,
      };

    case types.POST_EXPORTDELIVERYORDERS_ASYNC.ERROR:
      return {
        ...state,
        postExportDeliveryOrdersIsSuccess: false,
        isLoadingExportDeliveryOrders: false,
        exportDeliveryOrders: null,
        error: {
          ...state.error,
          exportDeliveryOrdersError: action.error,
        },
      };

    case types.POST_EXPORTDELIVERYORDERS_ASYNC.SUCCESS:
      return {
        ...state,
        postExportDeliveryOrdersIsSuccess: true,
        isLoadingExportDeliveryOrders: false,
        exportDeliveryOrders: action.data,
      };

    case types.POST_EXPORTDELIVERYORDERSV2_ASYNC.PENDING:
      return {
        ...state,
        postExportDeliveryOrdersV2IsSuccess: false,
        isLoadingExportDeliveryOrdersV2: true,
        exportDeliveryOrdersV2: null,
      };

    case types.POST_EXPORTDELIVERYORDERSV2_ASYNC.ERROR:
      return {
        ...state,
        postExportDeliveryOrdersV2IsSuccess: false,
        isLoadingExportDeliveryOrdersV2: false,
        exportDeliveryOrdersV2: null,
        error: {
          ...state.error,
          exportDeliveryOrdersV2Error: action.error,
        },
      };

    case types.POST_EXPORTDELIVERYORDERSV2_ASYNC.SUCCESS:
      return {
        ...state,
        postExportDeliveryOrdersV2IsSuccess: true,
        isLoadingExportDeliveryOrdersV2: false,
        exportDeliveryOrdersV2: action.data,
      };

    default:
      return state;
  }
};
