import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/invoiceConstants";

export const putInvoiceWIPMode = {
  pending: (data) =>
    createAction(types.PUT_INVOICEWIPMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_INVOICEWIPMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_INVOICEWIPMODE_ASYNC.ERROR, { error }),
};

export const putInvoicePendingStatus = {
  pending: (data) =>
    createAction(types.PUT_INVOICEPENDINGSTATUS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_INVOICEPENDINGSTATUS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_INVOICEPENDINGSTATUS_ASYNC.ERROR, { error }),
};

export const getInvoiceList = {
  pending: (data) =>
    createAction(types.GET_INVOICELIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_INVOICELIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_INVOICELIST_ASYNC.ERROR, { error }),
};

export const getInvoiceSummaryList = {
  pending: (data) =>
    createAction(types.GET_INVOICESUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_INVOICESUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_INVOICESUMMARYLIST_ASYNC.ERROR, { error }),
};

export const getInvoiceById = {
  pending: (data) =>
    createAction(types.GET_INVOICEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_INVOICEBYID_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_INVOICEBYID_ASYNC.ERROR, { error }),
};

export const getInvoiceByOrderNum = {
  pending: (data) =>
    createAction(types.GET_INVOICEBYORDERNUM_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_INVOICEBYORDERNUM_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_INVOICEBYORDERNUM_ASYNC.ERROR, { error }),
};

export const postSubmitInvoice = {
  pending: (data) =>
    createAction(types.POST_SUBMITINVOICE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITINVOICE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITINVOICE_ASYNC.ERROR, { error }),
};

export const postCreateInvoiceById = {
  pending: (data) =>
    createAction(types.POST_CREATEINVOICEBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_CREATEINVOICEBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_CREATEINVOICEBYID_ASYNC.ERROR, { error }),
};

export const putInvoicePrintDate = {
  pending: (data) =>
    createAction(types.PUT_INVOICEPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_INVOICEPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_INVOICEPRINTDATE_ASYNC.ERROR, { error }),
};

export const postOverrideInvoiceAmount = {
  pending: (data) =>
    createAction(types.POST_OVERRIDEINVOICEAMOUNT_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_OVERRIDEINVOICEAMOUNT_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_OVERRIDEINVOICEAMOUNT_ASYNC.ERROR, { error }),
};
