import * as types from "../_constants/visitOrderConstants";
const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_VISITDISPENSARYBRANCHES_ASYNC.PENDING:
      return {
        ...state,
        getVisitDispensaryBranchesIsSuccess: false,
        isLoadingVisitDispensaryBranches: true,
        dispensaryBranches: null,
      };

    case types.GET_VISITDISPENSARYBRANCHES_ASYNC.ERROR:
      return {
        ...state,
        getVisitDispensaryBranchesIsSuccess: false,
        isLoadingVisitDispensaryBranches: false,
        dispensaryBranches: null,
        error: { ...state.error, dispensaryBranchesError: action.error },
      };

    case types.GET_VISITDISPENSARYBRANCHES_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitDispensaryBranchesIsSuccess: true,
        isLoadingVisitDispensaryBranches: false,
        dispensaryBranches: action.data,
      };

    case types.GET_VISITMEDICINETYPES_ASYNC.PENDING:
      return {
        ...state,
        getVisitMedicineTypesIsSuccess: false,
        isLoadingVisitMedicineTypes: true,
        medicineTypes: null,
      };

    case types.GET_VISITMEDICINETYPES_ASYNC.ERROR:
      return {
        ...state,
        getVisitMedicineTypesIsSuccess: false,
        isLoadingVisitMedicineTypes: false,
        medicineTypes: null,
        error: { ...state.error, medicineTypesError: action.error },
      };

    case types.GET_VISITMEDICINETYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitMedicineTypesIsSuccess: true,
        isLoadingGetVisitMedicineTypes: false,
        medicineTypes: action.data,
      };

    case types.GET_VISITDISPENSARYBRANCHESALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getDispensaryBranchesAllianceIsSuccess: false,
        dispensaryBranchesAlliance: null,
        isLoadingGetDispensaryBranchesAlliance: true,
      };

    case types.GET_VISITDISPENSARYBRANCHESALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getDispensaryBranchesAllianceIsSuccess: false,
        dispensaryBranchesAlliance: null,
        isLoadingGetDispensaryBranchesAlliance: false,
        error: {
          ...state.error,
          dispensaryBranchesAllianceError: action.error,
        },
      };

    case types.GET_VISITDISPENSARYBRANCHESALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getDispensaryBranchesAllianceIsSuccess: true,
        isLoadingGetDispensaryBranchesAlliance: false,
        dispensaryBranchesAlliance: action.data,
      };

    case types.GET_VISITMEDICINETYPESALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getMedicineTypesAllianceIsSuccess: false,
        isLoadingGetMedicineTypesAlliance: true,
        medicineTypesAlliance: null,
      };

    case types.GET_VISITMEDICINETYPESALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getMedicineTypesAllianceIsSuccess: false,
        isLoadingGetMedicineTypesAlliance: false,
        medicineTypesAlliance: null,
        error: { ...state.error, medicineTypesAllianceError: action.error },
      };

    case types.GET_VISITMEDICINETYPESALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineTypesAllianceIsSuccess: true,
        isLoadingGetMedicineTypesAlliance: false,
        medicineTypesAlliance: action.data,
      };
    case types.POST_VISITFORMULASALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getFormulasAllianceIsSuccess: false,
        isLoadingGetFormulasAlliance: true,
        formulasAlliance: null,
      };

    case types.POST_VISITFORMULASALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getFormulasAllianceIsSuccess: false,
        isLoadingGetFormulasAlliance: false,
        formulasAlliance: null,
        error: { ...state.error, formulasAllianceError: action.error },
      };

    case types.POST_VISITFORMULASALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getFormulasAllianceIsSuccess: true,
        isLoadingGetFormulasAlliance: false,
        formulasAlliance: action.data,
      };

    case types.POST_VISITMEDICINEFORMULASALLIANCE_ASYNC.PENDING:
      return {
        ...state,
        getMedicineFormulasAllianceIsSuccess: false,
        isLoadingMedicineFormulasAlliance: true,
        //medicineFormulasAlliance will be null when loading
        medicineFormulasAlliance: null,
      };

    case types.POST_VISITMEDICINEFORMULASALLIANCE_ASYNC.ERROR:
      return {
        ...state,
        getMedicineFormulasAllianceIsSuccess: false,
        isLoadingMedicineFormulasAlliance: false,
        error: { ...state.error, medicineFormulasAllianceError: action.error },
        //medicineFormulasAlliance will be null when fail
        medicineFormulasAlliance: null,
      };

    case types.POST_VISITMEDICINEFORMULASALLIANCE_ASYNC.SUCCESS:
      return {
        ...state,
        getMedicineFormulasAllianceIsSuccess: true,
        isLoadingMedicineFormulasAlliance: false,
        medicineFormulasAlliance: action.data,
      };

    case types.POST_VISITBRANCHMEDICINES_ASYNC.PENDING:
      return {
        ...state,
        postVisitBranchMedicinesIsSuccess: false,
        isLoadingPostVisitBranchMedicines: true,
        //branchMedicines will be null when loading
        branchMedicines: null,
      };

    case types.POST_VISITBRANCHMEDICINES_ASYNC.ERROR:
      return {
        ...state,
        postVisitBranchMedicinesIsSuccess: false,
        isLoadingPostVisitBranchMedicines: false,
        //branchMedicines will be null when fail
        branchMedicines: null,
        error: { ...state.error, branchMedicinesError: action.error },
      };

    case types.POST_VISITBRANCHMEDICINES_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitBranchMedicinesIsSuccess: true,
        isLoadingPostVisitBranchMedicines: false,
        branchMedicines: action.data,
      };

    case types.GET_VISITCURRENTORGANISATION_ASYNC.PENDING:
      return {
        ...state,
        getVisitCurrentOrganisationIsSuccess: false,
        currentOrganistion: null,
        isLoadingGetVisitCurrentOrganisation: true,
      };

    case types.GET_VISITCURRENTORGANISATION_ASYNC.ERROR:
      return {
        ...state,
        getVisitCurrentOrganisationIsSuccess: false,
        isLoadingGetVisitCurrentOrganisation: false,
        currentOrganistion: null,
        error: { ...state.error, currentOrganistionError: action.error },
      };

    case types.GET_VISITCURRENTORGANISATION_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitCurrentOrganisationIsSuccess: true,
        isLoadingGetVisitCurrentOrganisation: false,
        currentOrganistion: action.data,
      };

    case types.POST_VISITSAVESTOREORDER_ASYNC.PENDING:
      return {
        ...state,
        postVisitSaveStoreOrderIsSuccess: false,
        isLoadingSaveStoreOrder: true,
        saveStoreOrder: null,
      };

    case types.POST_VISITSAVESTOREORDER_ASYNC.ERROR:
      return {
        ...state,
        postVisitSaveStoreOrderIsSuccess: false,
        isLoadingSaveStoreOrder: false,
        saveStoreOrder: null,
        error: { ...state.error, saveStoreOrderError: action.error },
      };

    case types.POST_VISITSAVESTOREORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitSaveStoreOrderIsSuccess: true,
        isLoadingSaveStoreOrder: false,
        saveStoreOrder: action.data,
      };

    case types.POST_VISITSUBMITSTOREORDER_ASYNC.PENDING:
      return {
        ...state,
        postVisitSubmitStoreOrderIsSuccess: false,
        isLoadingSubmitStoreOrder: true,
        submitStoreOrder: null,
      };

    case types.POST_VISITSUBMITSTOREORDER_ASYNC.ERROR:
      return {
        ...state,
        postVisitSubmitStoreOrderIsSuccess: false,
        isLoadingSubmitStoreOrder: false,
        submitStoreOrder: null,
        error: { ...state.error, submitStoreOrderError: action.error },
      };

    case types.POST_VISITSUBMITSTOREORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitSubmitStoreOrderIsSuccess: true,
        isLoadingSubmitStoreOrder: false,
        submitStoreOrder: action.data,
      };

    case types.GET_VISITSTOREORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getVisitStoreOrderListIsSuccess: false,
        isLoadingVisitStoreOrderList: true,
        storeOrderList: null,
      };

    case types.GET_VISITSTOREORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getVisitStoreOrderListIsSuccess: false,
        storeOrderList: null,
        isLoadingVisitStoreOrderList: false,
        error: { ...state.error, getVisitStoreOrderListError: action.error },
      };

    case types.GET_VISITSTOREORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitStoreOrderListIsSuccess: true,
        isLoadingVisitStoreOrderList: false,
        storeOrderList: action.data,
      };

    case types.GET_VISITSTOREORDERLISTLINKEDDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderListLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderListLinkedDelivery: true,
        storeOrderListLinkedDelivery: null,
      };

    case types.GET_VISITSTOREORDERLISTLINKEDDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderListLinkedDeliveryIsSuccess: false,
        storeOrderListLinkedDelivery: null,
        isLoadingStoreOrderListLinkedDelivery: false,
        error: {
          ...state.error,
          storeOrderListLinkedDelivery: action.error,
        },
      };

    case types.GET_VISITSTOREORDERLISTLINKEDDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderListLinkedDeliveryIsSuccess: true,
        isLoadingStoreOrderListLinkedDelivery: false,
        storeOrderListLinkedDelivery: action.data,
      };

    case types.GET_VISITSTOREORDERSUMMARYLIST_ASYNC.PENDING:
      return {
        ...state,
        getVisitStoreOrderSummaryListIsSuccess: false,
        isLoadingVisitStoreOrderSummaryList: true,
        storeOrderSummaryList: null,
      };

    case types.GET_VISITSTOREORDERSUMMARYLIST_ASYNC.ERROR:
      return {
        ...state,
        getVisitStoreOrderSummaryListIsSuccess: false,
        isLoadingVisitStoreOrderSummaryList: false,
        storeOrderSummaryList: null,
        error: {
          ...state.error,
          getVisitStoreOrderSummaryListError: action.error,
        },
      };

    case types.GET_VISITSTOREORDERSUMMARYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitStoreOrderSummaryListIsSuccess: true,
        isLoadingVisitStoreOrderSummaryList: false,
        storeOrderSummaryList: action.data,
      };

    case types.GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderSummaryListLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderSummaryListLinkedDelivery: true,
        storeOrderSummaryListLinkedDelivery: null,
      };

    case types.GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderSummaryListLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderSummaryListLinkedDelivery: null,
        storeOrderSummaryListLinkedDelivery: false,
        error: {
          ...state.error,
          getStoreOrderSummaryListLinkedDeliveryError: action.error,
        },
      };

    case types.GET_VISITSTOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderSummaryListLinkedDeliveryIsSuccess: true,
        isLoadingStoreOrderSummaryListLinkedDelivery: false,
        storeOrderSummaryListLinkedDelivery: action.data,
      };

    case types.GET_VISITSTOREORDERBYID_ASYNC.PENDING:
      return {
        ...state,
        getVisitStoreOrderByIdIsSuccess: false,
        isLoadingVisitStoreOrderById: true,
        visitStoreOrderListById: null,
      };

    case types.GET_VISITSTOREORDERBYID_ASYNC.ERROR:
      return {
        ...state,
        getVisitStoreOrderByIdIsSuccess: false,
        visitStoreOrderListById: null,
        isLoadingVisitStoreOrderById: false,
        error: { ...state.error, getVisitStoreOrderByIdError: action.error },
      };

    case types.GET_VISITSTOREORDERBYID_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitStoreOrderByIdIsSuccess: true,
        isLoadingVisitStoreOrderById: false,
        visitStoreOrderListById: action.data,
      };

    case types.GET_VISITSTOREORDERBYIDLINKEDDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        getStoreOrderByIdLinkedDeliveryIsSuccess: false,
        isLoadingStoreOrderByIdLinkedDelivery: true,
        storeOrderListByIdLinkedDelivery: null,
      };

    case types.GET_VISITSTOREORDERBYIDLINKEDDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        getStoreOrderByIdLinkedDeliveryIsSuccess: false,
        storeOrderListByIdLinkedDelivery: null,
        isLoadingStoreOrderByIdLinkedDelivery: false,
        error: {
          ...state.error,
          getStoreOrderByIdLinkedDeliveryError: action.error,
        },
      };

    case types.GET_VISITSTOREORDERBYIDLINKEDDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        getStoreOrderByIdLinkedDeliveryIsSuccess: true,
        isLoadingStoreOrderByIdLinkedDelivery: false,
        storeOrderListByIdLinkedDelivery: action.data,
      };

    case types.GET_VISITSTOREORDERBYIDPATIENTHISTORY_ASYNC.PENDING:
      return {
        ...state,
        getVisitStoreOrderByIdPatientHistoryIsSuccess: false,
        isLoadingVisitStoreOrderByIdPatientHistory: true,
        storeOrderListByIdPatientHistory: null,
      };

    case types.GET_VISITSTOREORDERBYIDPATIENTHISTORY_ASYNC.ERROR:
      return {
        ...state,
        getVisitStoreOrderByIdPatientHistoryIsSuccess: false,
        storeOrderListByIdPatientHistory: null,
        isLoadingVisitStoreOrderByIdPatientHistory: false,
        error: {
          ...state.error,
          getVisitStoreOrderByIdPatientHistoryError: action.error,
        },
      };

    case types.GET_VISITSTOREORDERBYIDPATIENTHISTORY_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitStoreOrderByIdPatientHistoryIsSuccess: true,
        isLoadingVisitStoreOrderByIdPatientHistory: false,
        storeOrderListByIdPatientHistory: action.data,
      };

    case types.GET_VISITSTOREORDERDASHBOARDSEARCHLIST_ASYNC.PENDING:
      return {
        ...state,
        getVisitStoreOrderDashboardSearchListIsSuccess: false,
        isLoadingVisitStoreOrderDashboardSearchList: true,
        storeOrderDashboardSearchList: null,
      };

    case types.GET_VISITSTOREORDERDASHBOARDSEARCHLIST_ASYNC.ERROR:
      return {
        ...state,
        getVisitStoreOrderDashboardSearchListIsSuccess: false,
        storeOrderDashboardSearchList: null,
        isLoadingVisitStoreOrderDashboardSearchList: false,
        error: {
          ...state.error,
          getVisitStoreOrderDashboardSearchListError: action.error,
        },
      };

    case types.GET_VISITSTOREORDERDASHBOARDSEARCHLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitStoreOrderDashboardSearchListIsSuccess: true,
        isLoadingVisitStoreOrderDashboardSearchList: false,
        storeOrderDashboardSearchList: action.data,
      };

    case types.GET_PATIENTVISITSTOREORDERLIST_ASYNC.PENDING:
      return {
        ...state,
        getPatientVisitStoreOrderListIsSuccess: false,
        isLoadingPatientVisitStoreOrderList: true,
        patientVisitStoreOrderList: null,
      };

    case types.GET_PATIENTVISITSTOREORDERLIST_ASYNC.ERROR:
      return {
        ...state,
        getPatientVisitStoreOrderListIsSuccess: false,
        patientVisitStoreOrderList: null,
        isLoadingPatientVisitStoreOrderList: false,
        error: {
          ...state.error,
          getPatientVisitStoreOrderListError: action.error,
        },
      };

    case types.GET_PATIENTVISITSTOREORDERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getPatientVisitStoreOrderListIsSuccess: true,
        isLoadingPatientVisitStoreOrderList: false,
        patientVisitStoreOrderList: action.data,
      };
    case types.PUT_VISITSTOREEDITMODE_ASYNC.PENDING:
      return {
        ...state,
        putVisitStockEditModeIsSuccess: false,
        isLoadingStockEditMode: true,
        storeEditMode: null,
      };

    case types.PUT_VISITSTOREEDITMODE_ASYNC.ERROR:
      return {
        ...state,
        putVisitStockEditModeIsSuccess: false,
        isLoadingStockEditMode: false,
        error: { ...state.error, putVisitStockEditModeError: action.error },
        storeEditMode: null,
      };

    case types.PUT_VISITSTOREEDITMODE_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitStockEditModeIsSuccess: true,
        isLoadingStockEditMode: false,
        storeEditMode: action.data,
      };

    case types.GET_VISITACUPOINTS_ASYNC.PENDING:
      return {
        ...state,
        getVisitAcupointsIsSuccess: false,
        isLoadingGetVisitAcupoints: true,
        acupointsList: null,
      };

    case types.GET_VISITACUPOINTS_ASYNC.ERROR:
      return {
        ...state,
        getVisitAcupointsIsSuccess: false,
        isLoadingGetVisitAcupoints: false,
        acupointsList: null,
        error: { ...state.error, getVisitAcupointsListError: action.error },
      };

    case types.GET_VISITACUPOINTS_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitAcupointsIsSuccess: true,
        isLoadingGetVisitAcupoints: false,
        acupointsList: action.data,
      };

    case types.GET_VISITTREATMENTTYPES_ASYNC.PENDING:
      return {
        ...state,
        getVisitTreatmentTypesIsSuccess: false,
        isLoadingGetVisitTreatmentTypes: true,
        treatmentTypesList: null,
      };

    case types.GET_VISITTREATMENTTYPES_ASYNC.ERROR:
      return {
        ...state,
        getVisitTreatmentTypesIsSuccess: false,
        isLoadingGetVisitTreatmentTypes: false,
        treatmentTypesList: null,
        error: {
          ...state.error,
          getVisitTreatmentTypesListError: action.error,
        },
      };

    case types.GET_VISITTREATMENTTYPES_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitTreatmentTypesIsSuccess: true,
        isLoadingGetVisitTreatmentTypes: false,
        treatmentTypesList: action.data,
      };

    case types.GET_VISITPAYMENTTERMS_ASYNC.PENDING:
      return {
        ...state,
        getVisitPaymentTermsIsSuccess: false,
        isLoadingGetVisitPaymentTerms: true,
        paymentTermsList: null,
      };

    case types.GET_VISITPAYMENTTERMS_ASYNC.ERROR:
      return {
        ...state,
        getVisitPaymentTermsIsSuccess: false,
        isLoadingGetVisitPaymentTerms: false,
        paymentTermsList: null,
        error: { ...state.error, getVisitPaymentTermsListError: action.error },
      };

    case types.GET_VISITPAYMENTTERMS_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitPaymentTermsIsSuccess: true,
        isLoadingGetVisitPaymentTerms: false,
        paymentTermsList: action.data,
      };
    case types.GET_VISITPAYMENTMETHODS_ASYNC.PENDING:
      return {
        ...state,
        getVisitPaymentMethodsIsSuccess: false,
        isLoadingGetVisitPaymentMethods: true,
        paymentMethodsList: null,
      };

    case types.GET_VISITPAYMENTMETHODS_ASYNC.ERROR:
      return {
        ...state,
        getVisitPaymentMethodsIsSuccess: false,
        isLoadingGetVisitPaymentMethods: false,
        paymentMethodsList: null,
        error: {
          ...state.error,
          getVisitPaymentMethodsListError: action.error,
        },
      };

    case types.GET_VISITPAYMENTMETHODS_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitPaymentMethodsIsSuccess: true,
        isLoadingGetVisitPaymentMethods: false,
        paymentMethodsList: action.data,
      };

    case types.POST_VISITTREATMENTS_ASYNC.PENDING:
      return {
        ...state,
        postVisitTreatmentsIsSuccess: false,
        isLoadingVisitTreatments: true,
        visitTreatmentsList: null,
      };

    case types.POST_VISITTREATMENTS_ASYNC.ERROR:
      return {
        ...state,
        postVisitTreatmentsIsSuccess: false,
        isLoadingVisitTreatments: false,
        visitTreatmentsList: null,
        error: { ...state.error, postVisitTreatmentsError: action.error },
      };

    case types.POST_VISITTREATMENTS_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitTreatmentsIsSuccess: true,
        isLoadingVisitTreatments: false,
        visitTreatmentsList: action.data,
      };

    case types.POST_VISITEMPTYORDER_ASYNC.PENDING:
      return {
        ...state,
        postVisitEmtryOrderIsSuccess: false,
        isLoadingVisitTreatments: true,
        visitEmtryOrder: null,
      };

    case types.POST_VISITEMPTYORDER_ASYNC.ERROR:
      return {
        ...state,
        postVisitEmtryOrderIsSuccess: false,
        isLoadingVisitEmtryOrder: false,
        visitEmtryOrder: null,
        error: { ...state.error, postVisitEmtryOrderError: action.error },
      };

    case types.POST_VISITEMPTYORDER_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitEmtryOrderIsSuccess: true,

        isLoadingVisitEmtryOrder: false,
        visitEmtryOrder: action.data,
      };

    case types.GET_VISITPHYSICIANS_ASYNC.PENDING:
      return {
        ...state,
        getVisitPhysiciansIsSuccess: false,
        isLoadingGetVisitPhysicians: true,
        physiciansList: null,
      };

    case types.GET_VISITPHYSICIANS_ASYNC.ERROR:
      return {
        ...state,
        getVisitPhysiciansIsSuccess: false,
        isLoadingGetVisitPhysicians: false,
        physiciansList: null,
        error: {
          ...state.error,
          getVisitPhysiciansError: action.error,
        },
      };

    case types.GET_VISITPHYSICIANS_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitPhysiciansIsSuccess: true,
        isLoadingGetVisitPhysicians: false,
        physiciansList: action.data,
      };

    case types.POST_VISITPHYSICIANS_ASYNC.PENDING:
      return {
        ...state,
        postVisitPhysiciansIsSuccess: false,
        postPhysiciansList: null,
        isLoadingPostVisitPhysicians: true,
      };

    case types.POST_VISITPHYSICIANS_ASYNC.ERROR:
      return {
        ...state,
        postVisitPhysiciansIsSuccess: false,
        isLoadingPostVisitPhysicians: false,
        postPhysiciansList: null,
        error: {
          ...state.error,
          postVisitPhysiciansError: action.error,
        },
      };

    case types.POST_VISITPHYSICIANS_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitPhysiciansIsSuccess: true,
        isLoadingPostVisitPhysicians: false,
        postPhysiciansList: action.data,
      };
    case types.GET_VISITUSERLIST_ASYNC.PENDING:
      return {
        ...state,
        getVisitUserListIsSuccess: false,
        isLoadingVisitUserList: true,
        visitUserList: null,
      };

    case types.GET_VISITUSERLIST_ASYNC.ERROR:
      return {
        ...state,
        getVisitUserListIsSuccess: false,
        isLoadingVisitUserList: false,
        visitUserList: null,

        error: { ...state.error, visitUserListError: action.error },
      };

    case types.GET_VISITUSERLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitUserListIsSuccess: true,
        isLoadingVisitUserList: false,
        visitUserList: action.data,
      };

    case types.GET_VISITUSERADVANCEDSEARCH_ASYNC.PENDING:
      return {
        ...state,
        getVisitUserAdvancedSearchIsSuccess: false,
        isLoadingVisitUserAdvancedSearch: true,
        visitUserAdvancedSearch: null,
      };

    case types.GET_VISITUSERADVANCEDSEARCH_ASYNC.ERROR:
      return {
        ...state,
        getVisitUserAdvancedSearchIsSuccess: false,
        isLoadingVisitUserAdvancedSearch: false,
        visitUserAdvancedSearch: null,

        error: { ...state.error, visitUserAdvancedSearchError: action.error },
      };

    case types.GET_VISITUSERADVANCEDSEARCH_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitUserAdvancedSearchIsSuccess: true,
        isLoadingVisitUserAdvancedSearch: false,
        visitUserAdvancedSearch: action.data,
      };

    case types.POST_VISITCREATEUSER_ASYNC.PENDING:
      return {
        ...state,
        postVisitCreateUserIsSuccess: false,
        isLoadingVisitCreateUser: true,
        visitCreateUser: null,
      };

    case types.POST_VISITCREATEUSER_ASYNC.ERROR:
      return {
        ...state,
        postVisitCreateUserIsSuccess: false,
        isLoadingVisitCreateUser: false,
        visitCreateUser: null,

        error: { ...state.error, visitCreateUserError: action.error },
      };

    case types.POST_VISITCREATEUSER_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitCreateUserIsSuccess: true,
        isLoadingVisitCreateUser: false,
        visitCreateUser: action.data,
      };

    case types.PUT_VISITUPDATEUSER_ASYNC.PENDING:
      return {
        ...state,
        putVisitUpdateUserIsSuccess: false,
        isLoadingVisitUpdateUser: true,
        visitUpdateUser: null,
      };

    case types.PUT_VISITUPDATEUSER_ASYNC.ERROR:
      return {
        ...state,
        putVisitUpdateUserIsSuccess: false,
        isLoadingVisitUpdateUser: false,
        visitUpdateUser: null,

        error: { ...state.error, visitUpdateUserError: action.error },
      };

    case types.PUT_VISITUPDATEUSER_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitUpdateUserIsSuccess: true,
        isLoadingVisitUpdateUser: false,
        visitUpdateUser: action.data,
      };

    case types.GET_VISITISUSERCURRENTORGANISATION_ASYNC.PENDING:
      return {
        ...state,
        getVisitIsUserCurrentOrganisationIsSuccess: false,
        isLoadingVisitIsUserCurrentOrganisation: true,
        visitIsUserCurrentOrganisation: null,
      };

    case types.GET_VISITISUSERCURRENTORGANISATION_ASYNC.ERROR:
      return {
        ...state,
        getVisitIsUserCurrentOrganisationIsSuccess: false,
        isLoadingVisitIsUserCurrentOrganisation: false,
        visitIsUserCurrentOrganisation: null,

        error: {
          ...state.error,
          visitIsUserCurrentOrganisationError: action.error,
        },
      };

    case types.GET_VISITISUSERCURRENTORGANISATION_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitIsUserCurrentOrganisationIsSuccess: true,
        isLoadingVisitIsUserCurrentOrganisation: false,
        visitIsUserCurrentOrganisation: action.data,
      };

    case types.PUT_VISITUPDATEUSERORGANISATION_ASYNC.PENDING:
      return {
        ...state,
        putVisitUpdateUserOrganisationIsSuccess: false,
        isLoadingVisitUpdateUserOrganisation: true,
        visitUpdateUserOrganisation: null,
      };

    case types.PUT_VISITUPDATEUSERORGANISATION_ASYNC.ERROR:
      return {
        ...state,
        putVisitUpdateUserOrganisationIsSuccess: false,
        isLoadingVisitUpdateUserOrganisation: false,
        visitUpdateUserOrganisation: null,

        error: {
          ...state.error,
          visitUpdateUserOrganisationError: action.error,
        },
      };

    case types.PUT_VISITUPDATEUSERORGANISATION_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitUpdateUserOrganisationIsSuccess: true,
        isLoadingVisitUpdateUserOrganisation: false,
        visitUpdateUserOrganisation: action.data,
      };

    case types.PUT_VISITSETARRIVALON_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitSetArrivalOnIsSuccess: true,
        isLoadingVisitSetArrivalOn: false,
        visitSetArrivalOn: action.data,
      };

    case types.PUT_VISITSETARRIVALON_ASYNC.PENDING:
      return {
        ...state,
        putVisitSetArrivalOnIsSuccess: false,
        isLoadingVisitSetArrivalOn: true,
        visitSetArrivalOn: null,
      };

    case types.PUT_VISITSETARRIVALON_ASYNC.ERROR:
      return {
        ...state,
        putVisitSetArrivalOnIsSuccess: false,
        isLoadingVisitSetArrivalOn: false,
        visitSetArrivalOn: null,

        error: {
          ...state.error,
          visitSetArrivalOnError: action.error,
        },
      };

    case types.PUT_VISITSETDEPARTUREON_ASYNC.PENDING:
      return {
        ...state,
        putVisitSetDepartureOnIsSuccess: false,
        isLoadingVisitSetDepartureOn: true,
        visitSetDepartureOn: null,
      };

    case types.PUT_VISITSETDEPARTUREON_ASYNC.ERROR:
      return {
        ...state,
        putVisitSetDepartureOnIsSuccess: false,
        isLoadingVisitSetDepartureOn: false,
        visitSetDepartureOn: null,

        error: {
          ...state.error,
          visitSetDepartureOnError: action.error,
        },
      };
    case types.PUT_VISITSETDEPARTUREON_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitSetDepartureOnIsSuccess: true,
        isLoadingVisitSetDepartureOn: false,
        visitSetDepartureOn: action.data,
      };

    case types.PUT_VISITSETCONSULTON_ASYNC.PENDING:
      return {
        ...state,
        putVisitSetConsultOnIsSuccess: false,
        isLoadingVisitSetConsultOn: true,
        visitSetConsultOn: null,
      };

    case types.PUT_VISITSETCONSULTON_ASYNC.ERROR:
      return {
        ...state,
        putVisitSetConsultOnIsSuccess: false,
        isLoadingVisitSetConsultOn: false,
        visitSetConsultOn: null,

        error: {
          ...state.error,
          visitSetConsultOnError: action.error,
        },
      };
    case types.PUT_VISITSETCONSULTON_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitSetConsultOnIsSuccess: true,
        isLoadingVisitSetConsultOn: false,
        visitSetConsultOn: action.data,
      };

    case types.PUT_VISITSETQUEUENUMBER_ASYNC.PENDING:
      return {
        ...state,
        putVisitSetQueueNumberIsSuccess: false,
        isLoadingVisitSetQueueNumber: true,
        visitSetQueueNumber: null,
      };

    case types.PUT_VISITSETQUEUENUMBER_ASYNC.ERROR:
      return {
        ...state,
        putVisitSetQueueNumberIsSuccess: false,
        isLoadingVisitSetQueueNumber: false,
        visitSetQueueNumber: null,

        error: {
          ...state.error,
          visitSetQueueNumberError: action.error,
        },
      };

    case types.PUT_VISITSETQUEUENUMBER_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitSetQueueNumberIsSuccess: true,
        isLoadingVisitSetQueueNumber: false,
        visitSetQueueNumber: action.data,
      };

    case types.PUT_VISITUPDATEVISITNOTES_ASYNC.PENDING:
      return {
        ...state,
        putVisitUpdateVisitNotesIsSuccess: false,
        isLoadingVisitUpdateVisitNotes: true,
        visitUpdateVisitNotes: null,
      };

    case types.PUT_VISITUPDATEVISITNOTES_ASYNC.ERROR:
      return {
        ...state,
        putVisitUpdateVisitNotesIsSuccess: false,
        isLoadingVisitUpdateVisitNotes: false,
        visitUpdateVisitNotes: null,

        error: {
          ...state.error,
          visitUpdateVisitNotesError: action.error,
        },
      };

    case types.PUT_VISITUPDATEVISITNOTES_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitUpdateVisitNotesIsSuccess: true,
        isLoadingVisitUpdateVisitNotes: false,
        visitUpdateVisitNotes: action.data,
      };

    case types.PUT_VISITPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putVisitPrintDateSuccess: false,
        isLoadingPutVisitPrintDate: true,
        putVisitPrintDate: null,
      };

    case types.PUT_VISITPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putVisitPrintDateSuccess: false,
        isLoadingPutVisitPrintDate: false,
        putVisitPrintDate: null,
        error: { ...state.error, putVisitPrintDateError: action.error },
      };

    case types.PUT_VISITPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putVisitPrintDateSuccess: true,
        isLoadingPutVisitPrintDate: false,
        putVisitPrintDate: action.data,
      };

    case types.PUT_UPDATEUSERMEDICALHISTORY_ASYNC.PENDING:
      return {
        ...state,
        putUpdateUserMedicalHistorySuccess: false,
        isLoadingpPutUpdateUserMedicalHistory: true,
        putUpdateUserMedicalHistory: null,
      };

    case types.PUT_UPDATEUSERMEDICALHISTORY_ASYNC.ERROR:
      return {
        ...state,
        putUpdateUserMedicalHistorySuccess: false,
        isLoadingpPutUpdateUserMedicalHistory: false,
        putUpdateUserMedicalHistory: null,
        error: {
          ...state.error,
          putUpdateUserMedicalHistoryError: action.error,
        },
      };

    case types.PUT_UPDATEUSERMEDICALHISTORY_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateUserMedicalHistorySuccess: true,
        isLoadingpPutUpdateUserMedicalHistory: false,
        putUpdateUserMedicalHistory: action.data,
      };
    case types.GET_VISITPRESCRIPTIONMSGLIST_ASYNC.PENDING:
      return {
        ...state,
        getVisitPrescriptionMsgListIsSuccess: false,
        isLoadingGetVisitPrescriptionMsgList: true,
        getVisitPrescriptionMsgList: null,
      };

    case types.GET_VISITPRESCRIPTIONMSGLIST_ASYNC.ERROR:
      return {
        ...state,
        getVisitPrescriptionMsgListIsSuccess: false,
        isLoadingGetVisitPrescriptionMsgList: false,
        getVisitPrescriptionMsgList: null,
        error: {
          ...state.error,
          getVisitPrescriptionMsgListError: action.error,
        },
      };

    case types.GET_VISITPRESCRIPTIONMSGLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitPrescriptionMsgListIsSuccess: true,
        isLoadingGetVisitPrescriptionMsgList: false,
        getVisitPrescriptionMsgList: action.data,
      };

    case types.GET_VISITMEDICINEINFO_ASYNC.PENDING:
      return {
        ...state,
        getVisitMedicineInfoIsSuccess: false,
        isLoadingGetVisitMedicineInfo: true,
        getVisitMedicineInfo: null,
      };

    case types.GET_VISITMEDICINEINFO_ASYNC.ERROR:
      return {
        ...state,
        getVisitMedicineInfoIsSuccess: false,
        isLoadingGetVisitMedicineInfo: false,
        getVisitMedicineInfo: null,
        error: {
          ...state.error,
          getVisitMedicineInfoError: action.error,
        },
      };

    case types.GET_VISITMEDICINEINFO_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitMedicineInfoIsSuccess: true,
        isLoadingGetVisitMedicineInfo: false,
        getVisitMedicineInfo: action.data,
      };

    case types.GET_VISITACUPOINTINFO_ASYNC.PENDING:
      return {
        ...state,
        getVisitAcupointInfoIsSuccess: false,
        isLoadingGetVisitAcupointInfo: true,
        getVisitAcupointInfo: null,
      };

    case types.GET_VISITACUPOINTINFO_ASYNC.ERROR:
      return {
        ...state,
        getVisitAcupointInfoIsSuccess: false,
        isLoadingGetVisitAcupointInfo: false,
        getVisitAcupointInfo: null,
        error: {
          ...state.error,
          getVisitAcupointInfoError: action.error,
        },
      };

    case types.GET_VISITACUPOINTINFO_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitAcupointInfoIsSuccess: true,
        isLoadingGetVisitAcupointInfo: false,
        getVisitAcupointInfo: action.data,
      };

    case types.GET_VISITTREATMENTINFO_ASYNC.PENDING:
      return {
        ...state,
        getVisitTreatmentInfoIsSuccess: false,
        isLoadingGetVisitTreatmentInfo: true,
        getVisitTreatmentInfo: null,
      };

    case types.GET_VISITTREATMENTINFO_ASYNC.ERROR:
      return {
        ...state,
        getVisitTreatmentInfoIsSuccess: false,
        isLoadingGetVisitTreatmentInfo: false,
        getVisitTreatmentInfo: null,
        error: {
          ...state.error,
          getVisitTreatmentInfoError: action.error,
        },
      };

    case types.GET_VISITTREATMENTINFO_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitTreatmentInfoIsSuccess: true,
        isLoadingGetVisitTreatmentInfo: false,
        getVisitTreatmentInfo: action.data,
      };

    case types.POST_VISITCHANGEBRANCH_ASYNC.PENDING:
      return {
        ...state,
        postVisitChangeBranchIsSuccess: false,
        isLoadingPostVisitChangeBranch: true,
        postVisitChangeBranch: null,
      };

    case types.POST_VISITCHANGEBRANCH_ASYNC.ERROR:
      return {
        ...state,
        postVisitChangeBranchIsSuccess: false,
        isLoadingPostVisitChangeBranch: false,
        postVisitChangeBranch: null,
        error: {
          ...state.error,
          postVisitChangeBranchError: action.error,
        },
      };

    case types.POST_VISITCHANGEBRANCH_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitChangeBranchIsSuccess: true,
        isLoadingPostVisitChangeBranch: false,
        postVisitChangeBranch: action.data,
      };
    case types.POST_VISITCHANGEBRANCHTREATMENTONLY_ASYNC.PENDING:
      return {
        ...state,
        postVisitChangeBranchTreatmentOnlyIsSuccess: false,
        isLoadingPostVisitChangeBranchTreatmentOnly: true,
        postVisitChangeBranchTreatmentOnly: null,
      };

    case types.POST_VISITCHANGEBRANCHTREATMENTONLY_ASYNC.ERROR:
      return {
        ...state,
        postVisitChangeBranchTreatmentOnlyIsSuccess: false,
        isLoadingPostVisitChangeBranchTreatmentOnly: false,
        postVisitChangeBranchTreatmentOnly: null,
        error: {
          ...state.error,
          postVisitChangeBranchTreatmentOnlyError: action.error,
        },
      };

    case types.POST_VISITCHANGEBRANCHTREATMENTONLY_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitChangeBranchTreatmentOnlyIsSuccess: true,
        isLoadingPostVisitChangeBranchTreatmentOnly: false,
        postVisitChangeBranchTreatmentOnly: action.data,
      };

    case types.POST_VISITCHANGEBRANCHACUPOINTONLY_ASYNC.PENDING:
      return {
        ...state,
        postVisitChangeBranchAcupointOnlyIsSuccess: false,
        isLoadingPostVisitChangeBranchAcupointOnly: true,
        postVisitChangeBranchAcupointOnly: null,
      };

    case types.POST_VISITCHANGEBRANCHACUPOINTONLY_ASYNC.ERROR:
      return {
        ...state,
        postVisitChangeBranchAcupointOnlyIsSuccess: false,
        isLoadingPostVisitChangeBranchAcupointOnly: false,
        postVisitChangeBranchAcupointOnly: null,
        error: {
          ...state.error,
          postVisitChangeBranchAcupointOnlyError: action.error,
        },
      };

    case types.POST_VISITCHANGEBRANCHACUPOINTONLY_ASYNC.SUCCESS:
      return {
        ...state,
        postVisitChangeBranchAcupointOnlyIsSuccess: true,
        isLoadingPostVisitChangeBranchAcupointOnly: false,
        postVisitChangeBranchAcupointOnly: action.data,
      };

    case types.PUT_UPDATEVISITACUPOINTS_ASYNC.PENDING:
      return {
        ...state,
        putUpdateVisitAcupointsIsSuccess: false,
        isLoadingPutUpdateVisitAcupoints: true,
        putUpdateVisitAcupoints: null,
      };

    case types.PUT_UPDATEVISITACUPOINTS_ASYNC.ERROR:
      return {
        ...state,
        putUpdateVisitAcupointsIsSuccess: false,
        isLoadingPutUpdateVisitAcupoints: false,
        putUpdateVisitAcupoints: null,
        error: {
          ...state.error,
          putUpdateVisitAcupointsError: action.error,
        },
      };

    case types.PUT_UPDATEVISITACUPOINTS_ASYNC.SUCCESS:
      return {
        ...state,
        putUpdateVisitAcupointsIsSuccess: true,
        isLoadingPutUpdateVisitAcupoints: false,
        putUpdateVisitAcupoints: action.data,
      };

    case types.GET_VISITPOSTALCODESURCHARGE_ASYNC.PENDING:
      return {
        ...state,
        getVisitPostalCodeSurchargeListIsSuccess: false,
        isLoadingGetVisitPostalCodeSurchargeList: true,
        getVisitPostalCodeSurchargeList: null,
      };

    case types.GET_VISITPOSTALCODESURCHARGE_ASYNC.ERROR:
      return {
        ...state,
        getVisitPostalCodeSurchargeListIsSuccess: false,
        isLoadingGetVisitPostalCodeSurchargeList: false,
        getVisitPostalCodeSurchargeList: null,
        error: {
          ...state.error,
          getVisitPostalCodeSurchargeListError: action.error,
        },
      };

    case types.GET_VISITPOSTALCODESURCHARGE_ASYNC.SUCCESS:
      return {
        ...state,
        getVisitPostalCodeSurchargeListIsSuccess: true,
        isLoadingGetVisitPostalCodeSurchargeList: false,
        getVisitPostalCodeSurchargeList: action.data,
      };

    default:
      return state;
  }
};
