//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
//actions
import {
  putBrewingWIPMode,
  getBrewingSummaryList,
  putBrewingPendingStatus,
  postSubmitBrewing,
  getBrewingById,
} from "../../../actions/brewingActions";
//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
//import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as PendingLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";
import OrderDetailPage from "./OrderDetailPage";
import * as pageConfigure from "../../../_constants/pageConstants";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import * as generalConstants from "../../../_constants/generalConstants";

import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";

export default function BrewingListTable({
  setBrewingInformationView = () => null,
  setRefreshTimeStamp = () => null,
  refreshTimeStamp = null,
  dashboardView = false,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const localStorageNameKey = "brewingListTable";
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_BREWING_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;

  // const options = {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
    },
    {
      Header: t("translation:Group"),
      accessor: "store_medicine_header.name",
    },
    {
      Header: t("translation:Physician"),
      accessor: "store_header.physician_name",
    },
    {
      Header: t("translation:Staff Name"),
      accessor: "store_header.staff_name",
    },
    {
      Header: t("translation:Order Description"),
      accessor: "store_header.desc",
    },
    {
      Header: t("translation:Physician/ Staff Contact Number"),
      sortable: false,
      accessor: "store_header.staff_contact_number",
      Cell: ({ row }) => {
        if (
          row.original.store_header.work_order_type ===
          generalConstants.VISIT_WORK_ORDER_TYPE
        )
          return row.original.store_header.physician_contact_number;
        if (
          row.original.store_header.work_order_type ===
          generalConstants.STORE_WORK_ORDER_TYPE
        )
          return row.original.store_header.staff_contact_number;
        return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsWUpdateAccess = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "statusAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.PENDING_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcess(row.original)}>
              <ProceedLogo title={t("translation:Proceed")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: "",
      accessor: "Pending",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowPending(row.original)}>
              <PendingLogo title={t("translation:Pending")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    {
      Header: "",
      accessor: "ProcessAll",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcessAll(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
    },
    {
      Header: t("translation:Group"),
      accessor: "store_medicine_header.name",
    },
    {
      Header: t("translation:Physician"),
      accessor: "store_header.physician_name",
    },
    {
      Header: t("translation:Staff Name"),
      accessor: "store_header.staff_name",
    },
    {
      Header: t("translation:Order Description"),

      accessor: "store_header.desc",
    },
    {
      Header: t("translation:Physician/ Staff Contact Number"),
      sortable: false,
      accessor: "store_header.staff_contact_number",
      Cell: ({ row }) => {
        if (
          row.original.store_header.work_order_type ===
          generalConstants.VISIT_WORK_ORDER_TYPE
        )
          return row.original.store_header.physician_contact_number;
        if (
          row.original.store_header.work_order_type ===
          generalConstants.STORE_WORK_ORDER_TYPE
        )
          return row.original.store_header.staff_contact_number;
        return "";
      },
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    search_brewing_order_number_value: null,
    search_store_order_number_value: null,
    search_status_type_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    search_staff_name_value: null,
    search_staff_contact_number_value: null,
    search_store_header_desc_value: null,
    order_by_field: null,
    order_by_direction: null,
  });

  const dispatch = useDispatch();
  const prevGetBrewingByIdOnStatus = useRef();
  const brewing = useSelector((state) => state.brewing);
  const common = useSelector((state) => state.common);

  // const [editOrderInfo, setEditOrderInfo] = React.useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmPendingOpen, setConfirmPendingOpen] = useState(false);
  const [confirmProcessAllOpen, setConfirmProceeAllOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [orderDetailView, setOrderDetailView] = React.useState(false);
  const [isCompleteAll, setIsCompleteAll] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [orderDetailInfo, setOrderDetailInfo] = React.useState(null);
  const prevWorkOrderWIPStatus = useRef();
  const prevWorkOrderPendingStatus = useRef();
  const prevSubmitBrewingStatus = useRef();
  const prevRefreshTimeStamp = useRef();
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [WIPSuccessAlarm, setWIPSuccessAlarm] = useState(false);
  const [WIPErrorAlarm, setWIPErrorAlarm] = useState(false);
  const [WIPmsgAlarm, setWIPMsgAlarm] = React.useState([]);
  const [PendingSuccessAlarm, setPendingSuccessAlarm] = useState(false);
  const [PendingErrorAlarm, setPendingErrorAlarm] = useState(false);
  const [PendingmsgAlarm, setPendingMsgAlarm] = React.useState([]);

  //functions
  const handleRowProcess = (value) => {
    setConfirmOpen(true);
    setSelectedValue(value);
  };

  const handleRowPending = (value) => {
    setConfirmPendingOpen(true);
    setSelectedValue(value);
  };

  const handleRowProcessAll = (value) => {
    setConfirmProceeAllOpen(true);
    setSelectedValue(value);
  };

  const onSubmitBrewing = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    setIsCompleteAll(true);
    dispatch(getBrewingById.pending(selectedValue.id));

    // const brewingHeader = {
    //   brewing_header: {
    //     brewing_details: [
    //       {
    //         id: 0,
    //       },
    //     ],
    //     id: selectedValue.id,
    //     order_number: selectedValue.order_number,
    //   },
    // };

    // dispatch(postSubmitBrewing.pending(brewingHeader));
  };

  const handleConfirmProcess = () => {
    dispatch(putBrewingWIPMode.pending(selectedValue.id));
  };

  const handleConfirmPending = () => {
    dispatch(putBrewingPendingStatus.pending(selectedValue.id));
  };

  const handleRowDetail = (value) => {
    //
    //setOrderDetailInfo(value);
    dispatch(getBrewingById.pending(value.id));
    setOrderDetailView(true);
    setSubmitErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setPendingErrorAlarm(false);
    setPendingSuccessAlarm(false);
    setIsCompleteAll(false);
  };

  //useEffects
  useEffect(() => {
    prevWorkOrderWIPStatus.current = false;
    prevWorkOrderPendingStatus.current = false;
    prevSubmitBrewingStatus.current = false;
    prevGetBrewingByIdOnStatus.current = false;
    prevRefreshTimeStamp.current = null;
  }, []);

  useEffect(() => {
    if (
      refreshTimeStamp !== prevRefreshTimeStamp.current &&
      dashboardView &&
      !orderDetailView
    ) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;
      dispatch(getBrewingSummaryList.pending(pageInfo));
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView, orderDetailView]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (brewing.isLoadingBrewingById !== prevGetBrewingByIdOnStatus.current) {
      //
      //check the previous ref with current state
      prevGetBrewingByIdOnStatus.current = brewing.isLoadingBrewingById;

      if (
        brewing.getBrewingByIdSuccess === true &&
        brewing.isLoadingBrewingById === false
      ) {
        if (isCompleteAll) {
          const brewingHeader = {
            brewing_header: {
              brewing_details: [
                {
                  id: 0,
                },
              ],
              id: selectedValue.id,
              is_order_completed: true,
              order_number: selectedValue.order_number,
            },
          };

          dispatch(postSubmitBrewing.pending(brewingHeader));
          setIsCompleteAll(false);
        } else {
          setOrderDetailInfo(brewing.brewingById.brewing_header);
        }
      }
    }
  }, [isCompleteAll, brewing, selectedValue, dispatch, setOrderDetailInfo]);

  useEffect(() => {
    if (
      brewing.isLoadingBrewingPendingStatus !==
      prevWorkOrderPendingStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderPendingStatus.current =
        brewing.isLoadingBrewingPendingStatus;

      if (
        brewing.putBrewingPendingStatusSuccess === true &&
        brewing.isLoadingBrewingPendingStatus === false
      ) {
        window.scrollTo(0, 0);
        setPendingErrorAlarm(false);
        setPendingSuccessAlarm(true);
        dispatch(getBrewingSummaryList.pending(pageInfo));
      }

      if (
        brewing.putBrewingPendingStatusSuccess === false &&
        brewing.isLoadingBrewingPendingStatus === false
      ) {
        window.scrollTo(0, 0);
        setPendingErrorAlarm(true);
        setPendingSuccessAlarm(false);
        setPendingMsgAlarm(brewing.error.brewingPendingStatusError);
      }
    }
  }, [dispatch, brewing, pageInfo]);

  useEffect(() => {
    if (brewing.isLoadingBrewingWIPMode !== prevWorkOrderWIPStatus.current) {
      //
      //check the previous ref with current state
      prevWorkOrderWIPStatus.current = brewing.isLoadingBrewingWIPMode;

      if (
        brewing.putBrewingWIPModeSuccess === true &&
        brewing.isLoadingBrewingWIPMode === false
      ) {
        window.scrollTo(0, 0);
        setWIPErrorAlarm(false);
        setWIPSuccessAlarm(true);
        dispatch(getBrewingSummaryList.pending(pageInfo));
      }

      if (
        brewing.putBrewingWIPModeSuccess === false &&
        brewing.isLoadingBrewingWIPMode === false
      ) {
        window.scrollTo(0, 0);
        setWIPErrorAlarm(true);
        setWIPSuccessAlarm(false);
        setWIPMsgAlarm(brewing.error.brewingWIPModeError);
      }
    }
  }, [dispatch, brewing, pageInfo]);
  //
  useEffect(() => {
    if (brewing.isLoadingSubmitBrewing !== prevSubmitBrewingStatus.current) {
      //check the previous ref with current state
      prevSubmitBrewingStatus.current = brewing.isLoadingSubmitBrewing;
      //
      if (
        brewing.postSubmitBrewingSuccess === true &&
        brewing.isLoadingSubmitBrewing === false
      ) {
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        dispatch(getBrewingSummaryList.pending(pageInfo));
        window.scrollTo(0, 0);
      }
      if (
        brewing.postSubmitBrewingSuccess === false &&
        brewing.isLoadingSubmitBrewing === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(brewing.error.submitBrewingError);
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, brewing, pageInfo]);

  useEffect(() => {
    if (orderDetailView === true) {
      setBrewingInformationView(true);
    } else {
      setBrewingInformationView(false);
    }
  }, [orderDetailView, setBrewingInformationView]);

  useEffect(() => {
    //dispatch(getDispensaryList.pending(pageInfo));

    if (!orderDetailView && !dashboardView) {
      dispatch(getBrewingSummaryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, orderDetailView, dashboardView]);
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_brewing_order_number_value: value,
        search_store_order_number_value: value,
        search_staff_name_value: value,
        search_staff_contact_number_value: value,
        search_physician_name_value: value,
        search_store_header_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_brewing_order_number_value: null,
        search_store_order_number_value: null,
        search_staff_name_value: null,
        search_staff_contact_number_value: null,
        search_physician_name_value: null,
        search_store_header_desc_value: null,
      });
    }
  };

  if (
    brewing.getBrewingSummaryListSuccess === false &&
    brewing.isLoadingBrewingSummaryList === false
  ) {
    return (
      <Alert severity="error">{brewing.error.brewingSummaryListError}</Alert>
    );
  } else if (
    brewing.getBrewingByIdSuccess === false &&
    brewing.isLoadingBrewingById === false &&
    (orderDetailView || orderDetailView)
  ) {
    return <Alert severity="error">{brewing.error.brewingByIdError}</Alert>;
  } else if (common.getGeneralStatusIsSuccess === true && !orderDetailView) {
    return (
      <>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Brewing has been submitted")}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={WIPSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.WIP_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={WIPErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {WIPmsgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={PendingSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.PENDING_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={PendingErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {PendingmsgAlarm}
          </Alert>
        </Collapse>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmPendingOpen}
          setOpen={setConfirmPendingOpen}
          onConfirm={handleConfirmPending}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmProcessAllOpen}
          setOpen={setConfirmProceeAllOpen}
          onConfirm={onSubmitBrewing}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <SortingSearchTable
          tableHeading=""
          searchPlaceholder={t(
            "translation:Brewing Order No/ Store Order No/ Physician/ Staff Name/ Staff Contact Number/ Description"
          )}
          statusPlaceholder={t("translation:Order Status")}
          statusOption={common.generalStatusList.status_general}
          searchValue={searchValue}
          columns={updateAccess ? columnsWUpdateAccess : columns}
          data={
            brewing.brewingSummaryList
              ? brewing.brewingSummaryList.brewing_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            brewing.brewingSummaryList
              ? brewing.brewingSummaryList.total_pages
              : 0
          }
          loading={brewing.isLoadingBrewingSummaryList}
          totalItems={
            brewing.brewingSummaryList
              ? brewing.brewingSummaryList.total_items
              : 0
          }
          handleSearch={handleSearch}
          searchDate={true}
          searchDatePlaceholder={t("translation:Creation Date")}
          localStorageName={localStorageNameKey}
        />
      </>
    );
  } else if (orderDetailView && orderDetailInfo !== null) {
    return (
      <OrderDetailPage
        orderDetailInfo={orderDetailInfo}
        setOrderDetailView={() => {
          setOrderDetailView(false);
          setOrderDetailInfo(null);
          setRefreshTimeStamp(moment());
        }}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
