import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/deliveryOrderConstants";

export const getDeliveryOrderSummaryList = {
  pending: (data) =>
    createAction(types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const getDeliveryOrderList = {
  pending: (data) =>
    createAction(types.GET_DELIVERYORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYORDERLIST_ASYNC.ERROR, { error }),
};

export const getDeliveryCourierByBranch = {
  pending: (data) =>
    createAction(types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.ERROR, { error }),
};

export const postSaveDeliveryOrder = {
  pending: (data) =>
    createAction(types.POST_SAVEDELIVERYORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SAVEDELIVERYORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SAVEDELIVERYORDER_ASYNC.ERROR, { error }),
};

export const postSubmitDeliveryOrder = {
  pending: (data) =>
    createAction(types.POST_SUBMITDELIVERYORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITDELIVERYORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITDELIVERYORDER_ASYNC.ERROR, { error }),
};

export const postSubmitAllDelivery = {
  pending: (data) =>
    createAction(types.POST_SUBMITALLDELIVERY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITALLDELIVERY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITALLDELIVERY_ASYNC.ERROR, { error }),
};

export const putDeliveryOrderWIPMode = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYORDERWIPMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DELIVERYORDERWIPMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DELIVERYORDERWIPMODE_ASYNC.ERROR, { error }),
};

export const putDeliveryOrderEditMode = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYORDEREDITMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DELIVERYORDEREDITMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DELIVERYORDEREDITMODE_ASYNC.ERROR, { error }),
};

export const putDeliveryOrderPendingMode = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.ERROR, { error }),
};

export const getDeliveryCourierList = {
  pending: (data) =>
    createAction(types.GET_DELIVERYCOURIERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYCOURIERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DELIVERYCOURIERLIST_ASYNC.ERROR, { error }),
};

export const getDeliveryOrderByStoreHeaderId = {
  pending: (data) =>
    createAction(types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.ERROR, { error }),
};

export const getStoreHeaderList = {
  pending: (data) =>
    createAction(types.GET_STOREHEADERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_STOREHEADERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_STOREHEADERLIST_ASYNC.ERROR, { error }),
};

export const getStoreHeaderSummaryList = {
  pending: (data) =>
    createAction(types.GET_STOREHEADERSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_STOREHEADERSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_STOREHEADERSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const putDeliveryOrderPrintDate = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYORDERPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DELIVERYORDERPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DELIVERYORDERPRINTDATE_ASYNC.ERROR, { error }),
};

export const putDeliveryOrderUpdateStoreDeliveryAddress = {
  pending: (data) =>
    createAction(
      types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.PENDING,
      {
        data,
      }
    ),
  success: (data) =>
    createAction(
      types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.SUCCESS,
      {
        data,
      }
    ),
  error: (error) =>
    createAction(
      types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.ERROR,
      {
        error,
      }
    ),
};

export const postExportDeliveryOrders = {
  pending: (data) =>
    createAction(types.POST_EXPORTDELIVERYORDERS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_EXPORTDELIVERYORDERS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_EXPORTDELIVERYORDERS_ASYNC.ERROR, { error }),
};

export const postExportDeliveryOrdersV2 = {
  pending: (data) =>
    createAction(types.POST_EXPORTDELIVERYORDERSV2_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_EXPORTDELIVERYORDERSV2_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_EXPORTDELIVERYORDERSV2_ASYNC.ERROR, { error }),
};
