import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  putStoreOrderStatus,
  getDispensaryList,
  getDispensarySummaryList,
  getDispensaryById,
  putDispensaryWIPMode,
  postSubmitDispensary,
  putDispensaryPendingStatus,
  putDispensaryPrintDate,
  putDispensaryVisitPrintDate,
  putDispensaryStorePrintDate,
  getDispensaryInvoiceOrder,
  putDispensaryInvoicePrintDate,
} from "../actions/dispensaryActions";
import * as types from "../_constants/dispensaryConstants";
import * as notification from "../_constants/notificationConstants";
import dispensaryApis from "../_apis/dispensaryApis";
export default function* dispensarySagas() {
  yield all([
    takeLatest(
      types.PUT_STOREORDERSTATUS_ASYNC.PENDING,
      putStoreOrderStatusCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_DISPENSARYLIST_ASYNC.PENDING, getDispensaryListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_DISPENSARYSUMMARYLIST_ASYNC.PENDING,
      getDispensarySummaryListCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_DISPENSARYBYID_ASYNC.PENDING, getDispensaryByIdCall),
  ]);

  yield all([
    takeLatest(
      types.PUT_DISPENSARYWIPMODE_ASYNC.PENDING,
      putDispensaryWIPModeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DISPENSARYPENDINGSTATUS_ASYNC.PENDING,
      putDispensaryPendingStatusCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITDISPENSARY_ASYNC.PENDING,
      postSubmitDispensaryCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DISPENSARYPRINTDATE_ASYNC.PENDING,
      putDispensaryPrintDateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DISPENSARYVISITPRINTDATE_ASYNC.PENDING,
      putDispensaryVisitPrintDateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DISPENSARYSTOREPRINTDATE_ASYNC.PENDING,
      putDispensaryStorePrintDateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DISPENSARYINVOICEORDER_ASYNC.PENDING,
      getDispensaryInvoiceOrderCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DISPENSARYINVOICEPRINTDATE_ASYNC.PENDING,
      putDispensaryInvoicePrintDateCall
    ),
  ]);
}

function* putStoreOrderStatusCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.putStoreOrderStatusApi,
      userModel.data
    );
    yield put(putStoreOrderStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putStoreOrderStatus.error(error.response?.data?.message));
    else yield put(putStoreOrderStatus.error(notification.CONST_NOINTERNET));
  }
}

function* getDispensaryListCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.getDispensaryListApi,
      userModel.data
    );
    yield put(getDispensaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDispensaryList.error(error.response?.data?.message));
    else yield put(getDispensaryList.error(notification.CONST_NOINTERNET));
  }
}

function* getDispensarySummaryListCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.getDispensarySummaryListApi,
      userModel.data
    );
    yield put(getDispensarySummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDispensarySummaryList.error(error.response?.data?.message));
    else
      yield put(getDispensarySummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* getDispensaryByIdCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.getDispensaryByIdApi,
      userModel.data
    );
    yield put(getDispensaryById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDispensaryById.error(error.response?.data?.message));
    else yield put(getDispensaryById.error(notification.CONST_NOINTERNET));
  }
}

function* putDispensaryWIPModeCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.putDispensaryWIPModeApi,
      userModel.data
    );
    yield put(putDispensaryWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDispensaryWIPMode.error(error.response?.data?.message));
    else yield put(putDispensaryWIPMode.error(notification.CONST_NOINTERNET));
  }
}

function* putDispensaryPendingStatusCall(Model) {
  try {
    const data = yield call(
      dispensaryApis.putDispensaryPendingStatusApi,
      Model.data
    );
    yield put(putDispensaryPendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDispensaryPendingStatus.error(error.response?.data?.message)
      );
    else
      yield put(
        putDispensaryPendingStatus.error(notification.CONST_NOINTERNET)
      );
  }
}

function* postSubmitDispensaryCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.postSubmitDispensaryApi,
      userModel.data
    );
    yield put(postSubmitDispensary.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitDispensary.error(error.response?.data?.message));
    else yield put(postSubmitDispensary.error(notification.CONST_NOINTERNET));
  }
}

function* putDispensaryPrintDateCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.putDispensaryPrintDateApi,
      userModel.data
    );
    yield put(putDispensaryPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDispensaryPrintDate.error(error.response?.data?.message));
    else yield put(putDispensaryPrintDate.error(notification.CONST_NOINTERNET));
  }
}

function* putDispensaryVisitPrintDateCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.putDispensaryVisitPrintDateApi,
      userModel.data
    );
    yield put(putDispensaryVisitPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDispensaryVisitPrintDate.error(error.response?.data?.message)
      );
    else
      yield put(
        putDispensaryVisitPrintDate.error(notification.CONST_NOINTERNET)
      );
  }
}

function* putDispensaryStorePrintDateCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.putDispensaryStorePrintDateApi,
      userModel.data
    );
    yield put(putDispensaryStorePrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDispensaryStorePrintDate.error(error.response?.data?.message)
      );
    else
      yield put(
        putDispensaryStorePrintDate.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDispensaryInvoiceOrderCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.getDispensaryInvoiceOrderApi,
      userModel.data
    );
    yield put(getDispensaryInvoiceOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDispensaryInvoiceOrder.error(error.response?.data?.message));
    else
      yield put(getDispensaryInvoiceOrder.error(notification.CONST_NOINTERNET));
  }
}

function* putDispensaryInvoicePrintDateCall(userModel) {
  try {
    const data = yield call(
      dispensaryApis.putDispensaryInvoicePrintDateApi,
      userModel.data
    );
    yield put(putDispensaryInvoicePrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDispensaryInvoicePrintDate.error(error.response?.data?.message)
      );
    else
      yield put(
        putDispensaryInvoicePrintDate.error(notification.CONST_NOINTERNET)
      );
  }
}
