//lib
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Font } from "@react-pdf/renderer";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
// import IconButton from "@mui/material/IconButton";
// import Alert from "@mui/material/Alert";
// import Collapse from "@mui/material/Collapse";
// import CloseIcon from "@mui/icons-material/Close";
//action

import { getPaymentMethod } from "../../../actions/commonActions";
//import { getOrganisationLogo } from "../../../actions/curUserActions";
import { postCreatePresignedUrlOrganisationLogo } from "../../../actions/cloudStorageActions";
import { putInvoicePrintDate } from "../../../actions/invoiceActions";
//components
import Panel from "../../../components/Panel";

import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import InvoiceDetailsTable from "./components/InvoiceDetailsTable";
import * as generalConstants from "../../../_constants/generalConstants";
import PaymentTable from "../../visit/Consultation/components/editOrder/PaymentTable";
import NotoSansHansRegular from "../../../components/PDF/NotoSansHans-Regular-2.ttf";
import InvoicePDF from "../../../components/PDF/InvoicePDF";
import AmountTotal from "./components/AmountTotal";
import { useStyles } from "../../../components/globalStyles";
import S3OrganisationLogo, {
  setPrintingOrganisationLogo,
} from "../../../components/functions/S3OrganisationLogo";
import EditInvoicePage from "./components/EditInvoicePage";

//register font before calling pdf file
Font.register({
  family: "NotoSansSChinese",
  fonts: [{ src: NotoSansHansRegular }],
});

Font.registerHyphenationCallback((word) =>
  word.length === 1 ? [word] : Array.from(word).map((char) => char)
);
//style

const DetailPage = ({
  setOrderProcessView,
  setOrderProcessInfo = () => null,
  orderProcessInfo,
}) => {
  const [pdfFontLoaded, setPdfFontLoaded] = useState(false);
  const classes = useStyles();
  const loadFonts = useCallback(async () => {
    await Promise.all([Font.load({ fontFamily: "NotoSansSChinese" })]).then(
      () => {
        setPdfFontLoaded(true);
      }
    );
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);

  const dispatch = useDispatch();
  const [TCMLogo, setTCMLOGO] = useState(null);
  const common = useSelector((state) => state.common);
  const { t } = useTranslation();
  const [payment, setPayment] = React.useState([]);
  const [paidAmount, setPaidAmount] = React.useState(0);
  const [allowPrint, setAllowPrint] = useState(false);
  const prevSetPrintDateOnStatus = useRef();
  const price = {
    deliveryFee: orderProcessInfo.store_header.delivery_total_price_for_costing
      ? orderProcessInfo.store_header.delivery_total_price_for_costing
      : 0,
    brewingFee: orderProcessInfo.store_header.brewing_total_price_for_costing
      ? orderProcessInfo.store_header.brewing_total_price_for_costing
      : 0,
    consultationFee: orderProcessInfo.store_header.consultation_total_price
      ? orderProcessInfo.store_header.consultation_total_price
      : 0,
    medicineTab: orderProcessInfo.store_header.medicine_total_price
      ? orderProcessInfo.store_header.medicine_total_price
      : 0,
    medicineFee: orderProcessInfo.store_header.medicine_price_for_costing
      ? orderProcessInfo.store_header.medicine_price_for_costing
      : 0,

    acupuncturePrice: orderProcessInfo.store_header.acupuncture_total_price
      ? orderProcessInfo.store_header.acupuncture_total_price
      : 0,
    treatmentPrice: orderProcessInfo.store_header.treatment_total_price
      ? orderProcessInfo.store_header.treatment_total_price
      : 0,
    miscPrice: orderProcessInfo.store_header.misc_total_price
      ? orderProcessInfo.store_header.misc_total_price
      : 0,
  };

  const discount = {
    deliveryFee: orderProcessInfo.store_header.delivery_discount_amt
      ? orderProcessInfo.store_header.delivery_discount_amt
      : 0,
    brewingFee: orderProcessInfo.store_header.brewing_discount_amt
      ? orderProcessInfo.store_header.brewing_discount_amt
      : 0,
    consultationFee: orderProcessInfo.store_header.consultation_discount_amt
      ? orderProcessInfo.store_header.consultation_discount_amt
      : 0,
    medicineTab: orderProcessInfo.store_header.medicine_discount_amt
      ? orderProcessInfo.store_header.medicine_discount_amt
      : 0,
    acupuncturePrice: orderProcessInfo.store_header.acupuncture_discount_amt
      ? orderProcessInfo.store_header.acupuncture_discount_amt
      : 0,
    treatmentPrice: orderProcessInfo.store_header.treatment_discount_amt
      ? orderProcessInfo.store_header.treatment_discount_amt
      : 0,
    miscPrice: orderProcessInfo.store_header.misc_discount_amt
      ? orderProcessInfo.store_header.misc_discount_amt
      : 0,
  };

  const extraAmt = {
    deliveryFee: orderProcessInfo.store_header.delivery_extra_amt
      ? orderProcessInfo.store_header.delivery_extra_amt
      : 0,
    brewingFee: orderProcessInfo.store_header.brewing_extra_amt
      ? orderProcessInfo.store_header.brewing_extra_amt
      : 0,
    consultationFee: orderProcessInfo.store_header.consultation_extra_amt
      ? orderProcessInfo.store_header.consultation_extra_amt
      : 0,
    medicineTab: orderProcessInfo.store_header.medicine_extra_amt
      ? orderProcessInfo.store_header.medicine_extra_amt
      : 0,
    acupuncturePrice: orderProcessInfo.store_header.acupuncture_extra_amt
      ? orderProcessInfo.store_header.acupuncture_extra_amt
      : 0,
  };

  const [treatmentDetail, setTreatmentDetail] = useState(null);
  const [miscDetail, setMiscDetail] = useState(null);
  // const [logoError, setLogoError] = useState(false);
  // const [logoErrorMsg, setLogoErrorMsg] = useState(null);
  //const curUser = useSelector((state) => state.curUser);
  const invoice = useSelector((state) => state.invoice);
  const [print, setPrint] = React.useState(0);
  const invoiceDetailsWoDeleted = () => {
    return orderProcessInfo.invoice_details.filter(
      (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
    );
  };

  const memoInvoiceDetailsWoDeleted = React.useMemo(invoiceDetailsWoDeleted, [
    orderProcessInfo,
  ]);

  const setPrintingLogo = () => {
    setTCMLOGO(null);
    //const organisationId = orderProcessInfo.organisation.id;

    //dispatch(getOrganisationLogo.pending(organisationId));
    //dispatch(getOrganisation.pending(organisationId));

    setPrint(new Date().valueOf());
    // dispatch(
    //   putInvoicePrintDate.pending({
    //     invoice_header: orderProcessInfo,
    //   })
    // );
    setAllowPrint(false);
    const submitJson = setPrintingOrganisationLogo(
      orderProcessInfo.organisation.codename,
      "organisation",
      orderProcessInfo.organisation.logo
    );
    dispatch(postCreatePresignedUrlOrganisationLogo.pending(submitJson));
  };

  const setPrintingDate = () => {
    dispatch(
      putInvoicePrintDate.pending({
        invoice_header: orderProcessInfo,
      })
    );
  };

  //useEffects
  useEffect(() => {
    prevSetPrintDateOnStatus.current = false;
  }, []);

  useEffect(() => {
    // const deafultOrganisationId =
    //   curUser.organisationInfo.user_organisations?.find(
    //     (o) => o.is_default === true
    //   ).organisations?.id;
    dispatch(getPaymentMethod.pending());
  }, [dispatch]);

  // useEffect(() => {
  //   if (curUser.OrganisationLogo) {
  //     setTCMLOGO(curUser.OrganisationLogo.data);
  //   } else if (curUser.error?.organisationLogoError) {
  //     setLogoError(true);
  //     if (
  //       curUser.error?.organisationLogoError === "Missing Organisation Logo"
  //     ) {
  //       setLogoErrorMsg(t("translation:Missing Organisation Logo"));
  //     } else {
  //       setLogoErrorMsg(curUser.error?.organisationLogoError);
  //     }
  //   } else {
  //     setTCMLOGO(null);
  //   }
  // }, [curUser, t]);

  useEffect(() => {
    if (
      invoice.isLoadingPutInvoicePrintDate !== prevSetPrintDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintDateOnStatus.current = invoice.isLoadingPutInvoicePrintDate;

      if (
        invoice.putInvoicePrintDateSuccess === true &&
        invoice.isLoadingPutInvoicePrintDate === false
      ) {
        setAllowPrint(true);
      }
    }
  }, [invoice]);

  useEffect(() => {
    if (allowPrint) {
      setOrderProcessInfo(invoice.putInvoicePrintDate.invoice_header);
    }
  }, [invoice.putInvoicePrintDate, allowPrint, setOrderProcessInfo]);

  useEffect(() => {
    if (orderProcessInfo.invoice_details.length > 0) {
      const temAccAmount = orderProcessInfo.invoice_details.reduce(
        (sum, li) => {
          if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
            return parseFloat((sum + li.payment_amount).toFixed(2));
          }
          return parseFloat(sum.toFixed(2));
        },
        0
      );

      setPaidAmount(temAccAmount);
    }
  }, [orderProcessInfo]);

  useEffect(() => {
    var result = [];

    if (orderProcessInfo.store_header.visit_treatment_details.length > 0) {
      orderProcessInfo.store_header.visit_treatment_details.reduce(function (
        res,
        value
      ) {
        if (value.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          if (!res[value.treatment.treatment_type.id]) {
            res[value.treatment.treatment_type.id] = {
              name: value.treatment_type_name,
              amount: 0,
            };
            result.push(res[value.treatment.treatment_type.id]);
          }
          res[value.treatment.treatment_type.id].amount = (
            Number(res[value.treatment.treatment_type.id].amount) +
            Number(value.quantity * value.unit_selling_price)
          ).toFixed(2);
          return res;
        }
        return res;
      },
      {});
    }
    setTreatmentDetail(result);
  }, [orderProcessInfo]);

  useEffect(() => {
    var result = [];

    if (orderProcessInfo.store_header.visit_misc_details.length > 0) {
      const detailsWoDelete =
        orderProcessInfo.store_header.visit_misc_details.filter(
          (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
        );
      result = detailsWoDelete.map((value) => {
        if (value.status_type === generalConstants.APPROVED_STATUS_TYPE) {
          return {
            ...value,
            amount: Number(value.quantity * value.unit_selling_price).toFixed(
              2
            ),
          };
        }
        return null;
      }, {});
    }

    const resultWoEmpty = result.filter((resultItem) => resultItem !== null);

    setMiscDetail(resultWoEmpty.sort((a, b) => a.row_no - b.row_no));
  }, [orderProcessInfo]);

  return (
    <div>
      <S3OrganisationLogo
        organisationCode={orderProcessInfo.organisation.codename}
        folderPath="organisation"
        setPhotoURL={setTCMLOGO}
      />
      {/* <Collapse in={logoError && print ? true : false}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setLogoError(false);
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {logoErrorMsg}
        </Alert>
      </Collapse> */}
      <Typography display="block" variant="h4">
        {orderProcessInfo.order_number}
      </Typography>
      {treatmentDetail && miscDetail && memoInvoiceDetailsWoDeleted && (
        <EditInvoicePage
          orderProcessInfo={orderProcessInfo}
          temPaidAmount={paidAmount}
          temPrice={price}
          temDiscount={discount}
          temExtraAmt={extraAmt}
          temTreatmentDetail={treatmentDetail}
          temMiscDetail={miscDetail}
          temMemoInvoiceDetailsWoDeleted={memoInvoiceDetailsWoDeleted}
        />
      )}
      <Link
        onClick={() => {
          setPrintingLogo();
        }}
        className={classes.boldMaroon075}>
        <Button className={classes.boldMaroon075}>
          {t("translation:PRINT INVOICE")}
        </Button>

        {pdfFontLoaded &&
          TCMLogo &&
          common.paymentMethodList &&
          print !== 0 && (
            <InvoicePDF
              t={t}
              TCMLogo={TCMLogo}
              print={print}
              paidAmount={paidAmount}
              invoiveHeader={orderProcessInfo}
              storeHeader={orderProcessInfo.store_header}
              miscDetail={miscDetail}
              treatmentDetail={treatmentDetail}
              paymentOptions={common.paymentMethodList.payment_methods}
              setPrintingDate={setPrintingDate}
              allowPrint={allowPrint}
              setAllowPrint={setAllowPrint}
            />
          )}
      </Link>
      <Panel>
        <PaymentTable
          viewOnly={true}
          price={price}
          discount={discount}
          extraAmt={extraAmt}
          t={t}
          treatmentDetail={treatmentDetail}
          miscDetail={miscDetail}
        />
      </Panel>
      <Panel>
        <AmountTotal
          amountHeader={orderProcessInfo.store_header}
          payment={payment}
          setPayment={setPayment}
          paidAmount={paidAmount}
        />
      </Panel>
      {memoInvoiceDetailsWoDeleted.length > 0 && (
        <Panel>
          <InvoiceDetailsTable
            data={memoInvoiceDetailsWoDeleted}
            t={t}
            viewOnly={true}
          />
        </Panel>
      )}

      <PageOnlyBackAction setView={() => setOrderProcessView(false)} />
    </div>
  );
};

export default DetailPage;
