const externalApiConstants = {};

export function apiPostSearchPostalCode(postcodeSearch, pageNum = 1) {
  /*One map*/
  //searching postal code
  let apiURL = `/api/common/elastic/search?searchVal=${postcodeSearch}&returnGeom=Y&getAddrDetails=Y&pageNum=${pageNum}`;
  return apiURL;
}

export default externalApiConstants;
