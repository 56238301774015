import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/dispensaryConstants";

export const putStoreOrderStatus = {
  pending: (data) =>
    createAction(types.PUT_STOREORDERSTATUS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_STOREORDERSTATUS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_STOREORDERSTATUS_ASYNC.ERROR, { error }),
};

export const getDispensaryList = {
  pending: (data) =>
    createAction(types.GET_DISPENSARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DISPENSARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DISPENSARYLIST_ASYNC.ERROR, { error }),
};

export const getDispensarySummaryList = {
  pending: (data) =>
    createAction(types.GET_DISPENSARYSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DISPENSARYSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DISPENSARYSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const getDispensaryById = {
  pending: (data) =>
    createAction(types.GET_DISPENSARYBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DISPENSARYBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DISPENSARYBYID_ASYNC.ERROR, { error }),
};

export const putDispensaryWIPMode = {
  pending: (data) =>
    createAction(types.PUT_DISPENSARYWIPMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DISPENSARYWIPMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DISPENSARYWIPMODE_ASYNC.ERROR, { error }),
};

export const putDispensaryPendingStatus = {
  pending: (data) =>
    createAction(types.PUT_DISPENSARYPENDINGSTATUS_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DISPENSARYPENDINGSTATUS_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DISPENSARYPENDINGSTATUS_ASYNC.ERROR, { error }),
};

export const postSubmitDispensary = {
  pending: (data) =>
    createAction(types.POST_SUBMITDISPENSARY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITDISPENSARY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITDISPENSARY_ASYNC.ERROR, { error }),
};

export const putDispensaryPrintDate = {
  pending: (data) =>
    createAction(types.PUT_DISPENSARYPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DISPENSARYPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DISPENSARYPRINTDATE_ASYNC.ERROR, { error }),
};

export const putDispensaryVisitPrintDate = {
  pending: (data) =>
    createAction(types.PUT_DISPENSARYVISITPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DISPENSARYVISITPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DISPENSARYVISITPRINTDATE_ASYNC.ERROR, { error }),
};

export const putDispensaryStorePrintDate = {
  pending: (data) =>
    createAction(types.PUT_DISPENSARYSTOREPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DISPENSARYSTOREPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DISPENSARYSTOREPRINTDATE_ASYNC.ERROR, { error }),
};

export const getDispensaryInvoiceOrder = {
  pending: (data) =>
    createAction(types.GET_DISPENSARYINVOICEORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DISPENSARYINVOICEORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DISPENSARYINVOICEORDER_ASYNC.ERROR, { error }),
};

export const putDispensaryInvoicePrintDate = {
  pending: (data) =>
    createAction(types.PUT_DISPENSARYINVOICEPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DISPENSARYINVOICEPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DISPENSARYINVOICEPRINTDATE_ASYNC.ERROR, { error }),
};
