import { createAsyncTypes } from "../_helper/Utilities";

export const GET_DELIVERYORDERSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERSUMMARYLIST"
);

export const GET_DELIVERYORDERLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERLIST"
);

export const POST_SAVEDELIVERYORDER_ASYNC = createAsyncTypes(
  "POST_SAVETDELIVERYORDER"
);

export const POST_SUBMITDELIVERYORDER_ASYNC = createAsyncTypes(
  "POST_SUBMITDELIVERYORDER"
);

export const POST_SUBMITALLDELIVERY_ASYNC = createAsyncTypes(
  "POST_SUBMITALLDELIVERY"
);

export const PUT_DELIVERYORDERWIPMODE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYORDERWIPMODE"
);

export const PUT_DELIVERYORDEREDITMODE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYORDEREDITMODE"
);

export const PUT_DELIVERYORDERPENDINGMODE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYORDERPENDINGMODE"
);

export const GET_DELIVERYCOURIERLIST_ASYNC = createAsyncTypes(
  "GET_DELIVERYCOURIERLIST"
);

export const GET_DELIVERYCOURIERBYBRANCH_ASYNC = createAsyncTypes(
  "GET_DELIVERYCOURIERBYBRANCH"
);

export const GET_DELIVERYORDERBYSTOREHEADERID_ASYNC = createAsyncTypes(
  "GET_DELIVERYORDERBYSTOREHEADERID"
);

export const GET_STOREHEADERSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_STOREHEADERSUMMARYLIST"
);

export const GET_STOREHEADERLIST_ASYNC = createAsyncTypes(
  "GET_STOREHEADERLIST"
);

export const PUT_DELIVERYORDERPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DELIVERYORDERPRINTDATE"
);

export const PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC =
  createAsyncTypes("PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS");

export const POST_EXPORTDELIVERYORDERS_ASYNC = createAsyncTypes(
  "POST_EXPORTDELIVERYORDERS"
);

export const POST_EXPORTDELIVERYORDERSV2_ASYNC = createAsyncTypes(
  "POST_EXPORTDELIVERYORDERSV2"
);
