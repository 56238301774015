import { createAsyncTypes } from "../_helper/Utilities";
export const GET_REPORTINGINVOICEDETAILSLISTBYSTORE_ASYNC = createAsyncTypes(
  "GET_REPORTINGINVOICEDETAILSLISTBYSTORE"
);

export const GET_REPORTINGINVOICESUMMARYLISTBYSTORE_ASYNC = createAsyncTypes(
  "GET_REPORTINGINVOICESUMMARYLISTBYSTORE"
);

export const GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC =
  createAsyncTypes("GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE");

export const GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC =
  createAsyncTypes("GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYSTORE");

export const GET_REPORTINGINVOICEDETAILSLISTBYVISIT_ASYNC = createAsyncTypes(
  "GET_REPORTINGINVOICEDETAILSLISTBYVISIT"
);

export const GET_REPORTINGINVOICESUMMARYLISTBYVISIT_ASYNC = createAsyncTypes(
  "GET_REPORTINGINVOICESUMMARYLISTBYVISIT"
);

export const GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC =
  createAsyncTypes("GET_REPORTINGINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT");

export const GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC =
  createAsyncTypes("GET_REPORTINGINVOICEMEDICINECOSTINGLISTBYVISIT");

export const GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE_ASYNC =
  createAsyncTypes("GET_REPORTINGEXPORTINVOICEDETAILSLISTBYSTORE");

export const GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE_ASYNC =
  createAsyncTypes("GET_REPORTINGEXPORTINVOICESUMMARYLISTBYSTORE");

export const GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE_ASYNC =
  createAsyncTypes(
    "GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYSTORE"
  );

export const GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE_ASYNC =
  createAsyncTypes("GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYSTORE");

export const GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT_ASYNC =
  createAsyncTypes("GET_REPORTINGEXPORTINVOICEDETAILSLISTBYVISIT");

export const GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT_ASYNC =
  createAsyncTypes("GET_REPORTINGEXPORTINVOICESUMMARYLISTBYVISIT");

export const GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT_ASYNC =
  createAsyncTypes(
    "GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGDETAILSLISTBYVISIT"
  );

export const GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT_ASYNC =
  createAsyncTypes("GET_REPORTINGEXPORTINVOICEMEDICINECOSTINGLISTBYVISIT");

export const GET_REPORTINGEXPORTMEDICINEINOUT_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTMEDICINEINOUT"
);

export const GET_REPORTINGEXPORTMEDICINEINOUTDETAILS_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTMEDICINEINOUTDETAILS"
);

export const GET_REPORTINGEXPORTACUPOINTS_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTACUPOINTS"
);

export const POST_REPORTINGVERIFYCSVACUPOINTS_ASYNC = createAsyncTypes(
  "POST_REPORTINGVERIFYCSVACUPOINTS"
);

export const POST_REPORTINGIMPORTCSVACUPOINTS_ASYNC = createAsyncTypes(
  "POST_REPORTINGIMPORTCSVACUPOINTS"
);

export const GET_REPORTINGEXPORTTREATMENTS_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTTREATMENTS"
);

export const POST_REPORTINGVERIFYCSVTREATMENTS_ASYNC = createAsyncTypes(
  "POST_REPORTINGVERIFYCSVTREATMENTS"
);

export const POST_REPORTINGIMPORTCSVTREATMENTS_ASYNC = createAsyncTypes(
  "POST_REPORTINGIMPORTCSVTREATMENTS"
);

export const GET_REPORTINGEXPORTMEDICINES_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTMEDICINES"
);

export const POST_REPORTINGVERIFYCSVMEDICINES_ASYNC = createAsyncTypes(
  "POST_REPORTINGVERIFYCSVMEDICINES"
);

export const POST_REPORTINGIMPORTCSVMEDICINES_ASYNC = createAsyncTypes(
  "POST_REPORTINGIMPORTCSVMEDICINES"
);

export const GET_REPORTINGEXPORTFORMULAS_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTFORMULAS"
);

export const POST_REPORTINGVERIFYCSVFORMULAS_ASYNC = createAsyncTypes(
  "POST_REPORTINGVERIFYCSVFORMULAS"
);

export const POST_REPORTINGIMPORTCSVFORMULAS_ASYNC = createAsyncTypes(
  "POST_REPORTINGIMPORTCSVFORMULAS"
);

export const GET_REPORTINGEXPORTFORMULAMEDICINE_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTFORMULAMEDICINE"
);

export const POST_REPORTINGVERIFYCSVFORMULAMEDICINE_ASYNC = createAsyncTypes(
  "POST_REPORTINGVERIFYCSVFORMULAMEDICINE"
);

export const POST_REPORTINGIMPORTCSVFORMULAMEDICINE_ASYNC = createAsyncTypes(
  "POST_REPORTINGIMPORTCSVFORMULAMEDICINE"
);

export const GET_REPORTINGEXPORTSTOREMEDICINEHEADER_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTSTOREMEDICINEHEADER"
);

export const GET_REPORTINGEXPORTVISITNOTESDETAILS_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTVISITNOTESDETAILS"
);

export const GET_REPORTINGEXPORTSTOREMEDICINEDETAILS_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTSTOREMEDICINEDETAILS"
);

export const GET_REPORTINGEXPORTBRANCHMEDICINE_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTBRANCHMEDICINE"
);

export const POST_REPORTINGVERIFYCSVBRANCHMEDICINE_ASYNC = createAsyncTypes(
  "POST_REPORTINGVERIFYCSVBRANCHMEDICINE"
);

export const POST_REPORTINGIMPORTCSVBRANCHMEDICINE_ASYNC = createAsyncTypes(
  "POST_REPORTINGIMPORTCSVBRANCHMEDICINE"
);

export const GET_REPORTINGEXPORTFIXEDMEDICINEPRICE_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTFIXEDMEDICINEPRICE"
);

export const POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE_ASYNC = createAsyncTypes(
  "POST_REPORTINGVERIFYCSVFIXEDMEDICINEPRICE"
);

export const POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE_ASYNC = createAsyncTypes(
  "POST_REPORTINGIMPORTCSVFIXEDMEDICINEPRICE"
);

export const POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC_ASYNC =
  createAsyncTypes("POST_REPORTINGVERIFYFIXEDMEDICINEPRICESYNC");

export const POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC_ASYNC =
  createAsyncTypes("POST_REPORTINGIMPORTFIXEDMEDICINEPRICESYNC");

export const GET_MEDICINEFIXEDSELLINGPRICEHEADER_ASYNC = createAsyncTypes(
  "GET_MEDICINEFIXEDSELLINGPRICEHEADER"
);

export const GET_REPORTINGEXPORTUSERCREDITS_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTUSERCREDITS"
);

export const GET_REPORTINGEXPORTUSERCREDITSSTATEMENT_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTUSERCREDITSSTATEMENT"
);

export const GET_REPORTINGBILLABLEINVOICEBYSTORE_ASYNC = createAsyncTypes(
  "GET_REPORTINGBILLABLEINVOICEBYSTORE"
);

export const GET_REPORTINEXPORTGBILLABLEINVOICEBYSTORE_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTBILLABLEINVOICEBYSTORE"
);

export const GET_REPORTINGBILLABLEINVOICEBYVISIT_ASYNC = createAsyncTypes(
  "GET_REPORTINGBILLABLEINVOICEBYVISIT"
);

export const GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTBILLABLEINVOICEBYVISIT"
);

export const GET_REPORTINGBILLABLEIMCBYSTORE_ASYNC = createAsyncTypes(
  "GET_REPORTINGBILLABLEIMCBYSTORE"
);

export const GET_REPORTINEXPORTGBILLABLEIMCBYSTORE_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTBILLABLEIMCBYSTORE"
);

export const GET_REPORTINGBILLABLEIMCBYVISIT_ASYNC = createAsyncTypes(
  "GET_REPORTINGBILLABLEIMCBYVISIT"
);

export const GET_REPORTINEXPORTGBILLABLEIMCBYVISIT_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTBILLABLEIMCBYVISIT"
);

export const GET_REPORTINGPURCHASEORDERSUMMARY_ASYNC = createAsyncTypes(
  "GET_REPORTINGPURCHASEORDERSUMMARY"
);

export const GET_REPORTINGEXPORTPURCHASEORDERSUMMARY_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTPURCHASEORDERSUMMARY"
);

export const GET_REPORTINGPURCHASEORDERDETAILS_ASYNC = createAsyncTypes(
  "GET_REPORTINGPURCHASEORDERDETAILS"
);

export const GET_REPORTINGEXPORTPURCHASEORDERDETAILS_ASYNC = createAsyncTypes(
  "GET_REPORTINGEXPORTPURCHASEORDERDETAILS"
);
