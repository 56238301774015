import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import StyledCheckbox from "../../../../../components/StyledCheckbox";
import { getWaterAmount } from "../../../../../actions/commonActions";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../../components/globalStyles";

const EditBrewing = ({
  memoSelectedGroupIndex,
  memoSelectedGroup,
  memoGroupOption,
  setGroupOption,
  setPrice,
  showBrewing,
  handleMedicineGroupInfo,
  order,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  //const [groupBrewingPack, setGroupBrewingPack] = useState(0);
  const common = useSelector((state) => state.common);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const handleOnchangeKeyIn = (event, value) => {
    setSelectedGroup((p) => ({
      ...p,
      [event.target.name]: event.target.value ? event.target.value : null,
    }));
  };
  //useEffects
  useEffect(() => {
    setSelectedGroup(memoSelectedGroup);
  }, [memoSelectedGroup, setSelectedGroup]);

  const handleBrewingService = (event) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      is_brewing_services: event.target.checked,
      water_amount: event.target.checked === true ? 2 : null,
      brewing_instructions: null,
    };
    setGroupOption(newArray);
  };

  const handleWaterAmount = (event, option) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      water_amount: option ? option.id : null,
    };
    setGroupOption(newArray);
  };

  useEffect(() => {
    if (!common.waterAmountList && !common.isLoadingWaterAmount) {
      dispatch(getWaterAmount.pending());
    }
  }, [dispatch, common.waterAmountList, common.isLoadingWaterAmount]);

  const getWaterAmountOpObj = (option) => {
    if (!option.id)
      option = common.waterAmountList.water_amount_select_field.find(
        (op) => op.id === option
      );
    return option;
  };

  // useEffect(() => {
  //   const selectedGroupResults = memoSelectedGroup;

  //   let temBrewingPack = 0;

  //   if (
  //     selectedGroupResults.store_medicine_details &&
  //     Object.keys(selectedGroupResults.store_medicine_details).length !== 0
  //   ) {
  //     temBrewingPack =
  //       selectedGroupResults.number_of_times *
  //       selectedGroupResults.number_of_days;
  //   }

  //   setGroupBrewingPack(temBrewingPack);
  // }, [memoSelectedGroup]);

  // const brewingPackCount = () => {
  //   return groupOption.reduce(
  //     (sum, li) => sum + li.number_of_days * li.number_of_times,
  //     0
  //   );
  // };

  // const memoBrewingPackCount = React.useMemo(brewingPackCount, [groupOption]);

  return (
    <Grid container>
      {showBrewing === true && (
        <Grid item xs={12} className={classes.userInfoField}>
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={memoSelectedGroup.is_brewing_services ? true : false}
                onChange={handleBrewingService}
                name="is_brewing_services"
              />
            }
            label={t("translation:Brewing Services")}
          />
        </Grid>
      )}
      {showBrewing && memoSelectedGroup.is_brewing_services === true && (
        <>
          <Grid item xs={12} className={classes.userInfoField}>
            {common.waterAmountList && (
              <Autocomplete
                name="water_amount"
                options={common.waterAmountList.water_amount_select_field}
                disableClearable
                value={memoSelectedGroup.water_amount}
                getOptionLabel={(option) => getWaterAmountOpObj(option).name}
                isOptionEqualToValue={(option, value) => {
                  if (option.id === value) return option;
                }}
                style={{ width: "100%" }}
                onChange={(e, option) =>
                  handleWaterAmount(e, option == null ? null : option)
                }
                renderInput={(params) => (
                  <TextField
                    required
                    className={classes.userFieldColor}
                    {...params}
                    label={t("translation:Amount of Water (CC)")}
                    variant="outlined"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.userInfoField}>
            <TextField
              className={classes.userFieldColor}
              value={
                selectedGroup.brewing_instructions
                  ? selectedGroup.brewing_instructions
                  : ""
              }
              multiline
              rows={3}
              name="brewing_instructions"
              label={t("translation:Brewing Instructions")}
              variant="outlined"
              onChange={(e) => handleOnchangeKeyIn(e)}
              onBlur={(e) => handleMedicineGroupInfo(e)}
            />
          </Grid>
          {/* <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end">
            <Grid xs={3} item className={classes.subTotalInfoField}>
              <TextField
                className={classes.userFieldColor}
                value={groupBrewingPack}
                disabled
                name="brewingPacks"
                label={t("translation:Brewing Packs / Day")}
                variant="outlined"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                InputLabelProps={{
                  shrink: true,

                  style: {
                    fontWeight: "bold",
                    color: "rgba(149, 0, 0,0.75)",
                  },
                }}
              />
            </Grid>
          </Grid> */}
        </>
      )}
    </Grid>
  );
};
export default EditBrewing;
