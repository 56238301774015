import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  putInvoiceWIPMode,
  putInvoicePendingStatus,
  getInvoiceList,
  getInvoiceSummaryList,
  getInvoiceById,
  getInvoiceByOrderNum,
  postSubmitInvoice,
  postCreateInvoiceById,
  putInvoicePrintDate,
  postOverrideInvoiceAmount,
} from "../actions/invoiceActions";
import * as types from "../_constants/invoiceConstants";
import * as notification from "../_constants/notificationConstants";
import invoiceApis from "../_apis/invoiceApis";
export default function* invoiceSagas() {
  yield all([
    takeLatest(types.PUT_INVOICEWIPMODE_ASYNC.PENDING, putInvoiceWIPModeCall),
  ]);

  yield all([
    takeLatest(
      types.PUT_INVOICEPENDINGSTATUS_ASYNC.PENDING,
      putInvoicePendingStatusCall
    ),
  ]);
  yield all([
    takeLatest(types.GET_INVOICELIST_ASYNC.PENDING, getInvoiceListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_INVOICESUMMARYLIST_ASYNC.PENDING,
      getInvoiceSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_INVOICEBYID_ASYNC.PENDING, getInvoiceByIdCall),
  ]);
  yield all([
    takeLatest(
      types.GET_INVOICEBYORDERNUM_ASYNC.PENDING,
      getInvoiceByOrderNumCall
    ),
  ]);
  yield all([
    takeLatest(types.POST_SUBMITINVOICE_ASYNC.PENDING, postSubmitInvoiceCall),
  ]);

  yield all([
    takeLatest(
      types.POST_CREATEINVOICEBYID_ASYNC.PENDING,
      postCreateInvoiceByIdCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_INVOICEPRINTDATE_ASYNC.PENDING,
      putInvoicePrintDateCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_OVERRIDEINVOICEAMOUNT_ASYNC.PENDING,
      postOverrideInvoiceAmountCall
    ),
  ]);
}

function* putInvoiceWIPModeCall(Model) {
  try {
    const data = yield call(invoiceApis.putInvoiceWIPModeApi, Model.data);
    yield put(putInvoiceWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putInvoiceWIPMode.error(error.response?.data?.message));
    else yield put(putInvoiceWIPMode.error(notification.CONST_NOINTERNET));
  }
}

function* putInvoicePendingStatusCall(Model) {
  try {
    const data = yield call(invoiceApis.putInvoicePendingStatusApi, Model.data);
    yield put(putInvoicePendingStatus.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putInvoicePendingStatus.error(error.response?.data?.message));
    else
      yield put(putInvoicePendingStatus.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceListCall(Model) {
  try {
    const data = yield call(invoiceApis.getInvoiceListApi, Model.data);
    yield put(getInvoiceList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceList.error(error.response?.data?.message));
    else yield put(getInvoiceList.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceSummaryListCall(Model) {
  try {
    const data = yield call(invoiceApis.getInvoiceSummaryListApi, Model.data);
    yield put(getInvoiceSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceSummaryList.error(error.response?.data?.message));
    else yield put(getInvoiceSummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceByIdCall(Model) {
  try {
    const data = yield call(invoiceApis.getInvoiceByIdApi, Model.data);
    yield put(getInvoiceById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceById.error(error.response?.data?.message));
    else yield put(getInvoiceById.error(notification.CONST_NOINTERNET));
  }
}

function* getInvoiceByOrderNumCall(Model) {
  try {
    const data = yield call(invoiceApis.getInvoiceByOrderNumApi, Model.data);
    yield put(getInvoiceByOrderNum.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getInvoiceByOrderNum.error(error.response?.data?.message));
    else yield put(getInvoiceByOrderNum.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitInvoiceCall(Model) {
  try {
    const data = yield call(invoiceApis.postSubmitInvoiceApi, Model.data);
    yield put(postSubmitInvoice.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitInvoice.error(error.response?.data?.message));
    else yield put(postSubmitInvoice.error(notification.CONST_NOINTERNET));
  }
}

function* postCreateInvoiceByIdCall(Model) {
  try {
    const data = yield call(invoiceApis.postCreateInvoiceByIdApi, Model.data);
    yield put(postCreateInvoiceById.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postCreateInvoiceById.error(error.response?.data?.message));
    else yield put(postCreateInvoiceById.error(notification.CONST_NOINTERNET));
  }
}

function* putInvoicePrintDateCall(Model) {
  try {
    const data = yield call(invoiceApis.putInvoicePrintDateApi, Model.data);
    yield put(putInvoicePrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putInvoicePrintDate.error(error.response?.data?.message));
    else yield put(putInvoicePrintDate.error(notification.CONST_NOINTERNET));
  }
}

function* postOverrideInvoiceAmountCall(Model) {
  try {
    const data = yield call(
      invoiceApis.postOverrideInvoiceAmountApi,
      Model.data
    );
    yield put(postOverrideInvoiceAmount.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postOverrideInvoiceAmount.error(error.response?.data?.message));
    else
      yield put(postOverrideInvoiceAmount.error(notification.CONST_NOINTERNET));
  }
}
