import apiConstants from "../_constants/apiConstants";
import { headerWithauth } from "../_helper/apiHeader";
import axios from "axios";

const deliveryOrderApis = {
  getDeliveryOrderSummaryListApi,
  getDeliveryOrderListApi,
  postSaveDeliveryOrderApi,
  postSubmitDeliveryOrderApi,
  postSubmitAllDeliveryApi,
  putDeliveryOrderWIPModeApi,
  putDeliveryOrderEditModeApi,
  putDeliveryOrderPendingModeApi,
  getDeliveryCourierListApi,
  getDeliveryCourierByBranchApi,
  getDeliveryOrderByStoreHeaderIdApi,
  getStoreHeaderListApi,
  getStoreHeaderSummaryListApi,
  putDeliveryOrderPrintDateApi,
  putDeliveryOrderUpdateStoreDeliveryAddressApi,
  postExportDeliveryOrdersApi,
  postExportDeliveryOrdersV2Api,
};

async function getDeliveryOrderSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryOrderSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOrderListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetDeliveryOrderList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSaveDeliveryOrderApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSaveDeliveryOrder, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitDeliveryOrderApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostSubmitDeliveryOrder,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postSubmitAllDeliveryApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiPostSubmitAllDelivery, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderPendingModeApi(model) {
  const url = apiConstants.apiPutDeliveryOrderPendingMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderEditModeApi(model) {
  const url = apiConstants.apiPutDeliveryOrderEditMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderWIPModeApi(model) {
  const url = apiConstants.apiPutDeliveryOrderWIPMode + model;
  const requestOptions = JSON.stringify({});
  return await axios
    .put(url, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryCourierListApi() {
  return await axios
    .get(apiConstants.apiGetDeliveryCourierList, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryCourierByBranchApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetDeliveryCourierByBranch,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getDeliveryOrderByStoreHeaderIdApi(model) {
  const url = apiConstants.apiGetDeliveryOrderByStoreHeaderId + model;
  return await axios
    .get(url, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreHeaderListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(apiConstants.apiGetStoreHeaderList, requestOptions, headerWithauth)
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function getStoreHeaderSummaryListApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiGetStoreHeaderSummaryList,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderPrintDateApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutDeliveryOrderPrintDate,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function putDeliveryOrderUpdateStoreDeliveryAddressApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .put(
      apiConstants.apiPutDeliveryOrderUpdateStoreDeliveryAddress,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const allRepos = response.data;
      return allRepos;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postExportDeliveryOrdersApi(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostExportDeliveryOrders,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

async function postExportDeliveryOrdersV2Api(searchModel) {
  const requestOptions = JSON.stringify(searchModel);
  return await axios
    .post(
      apiConstants.apiPostExportDeliveryOrdersV2,
      requestOptions,
      headerWithauth
    )
    .then((response) => {
      const BOM = "\uFEFF";
      let fileName =
        response.headers["content-disposition"].split("filename=")[1];
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([BOM + response.data], { type: "text/csv;charset=utf-8;" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"].split("filename=")[1]
        );
        document.body.appendChild(link);
        link.click();
      }
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
export default deliveryOrderApis;
