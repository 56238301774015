//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
//action

import { getMedicineMeasureUnit } from "../../../actions/commonActions";

import {
  getDispensaryBranchesAlliance,
  getMedicineTypesAlliance,
  postFormulasAlliance,
  postMedicineFormulasAlliance,
  postBranchMedicines,
  postSubmitStoreOrder,
  postSaveStoreOrder,
  getCurrentOrganisation,
  getStorePostalCodeSurchargeList,
} from "../../../actions/workOrderActions";

//components
import StyledCheckbox from "../../../components/StyledCheckbox";
import Panel from "../../../components/Panel";
import { UserInfoTable } from "./UserInfoTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
import MedicineListTable from "./MedicineListTable";
import OrderDetailPage from "./OrderDetailPage";
import * as generalConstants from "../../../_constants/generalConstants";
import Note from "./components/addOrder/Note";
import PageAction from "../../../components/PageAction";
import AllianceTotalPrice from "./components/editOrder/AllianceTotalPrice";
import EditBrewing from "./components/editOrder/EditBrewing";
import EditDelivery from "./components/editOrder/EditDelivery";
import GroupOption from "./components/editOrder/GroupOption";
import GroupTotal from "./components/addOrder/GroupTotal";
import GroupMedicineInfo from "./components/addOrder/GroupMedicineInfo";
import RequiredNote from "../../../components/RequiredNote";
import submisionJSON from "./components/editOrder/submisionJSON";
import noEnterSubmitForm from "../../../components/functions/noEnterSubmitForm";
import { useStyles } from "../../../components/globalStyles";
import SharedDelivery from "./components/addOrder/SharedDelivery";
//style
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    "& > span": {
      width: "100%",
      backgroundColor: "rgba(240,240,240)",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    marginRight: theme.spacing(1),
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    color: "rgba(240,240,240)",
    backgroundColor: "rgba(155,29,32,1)",
    borderRadius: "5px!important",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "rgba(240,240,240)",
      opacity: 1,
    },
    "&$selected": {
      color: "rgba(240,240,240)",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgba(240,240,240)",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function setDefaultAddSaveSuccessAlarm() {
  return null;
}

const OrderEditPage = ({
  setOrderEditView,
  editOrderInfo,
  addSaveSuccessAlarm,
  setEditOrderInfo,
  setAddSaveSuccessAlarm = setDefaultAddSaveSuccessAlarm,
}) => {
  //variables
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [saveSuccessAlarm, setSaveSuccessAlarm] = useState(false);
  const [saveErrorAlarm, setSaveErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");
  const [orderDetailView, setOrderDetailView] = React.useState(false);
  const [orderDetailInfo, setOrderDetailInfo] = React.useState([]);
  const [orderSaveDetailView, setOrderSaveDetailView] = React.useState(false);
  const [orderSaveDetailInfo, setOrderSaveDetailInfo] = React.useState([]);
  const [costingHeader, setCostingHeader] = React.useState([]);
  const prevSubmitWorkOrderStatus = useRef();
  const prevSaveWorkOrderStatus = useRef();
  const common = useSelector((state) => state.common);
  const [showDelivery, setShowDelivery] = useState(true);

  const [showBrewing, setShowBrewing] = useState(true);
  const [isRawHerb, setIsRawHerb] = useState(false);
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workOrder = useSelector((state) => state.workOrder);
  const userAuth = useSelector((state) => state.userAuth);
  const curUser = useSelector((state) => state.curUser);
  const user = userAuth.systemUserInfo;
  const { handleSubmit } = useForm();
  const defaultMedicineGroup = editOrderInfo.store_medicine_headers.find(
    (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
  );
  const [price, setPrice] = useState({
    deliveryFee: 0,
    brewingFee: 0,
    medicineTab: 0,
    medicineFee: 0,
    vendorGstAmt: 0,
    medicineGroupTotal: 0,
  });

  const [desc, setDesc] = useState(editOrderInfo.desc);

  const [tabValue, setTabValues] = useState("0");
  const [group, setGroup] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.name : null
  );
  const [groupId, setGroupId] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.id : null
  );

  const [groupOption, setGroupOption] = useState(
    editOrderInfo.store_medicine_headers
  );

  const [medicineList, setMedicineList] = useState(
    defaultMedicineGroup ? defaultMedicineGroup.store_medicine_details : []
  );

  //function
  // function getMultiLevelProp(obj, key) {
  //   if (typeof obj == "object") {
  //     var keyQueue = key.split(".").reverse();

  //     var tempVal = obj,
  //       currentKey;
  //     while ((currentKey = keyQueue.pop()) !== undefined)
  //       tempVal = tempVal[currentKey];

  //     return tempVal;
  //   }

  //   return false;
  // }

  const handleCheckIncluded = (event) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.checked,
    };

    setGroupOption(newArray);
  };
  //set table data
  const setMedicineData = (rowIndex, columnID, value) => {
    const elementsIndex = groupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );

    //make a copy of group option
    let oldArray = [...groupOption];

    let storeMedicineDetail = oldArray[elementsIndex].store_medicine_details;

    if (columnID === "delete") {
      let result = [...storeMedicineDetail];
      //check whether is a current existing medicine

      const currentMedicineWoDelete = storeMedicineDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineVerified = currentMedicineWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMedicineVerified) {
        const currentMedicineIndex = result.findIndex(
          (e) => e.id === currentMedicineVerified.id
        );
        result[currentMedicineIndex] = {
          ...currentMedicineVerified,
          status_type: generalConstants.DELETED_STATUS_TYPE,
        };
      } else {
        const newAddMedicineVerified = currentMedicineWoDelete.find(
          (e, index) => index === rowIndex && e.id === 0
        );

        result = result.filter(
          (e) => e.medicine_name !== newAddMedicineVerified.medicine_name
        );
      }

      let resultWDelete = result.filter(
        (item) => item.status_type !== generalConstants.APPROVED_STATUS_TYPE
      );
      let resultWoDelete = result.filter(
        (item) => item.status_type === generalConstants.APPROVED_STATUS_TYPE
      );
      resultWDelete = resultWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      resultWoDelete = resultWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });
      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: [...resultWoDelete, ...resultWDelete],
      };
      setGroupOption(oldArray);
    } else if (columnID === "up") {
      //check whether is a current existing medicine

      let currentMedicineWoDelete = storeMedicineDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentMedicineWDelete = storeMedicineDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineVerified = currentMedicineWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMedicineVerified) {
        const currentMedicineIndex = currentMedicineWoDelete.findIndex(
          (e) => e.id === currentMedicineVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [
          currentMedicineWoDelete[currentMedicineIndex],
          currentMedicineWoDelete[currentMedicineIndex - 1],
        ] = [
          currentMedicineWoDelete[currentMedicineIndex - 1],
          currentMedicineWoDelete[currentMedicineIndex],
        ];
      } else {
        const newAddMedicineVerified = currentMedicineWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [
          currentMedicineWoDelete[newAddMedicineVerified],
          currentMedicineWoDelete[newAddMedicineVerified - 1],
        ] = [
          currentMedicineWoDelete[newAddMedicineVerified - 1],
          currentMedicineWoDelete[newAddMedicineVerified],
        ];
      }
      currentMedicineWDelete = currentMedicineWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentMedicineWoDelete = currentMedicineWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: [
          ...currentMedicineWoDelete,
          ...currentMedicineWDelete,
        ],
      };
      setGroupOption(oldArray);
    } else if (columnID === "down") {
      //check whether is a current existing medicine

      let currentMedicineWoDelete = storeMedicineDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      let currentMedicineWDelete = storeMedicineDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineVerified = currentMedicineWoDelete.find(
        (e, index) => index === rowIndex && e.id > 0
      );

      if (currentMedicineVerified) {
        const currentMedicineIndex = currentMedicineWoDelete.findIndex(
          (e) => e.id === currentMedicineVerified.id
        );
        //[a[m], a[n]] = [a[n], a[m]]
        [
          currentMedicineWoDelete[currentMedicineIndex],
          currentMedicineWoDelete[currentMedicineIndex + 1],
        ] = [
          currentMedicineWoDelete[currentMedicineIndex + 1],
          currentMedicineWoDelete[currentMedicineIndex],
        ];
      } else {
        const newAddMedicineVerified = currentMedicineWoDelete.findIndex(
          (e, index) => index === rowIndex && e.id === 0
        );

        [
          currentMedicineWoDelete[newAddMedicineVerified],
          currentMedicineWoDelete[newAddMedicineVerified + 1],
        ] = [
          currentMedicineWoDelete[newAddMedicineVerified + 1],
          currentMedicineWoDelete[newAddMedicineVerified],
        ];
      }

      currentMedicineWDelete = currentMedicineWDelete.map((x, index) => {
        return { ...x, row_no: null };
      });
      currentMedicineWoDelete = currentMedicineWoDelete.map((x, index) => {
        return { ...x, row_no: index + 1 };
      });

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: [
          ...currentMedicineWoDelete,
          ...currentMedicineWDelete,
        ],
      };
      setGroupOption(oldArray);
    } else {
      const currentMedicineWoDelete = storeMedicineDetail.filter(
        (e) => e.status_type !== generalConstants.DELETED_STATUS_TYPE
      );

      const currentMedicineWDelete = storeMedicineDetail.filter(
        (e) => e.status_type === generalConstants.DELETED_STATUS_TYPE
      );

      const selectedstoreMedicine = currentMedicineWoDelete.map(
        (medicine, index) => {
          if (index === rowIndex && columnID !== "delete") {
            return {
              ...medicine,
              [columnID]: value,
            };
          }
          return medicine;
        }
      );
      //need to merge back delete medicine in order to update
      const mergeSelectedWDeletedMedicine = [
        ...currentMedicineWDelete,
        ...selectedstoreMedicine,
      ];

      oldArray[elementsIndex] = {
        ...oldArray[elementsIndex],
        store_medicine_details: mergeSelectedWDeletedMedicine,
      };
      setGroupOption(oldArray);
    }
  };

  // const sortMedicineData = (sort) => {
  //   const elementsIndex = groupOption.findIndex(
  //     (item) => item.name === group && item.id === groupId
  //   );

  //   //make a copy of group option
  //   let oldArray = [...groupOption];

  //   let storeMedicineDetail = oldArray[elementsIndex].store_medicine_details;

  //   let sortingMedicineDetail = storeMedicineDetail.sort((a, b) => {
  //     const nameA = getMultiLevelProp(a, sort.id);
  //     const nameB = getMultiLevelProp(b, sort.id);
  //     if (sort.desc) return nameA.localeCompare(nameB);
  //     else return nameB.localeCompare(nameA);
  //   });
  //   oldArray[elementsIndex] = {
  //     ...oldArray[elementsIndex],
  //     store_medicine_details: sortingMedicineDetail,
  //   };

  //   console.log(oldArray);
  //   setGroupOption(oldArray);
  // };

  const handleMedicineGroupInfo = (event, value) => {
    const elementsIndex = memoSelectedGroupIndex;
    //make a copy of group option
    let newArray = [...memoGroupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value ? event.target.value : null,
    };

    setGroupOption(newArray);
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValues(newTabValue);
  };

  const handleMedicineGroupNumInfo = (event, value) => {
    const elementsIndex = groupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
    //make a copy of group option
    let newArray = [...groupOption];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [event.target.name]: event.target.value
        ? Number(event.target.value)
        : null,
    };

    setGroupOption(newArray);
  };

  const onSave = (event) => {
    event.currentTarget.form.reportValidity();
    var checkVal = event.currentTarget.form.checkValidity();

    if (checkVal) {
      window.scrollTo(0, 0);
      if (workOrder.currentOrganistion) {
        dispatch(
          postSaveStoreOrder.pending(
            submisionJSON(
              editOrderInfo,
              workOrder.currentOrganistion?.organisation,
              price,
              groupOption,
              costingHeader,
              desc
            )
          )
        );
      } else {
        setMsgAlarm(t("translation:Missing Organisation"));
        setSaveErrorAlarm(true);
      }
      // let verifySubmission = null;
      // let medicineName = null;

      // submission.store_header.store_medicine_headers.map((header) => {
      //   if (header.store_medicine_details) {
      //     header.store_medicine_details.map((medicine) => {
      //       console.log(medicine);
      //       if (
      //         medicine.branch_medicine.quantity === 0 ||
      //         medicine.branch_medicine.id === null
      //       ) {
      //         verifySubmission = header.name;
      //         medicineName = medicine.medicine_name;
      //         return "";
      //       }
      //       return "";
      //     });
      //   }
      //   return "";
      // });

      // if (verifySubmission) {
      //   setSaveSuccessAlarm(false);
      //   setSaveErrorAlarm(true);
      //   setMsgAlarm(
      //     t("translation:Missing branch medicine") +
      //       " - " +
      //       verifySubmission +
      //       " - " +
      //       medicineName
      //   );
      // } else {
      //   dispatch(postSaveStoreOrder.pending(submission));
      // }
    }
  };

  const onSubmit = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
    if (workOrder.currentOrganistion) {
      dispatch(
        postSubmitStoreOrder.pending(
          submisionJSON(
            editOrderInfo,
            workOrder.currentOrganistion?.organisation,
            price,
            groupOption,
            costingHeader,
            desc
          )
        )
      );
    } else {
      setMsgAlarm(t("translation:Missing Organisation"));
      setSubmitErrorAlarm(true);
    }
    // let verifySubmission = null;
    // let medicineName = null;

    // submission.store_header.store_medicine_headers.map((header) => {
    //   if (header.store_medicine_details) {
    //     header.store_medicine_details.map((medicine) => {
    //       if (
    //         medicine.branch_medicine.quantity === 0 ||
    //         medicine.branch_medicine.id === null
    //       ) {
    //         verifySubmission = header.name;
    //         medicineName = medicine.medicine_name;
    //         return "";
    //       }
    //       return "";
    //     });
    //   }
    //   return "";
    // });

    // if (verifySubmission) {
    //   setSaveSuccessAlarm(false);
    //   setSaveErrorAlarm(true);
    //   setMsgAlarm(
    //     t("translation:Missing branch medicine") +
    //       " - " +
    //       verifySubmission +
    //       " - " +
    //       medicineName
    //   );
    // } else {
    //   dispatch(postSubmitStoreOrder.pending(submission));
    // }
  };

  const onConfirmSubmit = () => {
    setConfirmSubmitOpen(true);
  };

  //useEffects
  useEffect(() => {
    prevSubmitWorkOrderStatus.current = false;
    prevSaveWorkOrderStatus.current = false;
  }, []);
  useEffect(() => {
    dispatch(getCurrentOrganisation.pending());
    dispatch(getMedicineMeasureUnit.pending());
    dispatch(
      getStorePostalCodeSurchargeList.pending({
        is_pagination: false,
        search_status_type_value: generalConstants.APPROVED_STATUS_TYPE,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (workOrder.isLoadingSaveStoreOrder !== prevSaveWorkOrderStatus.current) {
      //check the previous ref with current state
      prevSaveWorkOrderStatus.current = workOrder.isLoadingSaveStoreOrder;

      if (
        workOrder.postSaveStoreOrderIsSuccess === true &&
        workOrder.isLoadingSaveStoreOrder === false
      ) {
        setSaveSuccessAlarm(true);
        setSaveErrorAlarm(false);
        setOrderSaveDetailInfo(workOrder.saveStoreOrder.store_header);
        setOrderSaveDetailView(true);
        window.scrollTo(0, 0);
      }
      if (
        workOrder.postSaveStoreOrderIsSuccess === false &&
        workOrder.isLoadingSaveStoreOrder === false
      ) {
        setSaveSuccessAlarm(false);
        setSaveErrorAlarm(true);
        setMsgAlarm(workOrder.error.saveStoreOrderError);
        window.scrollTo(0, 0);
      }
    }
  }, [
    workOrder.postSaveStoreOrderIsSuccess,
    workOrder.isLoadingSaveStoreOrder,
    workOrder.error,
    workOrder.saveStoreOrder,
  ]);

  useEffect(() => {
    if (
      workOrder.isLoadingSubmitStoreOrder !== prevSubmitWorkOrderStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitWorkOrderStatus.current = workOrder.isLoadingSubmitStoreOrder;

      if (
        workOrder.postSubmitStoreOrderIsSuccess === true &&
        workOrder.isLoadingSubmitStoreOrder === false
      ) {
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        setOrderDetailInfo(workOrder.submitStoreOrder.store_header);
        setOrderDetailView(true);
        window.scrollTo(0, 0);
      }
      if (
        workOrder.postSubmitStoreOrderIsSuccess === false &&
        workOrder.isLoadingSubmitStoreOrder === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        setMsgAlarm(workOrder.error.submitStoreOrderError);
        window.scrollTo(0, 0);
      }
    }
  }, [
    workOrder.postSubmitStoreOrderIsSuccess,
    workOrder.isLoadingSubmitStoreOrder,
    workOrder.error,
    workOrder.submitStoreOrder,
  ]);

  const groupOptionAll = () => {
    return groupOption;
  };

  const memoGroupOption = React.useMemo(groupOptionAll, [groupOption]);

  const selectedGroup = () => {
    if (price) {
      return memoGroupOption.find(
        (item) => item.name === group && item.id === groupId
      );
    }
  };

  const memoSelectedGroup = React.useMemo(selectedGroup, [
    memoGroupOption,
    group,
    groupId,
    price,
  ]);

  const selectedGroupIndex = () => {
    return memoGroupOption.findIndex(
      (item) => item.name === group && item.id === groupId
    );
  };

  const memoSelectedGroupIndex = React.useMemo(selectedGroupIndex, [
    memoGroupOption,
    group,
    groupId,
  ]);

  if (!orderDetailView && !orderSaveDetailView) {
    return (
      <div>
        {/* <Collapse in={saveSuccessAlarm || addSaveSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSaveSuccessAlarm(false);
                  setAddSaveSuccessAlarm(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {t("translation:Order has been saved")}
          </Alert>
        </Collapse> */}

        <Collapse in={saveErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSaveErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmSubmitOpen}
          setOpen={setConfirmSubmitOpen}
          onConfirm={onSubmit}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <Typography display="block" variant="h4">
          {editOrderInfo.order_number}
        </Typography>
        <UserInfoTable
          name={editOrderInfo.staff.name}
          staff_contact_number={editOrderInfo.staff.contact_number}
          staff_address={editOrderInfo.staff.address}
          common={common}
          t={t}
        />
        <form
          onSubmit={handleSubmit(onConfirmSubmit)}
          onKeyDown={(e) => noEnterSubmitForm(e)}>
          <br />
          <Panel>
            <Grid container>
              <Grid xs={12} item className={classes.userInfoField}>
                <TextField
                  className={classes.userFieldColor}
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                  name="order description"
                  cy_data="storeOrderDescription"
                  label={t("translation:Order Description")}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Panel>

          <Grid className={classes.gridRoot} container>
            <TabContext value={tabValue}>
              <Grid item xs={12}>
                <StyledTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="fullWidth">
                  <StyledTab
                    className={classes.defualtCursor}
                    value="0"
                    label={
                      <span>
                        <Typography display="block" variant="subtitle1">
                          {t("translation:MEDICINE")}
                        </Typography>

                        <Typography display="block" variant="subtitle1">
                          $ {price.medicineTab}
                        </Typography>
                      </span>
                    }></StyledTab>

                  {/* <StyledTab
                  value="1"
                  label={
                    <span>
                      <Typography display="block" variant="subtitle1">
                        PAYMENT
                      </Typography>

                      <Typography display="block" variant="subtitle1">
                        $ 58
                      </Typography>
                    </span>
                  }
                /> */}
                </StyledTabs>
              </Grid>

              <TabPanel className={classes.gridRoot} value="0">
                <Panel>
                  <GroupOption
                    memoGroupOption={memoGroupOption}
                    groupOption={groupOption}
                    setGroupOption={setGroupOption}
                    group={group}
                    setGroup={setGroup}
                    memoSelectedGroupIndex={memoSelectedGroupIndex}
                    medicineList={medicineList}
                    setMedicineList={setMedicineList}
                    memoSelectedGroup={memoSelectedGroup}
                    setShowBrewing={setShowBrewing}
                    setShowDelivery={setShowDelivery}
                    setIsRawHerb={setIsRawHerb}
                    setGroupId={setGroupId}
                    groupId={groupId}
                    getDispensaryBranchesAlliance={
                      getDispensaryBranchesAlliance
                    }
                    getMedicineTypesAlliance={getMedicineTypesAlliance}
                    postFormulasAlliance={postFormulasAlliance}
                    postMedicineFormulasAlliance={postMedicineFormulasAlliance}
                    postBranchMedicines={postBranchMedicines}
                    order={workOrder}
                  />
                </Panel>
                {Object.keys(medicineList).length !== 0 &&
                  workOrder.currentOrganistion &&
                  common.medicineMeasureUnitList &&
                  workOrder.dispensaryBranchesAlliance && (
                    <div>
                      <Panel>
                        <Grid container>
                          <Grid item xs={12} className={classes.userInfoField}>
                            <MedicineListTable
                              t={t}
                              currentOrganistionBranch={
                                memoSelectedGroup.organisation_branch
                              }
                              data={medicineList.sort(
                                (a, b) => a.row_no - b.row_no
                              )}
                              number_of_days={memoSelectedGroup.number_of_days}
                              order={workOrder}
                              isRawHerb={isRawHerb}
                              setData={setMedicineData}
                              measureUnit={
                                common.medicineMeasureUnitList.measure_units
                              }
                              storeOrderMode={true}
                              userOrganisations={
                                curUser.organisationInfo.user_organisations
                              }
                              infoModule="STORE"
                            />
                          </Grid>

                          <GroupMedicineInfo
                            memoGroupOption={memoGroupOption}
                            handleMedicineGroupNumInfo={
                              handleMedicineGroupNumInfo
                            }
                            handleMedicineGroupInfo={handleMedicineGroupInfo}
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoSelectedGroup={memoSelectedGroup}
                            setGroupOption={setGroupOption}
                            module={"STORE"}
                          />
                        </Grid>
                        <GroupTotal
                          price={price}
                          memoSelectedGroup={memoSelectedGroup}
                          handleMedicineGroupNumInfo={
                            handleMedicineGroupNumInfo
                          }
                        />
                      </Panel>
                      {(showBrewing ||
                        showDelivery ||
                        memoSelectedGroup.delivery_incl_prescription === true ||
                        memoSelectedGroup.delivery_incl_receipt === true) && (
                        <Panel>
                          <Grid item xs={12} className={classes.userInfoField}>
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  checked={
                                    memoSelectedGroup.delivery_incl_prescription
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckIncluded}
                                  name="delivery_incl_prescription"
                                  cy_data="deliveryInclPrescription"
                                />
                              }
                              label={t(
                                "translation:Delivery Include Prescription"
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.userInfoField}>
                            <FormControlLabel
                              control={
                                <StyledCheckbox
                                  checked={
                                    memoSelectedGroup.delivery_incl_receipt
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckIncluded}
                                  name="delivery_incl_receipt"
                                  cy_data="deliveryInclInvoice"
                                />
                              }
                              label={t("translation:Delivery Include Invoice")}
                            />
                          </Grid>
                          <EditBrewing
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoSelectedGroup={memoSelectedGroup}
                            memoGroupOption={memoGroupOption}
                            groupOption={groupOption}
                            setGroupOption={setGroupOption}
                            setPrice={setPrice}
                            showBrewing={showBrewing}
                            handleMedicineGroupInfo={handleMedicineGroupInfo}
                            order={workOrder}
                          />
                          <EditDelivery
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoSelectedGroup={memoSelectedGroup}
                            memoGroupOption={memoGroupOption}
                            setGroupOption={setGroupOption}
                            editOrderInfo={editOrderInfo}
                            setPrice={setPrice}
                            user={user}
                            showDelivery={showDelivery}
                            price={price}
                            handleMedicineGroupInfo={handleMedicineGroupInfo}
                            order={workOrder}
                            module="STORE"
                            confirmSubmitOpen={confirmSubmitOpen}
                          />

                          <SharedDelivery
                            memoSelectedGroup={memoSelectedGroup}
                            memoSelectedGroupIndex={memoSelectedGroupIndex}
                            memoGroupOption={memoGroupOption}
                            setGroupOption={setGroupOption}
                            module="workOrder"
                          />
                        </Panel>
                      )}
                    </div>
                  )}
                {workOrder.currentOrganistion &&
                  workOrder.dispensaryBranchesAlliance &&
                  workOrder?.getStorePostalCodeSurchargeList && (
                    <AllianceTotalPrice
                      medicineList={medicineList}
                      memoGroupOption={memoGroupOption}
                      memoSelectedGroup={memoSelectedGroup}
                      group={group}
                      setGroupOption={setGroupOption}
                      price={price}
                      setPrice={setPrice}
                      memoSelectedGroupIndex={memoSelectedGroupIndex}
                      costingHeader={costingHeader}
                      setCostingHeader={setCostingHeader}
                      order={workOrder}
                      storeOrderMode={true}
                      userOrganisations={
                        curUser.organisationInfo.user_organisations
                      }
                    />
                  )}
              </TabPanel>

              <Note
                groupPrice={true}
                prescriptionTimes={true}
                noOfPacks={true}
              />
              <RequiredNote />
              <PageAction
                onSave={onSave}
                submissionTitle={t("translation:Submit to Dispensary Branch")}
                setView={() => setOrderEditView(false)}
              />
            </TabContext>
          </Grid>
        </form>
      </div>
    );
  } else if (orderDetailInfo && orderDetailView) {
    return (
      <div>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            onClick={() => {
              setSubmitSuccessAlarm(false);
            }}
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            cy_data="alertSubmitOrder">
            {t("translation:Order has been submitted")}
          </Alert>
        </Collapse>
        {workOrder.submitStoreOrder && (
          <OrderDetailPage
            orderDetailInfo={orderDetailInfo}
            setOrderDetailInfo={setOrderDetailInfo}
            setOrderDetailView={setOrderEditView}
          />
        )}
      </div>
    );
  } else if (orderSaveDetailInfo && orderSaveDetailView) {
    return (
      <div>
        <Collapse in={saveSuccessAlarm}>
          <Alert
            onClick={() => {
              setSaveSuccessAlarm(false);
            }}
            cy_data="alertSaveOrder"
            action={
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order has been saved")}
          </Alert>
        </Collapse>
        {workOrder.saveStoreOrder && (
          <OrderDetailPage
            orderDetailInfo={orderSaveDetailInfo}
            setOrderDetailInfo={setOrderSaveDetailInfo}
            setOrderDetailView={setOrderEditView}
          />
        )}
      </div>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
};

export default OrderEditPage;
