import React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../../../../components/globalStyles";

import Panel from "../../../../../components/Panel";

export default function BranchMedicineField({
  branchMedicines = null,
  setBranchMedicines = () => null,
  viewMode,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const common = useSelector((state) => state.common);
  const getStatusOpObj = (option) => {
    if (!option.id)
      option = common.generalStatusList.status_general.find(
        (op) => op.id === option
      );
    return option;
  };
  return (
    <Panel>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid item xs={12} className={classes.userInfoField}>
          <Autocomplete
            name="Order Status"
            disabled={viewMode}
            options={common.generalStatusList.status_general}
            disableClearable
            value={branchMedicines ? branchMedicines.status_type : null}
            isOptionEqualToValue={(option, value) => option.id === value}
            getOptionLabel={(option) => getStatusOpObj(option)?.name}
            style={{ width: "100%" }}
            onChange={(e, option) => {
              setBranchMedicines((p) => ({
                ...p,
                status_type: option.id,
              }));
            }}
            renderInput={(params) => (
              <TextField
                required
                className={classes.userFieldColor}
                label={t("translation:Status")}
                {...params}
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid xs={12} item className={classes.userInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={viewMode}
            value={branchMedicines ? branchMedicines.physical_location : ""}
            name="location"
            label={t("translation:Physical Location")}
            variant="outlined"
            onChange={(e) => {
              setBranchMedicines((p) => ({
                ...p,
                physical_location: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid xs={6} item className={classes.userInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={viewMode}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            value={
              branchMedicines && branchMedicines.quantity !== null
                ? branchMedicines.quantity
                : ""
            }
            name="Quantity"
            label={t("translation:Quantity")}
            variant="outlined"
            onChange={(e) => {
              setBranchMedicines((p) => ({
                ...p,
                quantity: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid xs={6} item className={classes.userInfoField}>
          <TextField
            className={classes.userFieldColor}
            disabled={viewMode}
            type="number"
            onWheel={(event) => event.target.blur()}
            InputProps={{
              inputProps: {
                min: 0,
                step: "any",
                style: { textAlign: "right" },
              },
            }}
            onKeyDown={(event) => {
              if (
                event?.key === "-" ||
                event?.key === "+" ||
                event?.key === "e" ||
                event?.key === "E"
              ) {
                event.preventDefault();
              }
            }}
            value={
              branchMedicines && branchMedicines.reserve_quantity !== null
                ? branchMedicines.reserve_quantity
                : ""
            }
            name="reserveQuantity"
            label={t("translation:Reserve Quantity")}
            variant="outlined"
            onChange={(e) => {
              setBranchMedicines((p) => ({
                ...p,
                reserve_quantity: e.target.value,
              }));
            }}
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
