//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button, Grid } from "@mui/material";

import Alert from "@mui/material/Alert";

//actions
import {
  getVisitStoreOrderSummaryListLinkedDelivery,
  getVisitStoreOrderByIdLinkedDelivery,
} from "../../../../../actions/visitOrderActions";

import {
  getStoreOrderSummaryListLinkedDelivery,
  getStoreOrderByIdLinkedDelivery,
} from "../../../../../actions/workOrderActions";

//components
import { SortingSearchTable } from "../../../../../components/SortingSearchTable";

import { ReactComponent as DetailLogo } from "../../../../../assets/coolicons/file/file_blank_outline.svg";

import OrderDetailPage from "../../../../visit/Consultation/OrderDetailPage";

import StoreOrderDetailPage from "../../OrderDetailPage";

import * as pageConfigure from "../../../../../_constants/pageConstants";

import StyledRadioCheckbox from "../../../../../components/StyledRadioCheckbox";

import * as generalConstants from "../../../../../_constants/generalConstants";
export default function StoreListLinkedDeliveryTable({
  storeHeader,
  setStoreHeader,
  module = "visitOrder",
}) {
  //variables

  const { t } = useTranslation();
  const columns = [
    {
      Header: "",
      accessor: "checkVisitOrder",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        return (
          <>
            <StyledRadioCheckbox
              checked={
                storeHeader !== null && storeHeader.id === row.original.id
                  ? true
                  : false
              }
              name="checkVisitOrder"
              onChange={(e) => handleVisitOrderCheck(e, row)}
            />
          </>
        );
      },
    },
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Visit Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Physician"),
      accessor: "physician_name",
      sortable: false,
    },
    {
      Header: t("translation:Patient"),
      accessor: "staff_name",
    },

    {
      Header: t("translation:Contact Number"),
      sortable: false,
      accessor: "staff_contact_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const viewColumns = [
    {
      Header: t("translation:Visit Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Physician"),
      accessor: "physician_name",
      sortable: false,
    },
    {
      Header: t("translation:Patient"),
      accessor: "staff_name",
    },

    {
      Header: t("translation:Contact Number"),
      sortable: false,
      accessor: "staff_contact_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    search_order_number_value: null,
    search_staff_name_value: null,
    search_physician_name_value: null,
    search_desc_value: null,
    search_staff_contact_number_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
    excl_visit_order_with_no_delivery_service: true,
    incl_dowo_status_type: [
      generalConstants.NEW_STATUS_TYPE,
      generalConstants.PENDING_STATUS_TYPE,
      generalConstants.APPROVED_STATUS_TYPE,
    ],
  });

  const [viewPageInfo, setViewPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const dispatch = useDispatch();

  const prevGetVisitStoreOrderByIdOnStatus = useRef();

  const visitOrder = useSelector((state) => state[module]);

  const common = useSelector((state) => state.common);

  const [orderDetailView, setOrderDetailView] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [editOrderInfo, setEditOrderInfo] = useState(null);

  //functions
  const handleVisitOrderCheck = (e, row) => {
    if (e.target.checked) {
      //setOrderDetailInfo(value);
      if (module === "visitOrder") {
        dispatch(getVisitStoreOrderByIdLinkedDelivery.pending(row.original.id));
      }
      if (module === "workOrder") {
        dispatch(getStoreOrderByIdLinkedDelivery.pending(row.original.id));
      }
    } else {
      setStoreHeader(null);
    }
  };

  const handleRowDetail = (value) => {
    //
    //setOrderDetailInfo(value);
    if (module === "visitOrder") {
      dispatch(getVisitStoreOrderByIdLinkedDelivery.pending(value.id));
    }
    if (module === "workOrder") {
      dispatch(getStoreOrderByIdLinkedDelivery.pending(value.id));
    }
    setOrderDetailView(true);
  };

  useEffect(() => {
    prevGetVisitStoreOrderByIdOnStatus.current = false;
  }, []);

  useEffect(() => {
    if (module === "visitOrder") {
      dispatch(getVisitStoreOrderSummaryListLinkedDelivery.pending(pageInfo));
    }
    if (module === "workOrder") {
      dispatch(getStoreOrderSummaryListLinkedDelivery.pending(pageInfo));
    }
  }, [dispatch, pageInfo, module]);

  useEffect(() => {
    if (
      visitOrder.isLoadingStoreOrderByIdLinkedDelivery !==
      prevGetVisitStoreOrderByIdOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetVisitStoreOrderByIdOnStatus.current =
        visitOrder.isLoadingStoreOrderByIdLinkedDelivery;

      if (
        visitOrder.getStoreOrderByIdLinkedDeliveryIsSuccess === true &&
        visitOrder.isLoadingStoreOrderByIdLinkedDelivery === false
      ) {
        setEditOrderInfo(
          visitOrder.storeOrderListByIdLinkedDelivery.store_header
        );
        if (!orderDetailView) {
          setStoreHeader(
            visitOrder.storeOrderListByIdLinkedDelivery.store_header
          );
        }
      }
    }
  }, [orderDetailView, visitOrder, dispatch, setEditOrderInfo, setStoreHeader]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: value,
        search_staff_name_value: value,
        search_physician_name_value: value,
        search_staff_contact_number_value: value,
        search_desc_value: value,
        excl_visit_order_with_no_delivery_service: true,
        incl_dowo_status_type: [
          generalConstants.NEW_STATUS_TYPE,
          generalConstants.PENDING_STATUS_TYPE,
          generalConstants.APPROVED_STATUS_TYPE,
        ],
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: null,
        search_staff_name_value: null,
        search_physician_name_value: null,
        search_staff_contact_number_value: null,
        search_desc_value: null,
        excl_visit_order_with_no_delivery_service: true,
        incl_dowo_status_type: [
          generalConstants.NEW_STATUS_TYPE,
          generalConstants.PENDING_STATUS_TYPE,
          generalConstants.APPROVED_STATUS_TYPE,
        ],
      });
    }
  };

  if (
    visitOrder.getStoreOrderSummaryListLinkedDeliveryIsSuccess === false &&
    visitOrder.isLoadingStoreOrderSummaryListLinkedDelivery === false
  ) {
    return (
      <Alert severity="error">
        {visitOrder.error.getStoreOrderSummaryListLinkedDeliveryError}
      </Alert>
    );
  } else if (
    visitOrder.getStoreOrderByIdLinkedDeliveryIsSuccess === false &&
    visitOrder.isLoadingStoreOrderByIdLinkedDelivery === false &&
    orderDetailView
  ) {
    return (
      <Alert severity="error">
        {visitOrder.error.getStoreOrderByIdLinkedDeliveryError}
      </Alert>
    );
  } else if (common.getGeneralStatusIsSuccess === true && !orderDetailView) {
    return (
      <>
        {storeHeader && (
          <Grid item xs={12}>
            <SortingSearchTable
              disabledSearch
              columns={viewColumns}
              data={[storeHeader]}
              pageInfo={viewPageInfo}
              setPageInfo={setViewPageInfo}
              totalItems={1}
              disabledFooter
            />
          </Grid>
        )}
        <SortingSearchTable
          searchPlaceholder={t(
            "translation:Visit Order No/ Physician/ Patient/ Patient Contact Number"
          )}
          statusPlaceholder={t("translation:Order Status")}
          searchValue={searchValue}
          statusOption={common.generalStatusList.status_general}
          columns={columns}
          data={
            visitOrder.storeOrderSummaryListLinkedDelivery
              ? visitOrder.storeOrderSummaryListLinkedDelivery.store_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            visitOrder.storeOrderSummaryListLinkedDelivery
              ? visitOrder.storeOrderSummaryListLinkedDelivery.total_pages
              : 0
          }
          loading={visitOrder.isLoadingVisitStoreOrderSummaryListLinkedDelivery}
          totalItems={
            visitOrder.storeOrderSummaryListLinkedDelivery
              ? visitOrder.storeOrderSummaryListLinkedDelivery.total_items
              : 0
          }
          handleSearch={handleSearch}
          searchDate={true}
          searchDatePlaceholder={t("translation:Creation Date")}
        />
      </>
    );
  } else if (
    orderDetailView &&
    editOrderInfo !== null &&
    module === "visitOrder"
  ) {
    return (
      <OrderDetailPage
        orderDetailInfo={editOrderInfo}
        setOrderDetailInfo={setEditOrderInfo}
        setOrderDetailView={() => {
          setOrderDetailView(false);
          setEditOrderInfo(null);
        }}
      />
    );
  } else if (
    orderDetailView &&
    editOrderInfo !== null &&
    module === "workOrder"
  ) {
    return (
      <StoreOrderDetailPage
        orderDetailInfo={editOrderInfo}
        setOrderDetailInfo={setEditOrderInfo}
        setOrderDetailView={() => {
          setOrderDetailView(false);
          setEditOrderInfo(null);
        }}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
