import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getDeliveryList,
  postSubmitDelivery,
  putDeliveryPrintDate,
} from "../actions/deliveryActions";
import * as types from "../_constants/deliveryConstants";
import * as notification from "../_constants/notificationConstants";
import deliveryApis from "../_apis/deliveryApis";
export default function* deliverySagas() {
  yield all([
    takeLatest(types.GET_DELIVERYLIST_ASYNC.PENDING, getDeliveryListCall),
  ]);
  yield all([
    takeLatest(types.POST_SUBMITDELIVERY_ASYNC.PENDING, postSubmitDeliveryCall),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYPRINTDATE_ASYNC.PENDING,
      putDeliveryPrintDateCall
    ),
  ]);
}

function* getDeliveryListCall(searchModel) {
  try {
    const data = yield call(deliveryApis.getDeliveryListApi, searchModel.data);
    yield put(getDeliveryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryList.error(error.response?.data?.message));
    else yield put(getDeliveryList.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitDeliveryCall(model) {
  try {
    const data = yield call(deliveryApis.postSubmitDeliveryApi, model.data);
    yield put(postSubmitDelivery.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitDelivery.error(error.response?.data?.message));
    else yield put(postSubmitDelivery.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryPrintDateCall(Model) {
  try {
    const data = yield call(deliveryApis.putDeliveryPrintDateApi, Model.data);
    yield put(putDeliveryPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDeliveryPrintDate.error(error.response?.data?.message));
    else yield put(putDeliveryPrintDate.error(notification.CONST_NOINTERNET));
  }
}
