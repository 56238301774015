import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function MfaAuthNote() {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        {t("translation:Note")} ：
      </Grid>
      <Grid item xs={12}>
        {t(
          "translation:Please scan the QR code or enter MFA name and MFA secret key manually on your authenticator app"
        )}
        .
      </Grid>
    </>
  );
}
