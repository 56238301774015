import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/deliveryConstants";

export const getDeliveryList = {
  pending: (data) =>
    createAction(types.GET_DELIVERYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DELIVERYLIST_ASYNC.SUCCESS, { data }),
  error: (error) => createAction(types.GET_DELIVERYLIST_ASYNC.ERROR, { error }),
};

export const postSubmitDelivery = {
  pending: (data) =>
    createAction(types.POST_SUBMITDELIVERY_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITDELIVERY_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITDELIVERY_ASYNC.ERROR, { error }),
};

export const putDeliveryPrintDate = {
  pending: (data) =>
    createAction(types.PUT_DELIVERYPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_DELIVERYPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_DELIVERYPRINTDATE_ASYNC.ERROR, { error }),
};
