import { createAsyncTypes } from "../_helper/Utilities";

export const PUT_STOREORDERSTATUS_ASYNC = createAsyncTypes(
  "PUT_STOREORDERSTATUS"
);

export const GET_DISPENSARYLIST_ASYNC = createAsyncTypes("GET_DISPENSARYLIST");

export const GET_DISPENSARYSUMMARYLIST_ASYNC = createAsyncTypes(
  "GET_DISPENSARYSUMMARYLIST"
);

export const GET_DISPENSARYBYID_ASYNC = createAsyncTypes("GET_DISPENSARYBYID");

export const PUT_DISPENSARYWIPMODE_ASYNC = createAsyncTypes(
  "PUT_DISPENSARYWIPMODE"
);

export const PUT_DISPENSARYPENDINGSTATUS_ASYNC = createAsyncTypes(
  "PUT_DISPENSARYPENDINGSTATUS"
);

export const POST_SUBMITDISPENSARY_ASYNC = createAsyncTypes(
  "POST_SUBMITDISPENSARY"
);

export const PUT_DISPENSARYPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DISPENSARYPRINTDATE"
);

export const PUT_DISPENSARYVISITPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DISPENSARYVISITPRINTDATE"
);

export const PUT_DISPENSARYSTOREPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DISPENSARYSTOREPRINTDATE"
);

export const GET_DISPENSARYINVOICEORDER_ASYNC = createAsyncTypes(
  "GET_DISPENSARYINVOICEORDER"
);

export const PUT_DISPENSARYINVOICEPRINTDATE_ASYNC = createAsyncTypes(
  "PUT_DISPENSARYINVOICEPRINTDATE"
);
