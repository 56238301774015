//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
//actions
import {
  getStoreOrderSummaryList,
  getStoreOrderById,
  putStoreEditMode,
  getCurrentOrganisation,
} from "../../../actions/workOrderActions";

//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";
import { ReactComponent as NewLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import OrderDetailPage from "./OrderDetailPage";
import OrderEditPage from "./OrderEditPage";
import * as pageConfigure from "../../../_constants/pageConstants";
import ConfirmDialog from "../../../components/ConfirmDialog";
import * as generalConstants from "../../../_constants/generalConstants";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

export default function StoreListTable({
  setStoreInformationView = () => null,
  setRefreshTimeStamp = () => null,
  refreshTimeStamp = null,
  dashboardView = false,
}) {
  //variables

  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const updateOrderAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type === generalConstants.WORK_ORDER_STORE_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:Store Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Staff Name"),
      accessor: "staff_name",
    },

    {
      Header: t("translation:Order Description"),
      accessor: "desc",
    },

    {
      Header: t("translation:Contact Number"),
      sortable: false,
      accessor: "staff_contact_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsWUpdateAccess = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "Edit",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.NEW_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowEdit(row.original)}>
              <EditLogo title={t("translation:Edit")} />
            </Button>
          </>
        ) : row.original.status_type ===
          generalConstants.PENDING_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowEditProcess(row.original)}>
              <NewLogo title={t("translation:New")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    {
      Header: t("translation:Store Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Staff Name"),
      accessor: "staff_name",
    },

    {
      Header: t("translation:Order Description"),
      accessor: "desc",
    },

    {
      Header: t("translation:Contact Number"),
      sortable: false,
      accessor: "staff_contact_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },
    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );

        return cellValue ? cellValue.name : "";
      },
    },
  ];
  const localStorageNameKey = "storeListTable";
  const [pageInfo, setPageInfo] = useState({
    search_order_number_value: null,
    search_staff_name_value: null,
    search_staff_contact_number_value: null,
    search_desc_value: null,
    search_status_type_value: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,
  });

  const dispatch = useDispatch();

  const prevGetStoreOrderByIdOnStatus = useRef();

  const workOrder = useSelector((state) => state.workOrder);

  const common = useSelector((state) => state.common);

  //
  const [orderEditView, setOrderEditView] = useState(false);
  const [newStatusErrorAlarm, setNewStatusErrorAlarm] = useState(false);
  const [newStatusSuccessAlarm, setNewStatusSuccessAlarm] = useState(false);
  const [orderDetailView, setOrderDetailView] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [orderDetailInfo, setOrderDetailInfo] = useState(null);
  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  //const [editOrderInfo, setEditOrderInfo] = useState(null);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const prevWorkOrderStatus = useRef();
  const prevRefreshTimeStamp = useRef();
  //functions
  const handleRowEditProcess = (value) => {
    //
    setConfirmEditOpen(true);
    setSelectedValue(value.id);
  };

  const handleConfirmEditProcess = () => {
    dispatch(putStoreEditMode.pending(selectedValue));
  };
  const handleRowEdit = (value) => {
    //
    dispatch(getStoreOrderById.pending(value.id));
    //setEditOrderInfo(value);
    setOrderEditView(true);
    setNewStatusSuccessAlarm(false);
  };

  const handleRowDetail = (value) => {
    //
    dispatch(getStoreOrderById.pending(value.id));
    //setOrderDetailInfo(value);
    setOrderDetailView(true);
    setNewStatusSuccessAlarm(false);
  };

  //useEffects
  useEffect(() => {
    prevWorkOrderStatus.current = false;
    prevRefreshTimeStamp.current = null;
    prevGetStoreOrderByIdOnStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      refreshTimeStamp !== prevRefreshTimeStamp.current &&
      dashboardView &&
      !orderEditView &&
      !orderDetailView
    ) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;
      dispatch(getStoreOrderSummaryList.pending(pageInfo));
    }
  }, [
    refreshTimeStamp,
    dispatch,
    pageInfo,
    dashboardView,
    orderEditView,
    orderDetailView,
  ]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (
      workOrder.isLoadingStoreOrderById !==
      prevGetStoreOrderByIdOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetStoreOrderByIdOnStatus.current = workOrder.isLoadingStoreOrderById;

      if (
        workOrder.getStoreOrderByIdSuccess === true &&
        workOrder.isLoadingStoreOrderById === false
      ) {
        setOrderDetailInfo(workOrder.storeOrderById.store_header);
      }
    }
  }, [workOrder, dispatch, setOrderDetailInfo]);

  useEffect(() => {
    if (workOrder.isLoadingStockEditMode !== prevWorkOrderStatus.current) {
      //check the previous ref with current state
      prevWorkOrderStatus.current = workOrder.isLoadingStockEditMode;

      if (
        workOrder.putStockEditModeIsSuccess === true &&
        workOrder.isLoadingStockEditMode === false
      ) {
        dispatch(getStoreOrderSummaryList.pending(pageInfo));
        setNewStatusErrorAlarm(false);
        setNewStatusSuccessAlarm(true);
        window.scrollTo(0, 0);
      }

      if (
        workOrder.putStockEditModeIsSuccess === false &&
        workOrder.isLoadingStockEditMode === false
      ) {
        setMsgAlarm(workOrder.error.putStockEditModeError);
        window.scrollTo(0, 0);
        setNewStatusErrorAlarm(true);
        setNewStatusSuccessAlarm(false);
      }
    }
  }, [dispatch, workOrder, pageInfo]);

  useEffect(() => {
    if (orderDetailView === true || orderEditView === true) {
      setStoreInformationView(true);
    } else {
      setStoreInformationView(false);
    }
  }, [orderEditView, orderDetailView, setStoreInformationView]);

  useEffect(() => {
    if (!orderEditView && !orderDetailView && !dashboardView) {
      dispatch(getStoreOrderSummaryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, orderEditView, orderDetailView, dashboardView]);

  useEffect(() => {
    //use refresh current organisation
    dispatch(getCurrentOrganisation.pending());
  }, [dispatch]);

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: value,
        search_staff_name_value: value,
        search_staff_contact_number_value: value,
        search_desc_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_order_number_value: null,
        search_staff_name_value: null,
        search_staff_contact_number_value: null,
        search_desc_value: null,
      });
    }
  };

  if (
    workOrder.getStoreOrderSummaryListIsSuccess === false &&
    workOrder.isLoadingStoreOrderSummaryList === false
  ) {
    return (
      <Alert severity="error">
        {workOrder.error.storeOrderSummaryListError}
      </Alert>
    );
  } else if (
    workOrder.getStoreOrderByIdSuccess === false &&
    workOrder.isLoadingStoreOrderById === false &&
    (orderDetailView || orderDetailView)
  ) {
    return (
      <Alert severity="error">{workOrder.error.storeOrderByIdError}</Alert>
    );
  } else if (
    common.getGeneralStatusIsSuccess === true &&
    !orderEditView &&
    !orderDetailView
  ) {
    return (
      <>
        <Collapse in={newStatusSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setNewStatusSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.NEW_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={newStatusErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setNewStatusErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmEditOpen}
          setOpen={setConfirmEditOpen}
          onConfirm={handleConfirmEditProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <SortingSearchTable
          searchPlaceholder={t(
            "translation:Store Order No/ Staff Name/ Staff Contact Number/ Order Description"
          )}
          statusPlaceholder={t("translation:Order Status")}
          searchValue={searchValue}
          statusOption={common.generalStatusList.status_general}
          columns={updateOrderAccess ? columnsWUpdateAccess : columns}
          data={
            workOrder.storeOrderSummaryList
              ? workOrder.storeOrderSummaryList.store_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            workOrder.storeOrderSummaryList
              ? workOrder.storeOrderSummaryList.total_pages
              : 0
          }
          loading={workOrder.isLoadingStoreOrderSummaryList}
          totalItems={
            workOrder.storeOrderSummaryList
              ? workOrder.storeOrderSummaryList.total_items
              : 0
          }
          handleSearch={handleSearch}
          searchDate={true}
          searchDatePlaceholder={t("translation:Creation Date")}
          localStorageName={localStorageNameKey}
        />
      </>
    );
  } else if (orderDetailView && orderDetailInfo !== null) {
    return (
      <OrderDetailPage
        orderDetailInfo={orderDetailInfo}
        setOrderDetailInfo={setOrderDetailInfo}
        setOrderDetailView={() => {
          setOrderDetailView(false);
          setOrderDetailInfo(null);
          setRefreshTimeStamp(moment());
        }}
      />
    );
  } else if (orderEditView && orderDetailInfo !== null) {
    return (
      <OrderEditPage
        editOrderInfo={orderDetailInfo}
        setOrderEditView={() => {
          setOrderEditView(false);
          setOrderDetailInfo(null);
          setRefreshTimeStamp(moment());
        }}
        setEditOrderInfo={setOrderDetailInfo}
      />
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
