//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField } from "@mui/material";
//Actions

import {
  getPurchaseOrderList,
  getPurchaseOrderById,
  putPurchaseOrderWIPMode,
  putPurchaseOrderCancelled,
  postSubmitPurchaseOrder,
} from "../../../actions/purchaseFormActions";

//Component
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as CancelLogo } from "../../../assets/coolicons/media/skip_previous.svg";
import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";
import * as pageConfigure from "../../../_constants/pageConstants";
import * as generalConstants from "../../../_constants/generalConstants";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ProcessPurchaseOrderPage from "./ProcessPurchaseOrderPage";
import { useStyles } from "../../../components/globalStyles";
import onlyFixedNumerics from "../../../components/functions/onlyFixedNumerics";
import organisationPriceRounding from "../../../components/functions/organisationPriceRounding";

export default function PurchaseOrderListTable({
  setInformationView,
  viewInfo,
  setViewInfo,
  editView,
  setEditView,
  detailView,
  setDetailView,
}) {
  //variables
  const { t } = useTranslation();
  const classes = useStyles();
  const curUser = useSelector((state) => state.curUser);
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_PURCHASE_ORDER_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;

  const defaultOrganisation =
    curUser.organisationInfo?.user_organisations?.find(
      (item) => item.is_default === true
    );
  //   defaultOrganisation.organisations?.is_po_multiplier_required
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "EditOrWIP",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        if (row.original.status_type === generalConstants.PENDING_STATUS_TYPE) {
          return (
            <>
              <Button onClick={() => handleRowWIP(row.original)}>
                <ProceedLogo title={t("translation:WIP")} />
              </Button>
            </>
          );
        } else if (
          row.original.status_type === generalConstants.WIP_STATUS_TYPE
        ) {
          return (
            <>
              <Button onClick={() => handleRowEdit(row.original)}>
                <EditLogo title={t("translation:Edit")} />
              </Button>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: "",
      accessor: "New",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        if (
          row.original.status_type > generalConstants.NEW_STATUS_TYPE &&
          row.original.status_type <= generalConstants.WIP_STATUS_TYPE
        ) {
          return (
            <>
              <Button onClick={() => handleRowCancel(row.original)}>
                <CancelLogo title={t("translation:Cancel")} />
              </Button>
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      Header: "",
      accessor: "processAllAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE ? (
          <>
            <Button onClick={() => handleRowProcessAll(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },

    {
      Header: t("translation:PO Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Supplier"),
      accessor: "supplier_name",

      sortable: false,
    },
    {
      Header: t("translation:Creation Date"),
      sortable: false,
      accessor: (d) => {
        if (d.created_on)
          return moment.utc(d.created_on).local().format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsViewOnly = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },

    {
      Header: t("translation:PO Order No"),
      accessor: "order_number",
    },

    {
      Header: t("translation:Supplier"),
      accessor: "supplier_name",

      sortable: false,
    },
    {
      Header: t("translation:Creation Date"),
      sortable: false,
      accessor: (d) => {
        if (d.created_on)
          return moment.utc(d.created_on).local().format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  //Initialization of pageinfo
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_end_date_value: null,
    search_end_timestamp_value: null,
    search_purchase_order_number_value: null,
    search_start_date_value: null,
    search_start_timestamp_value: null,
    search_status_type_value: null,
  });

  const dispatch = useDispatch();

  const purchaseOrder = useSelector((state) => state.purchaseOrder);
  const common = useSelector((state) => state.common);
  //Init

  const [confirmWIPOpen, setConfirmWIPOpen] = useState(false);
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [costPriceMutiplier, setCostPriceMutiplier] = React.useState(null);
  const [WIPSuccessAlarm, setWIPSuccessAlarm] = useState(false);
  const [WIPErrorAlarm, setWIPErrorAlarm] = useState(false);
  const [WIPMsgAlarm, setWIPMsgAlarm] = React.useState(null);
  const [cancelSuccessAlarm, setCancelSuccessAlarm] = useState(false);
  const [cancelErrorAlarm, setCancelErrorAlarm] = useState(false);
  const [cancelMsgAlarm, setCancelMsgAlarm] = React.useState(null);
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [confirmProceedAllOpen, setConfirmProceedAllOpen] = useState(false);
  //const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [submitlMsgAlarm, setSubmitMsgAlarm] = React.useState(null);
  const [errorAlarm, setErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = useState("");

  const prevGetPurchaseOrderByIdStatus = useRef();
  const prevPutWIPStatus = useRef();
  const prevPutCancelStatus = useRef();
  const prevPostSubmitStatus = useRef();
  //Onclick event
  const handleRowEdit = (value) => {
    dispatch(getPurchaseOrderById.pending(value.id));
    setEditView(true);
    setViewInfo(null);

    setWIPSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setCancelSuccessAlarm(false);
    setCancelErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setErrorAlarm(false);
  };

  const handleRowWIP = (value) => {
    setConfirmWIPOpen(true);
    setSelectedValue(value);
    setViewInfo(null);
  };

  const handleConfirmWIP = () => {
    const jsonText = {
      purchase_order_header: { id: selectedValue.id },
    };

    dispatch(putPurchaseOrderWIPMode.pending(jsonText));
  };

  const handleRowProcessAll = (value) => {
    setConfirmProceedAllOpen(true);
    setCostPriceMutiplier(null);
    setSelectedValue(value);
  };

  const handleRowCancel = (value) => {
    setConfirmCancelOpen(true);
    setSelectedValue(value);
    setViewInfo(null);
  };

  const handleConfirmCancelMode = () => {
    const jsonText = {
      purchase_order_header: { id: selectedValue.id },
    };

    dispatch(putPurchaseOrderCancelled.pending(jsonText));
  };

  const handleRowDetail = (value) => {
    dispatch(getPurchaseOrderById.pending(value.id));
    setDetailView(true);
    setViewInfo(null);

    setWIPSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setCancelSuccessAlarm(false);
    setCancelErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setErrorAlarm(false);
  };

  const onSubmit = (e) => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    let purchaseOrderDetails = [...selectedValue.purchase_order_details];

    purchaseOrderDetails.map((item, index) => {
      const totalQuantityReceived = item.qty_received_details.reduce(
        (sum, li) => {
          if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
            return onlyFixedNumerics(sum - li.qty_received);
          }
          return onlyFixedNumerics(sum);
        },
        item.quantity
      );

      const reveicedQuantityPrice = onlyFixedNumerics(
        item.price * Number(totalQuantityReceived)
      );

      const newUnitCostPrice = onlyFixedNumerics(
        onlyFixedNumerics(
          onlyFixedNumerics(
            item.branch_medicine.medicines.unit_cost_price *
              item.branch_medicine.quantity
          ) + reveicedQuantityPrice
        ) /
          //if no old_unit_price
          (item.branch_medicine.medicines.unit_cost_price
            ? onlyFixedNumerics(
                item.branch_medicine.quantity +
                  onlyFixedNumerics(
                    Number(totalQuantityReceived) * item.package_content
                  )
              )
            : onlyFixedNumerics(
                Number(totalQuantityReceived) * item.package_content
              ))
      );

      const oldUnitSellingPrice =
        item.branch_medicine.medicines.unit_selling_price;

      const newUnitSellingPrice = defaultOrganisation.organisations
        ?.is_po_multiplier_required
        ? onlyFixedNumerics(
            Number(newUnitCostPrice) * Number(costPriceMutiplier)
          )
        : oldUnitSellingPrice;

      const newReceivedDetail = {
        id: 0,
        medicine_in_out: {
          new_unit_cost_price: Number(
            organisationPriceRounding(
              newUnitCostPrice,
              generalConstants.ROUND_OFF_RMT,
              generalConstants.TEN_THOUSANDTH_RPVT
            )
          ),
          new_unit_selling_price: Number(
            organisationPriceRounding(
              newUnitSellingPrice,
              generalConstants.ROUND_OFF_RMT,
              generalConstants.TEN_THOUSANDTH_RPVT
            )
          ),
        },
        qty_received: Number(totalQuantityReceived),
      };

      purchaseOrderDetails[index].qty_received_details.push(newReceivedDetail);
    });

    const jsonText = {
      purchase_order_header: {
        ...selectedValue,
        purchase_order_details: purchaseOrderDetails,
      },
    };
    dispatch(postSubmitPurchaseOrder.pending(jsonText));
  };

  //For Search
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        page_size: pageConfigure.DEFAULT_PAGESIZE,
        search_purchase_order_number_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_purchase_order_number_value: null,
      });
    }
  };

  //useEffects

  useEffect(() => {
    //set ref when first render
    prevGetPurchaseOrderByIdStatus.current = false;
    prevPutWIPStatus.current = false;
    prevPutCancelStatus.current = false;
    prevPostSubmitStatus.current = false;
  }, []);

  // useEffect(() => {
  //   if (detailView === true || editView === true) {
  //     setInformationView(true);
  //   } else {
  //     setInformationView(false);
  //   }
  // }, [detailView, editView, setInformationView]);

  useEffect(() => {
    if (!detailView && !editView) {
      setWIPSuccessAlarm(false);
      setWIPErrorAlarm(false);
      setCancelSuccessAlarm(false);
      setCancelErrorAlarm(false);
      setSubmitSuccessAlarm(false);
      setErrorAlarm(false);
      setInformationView(false);
    } else {
      setInformationView(true);
    }
  }, [detailView, editView]);

  useEffect(() => {
    if (!editView && !detailView) {
      dispatch(getPurchaseOrderList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, editView, detailView]);

  useEffect(() => {
    if (
      purchaseOrder.isLoadingGetPurchaseOrderById !==
      prevGetPurchaseOrderByIdStatus.current
    ) {
      //check the previous ref with current state
      prevGetPurchaseOrderByIdStatus.current =
        purchaseOrder.isLoadingGetPurchaseOrderById;

      if (
        purchaseOrder.getPurchaseOrderByIdIsSuccess === true &&
        purchaseOrder.isLoadingGetPurchaseOrderById === false
      ) {
        setViewInfo(purchaseOrder.purchaseOrderById);
      }
      if (
        purchaseOrder.getPurchaseOrderByIdIsSuccess === false &&
        purchaseOrder.isLoadingGetPurchaseOrderById === false
      ) {
        setEditView(false);
        setDetailView(false);
        setViewInfo(null);
      }
    }
  }, [purchaseOrder, setEditView, setDetailView, setViewInfo]);

  useEffect(() => {
    if (
      purchaseOrder.isLoadingPutPurchaseOrderWIPMode !==
      prevPutWIPStatus.current
    ) {
      //check the previous ref with current state
      prevPutWIPStatus.current = purchaseOrder.isLoadingPutPurchaseOrderWIPMode;

      if (
        purchaseOrder.putPurchaseOrderWIPModeIsSuccess === true &&
        purchaseOrder.isLoadingPutPurchaseOrderWIPMode === false
      ) {
        setWIPSuccessAlarm(true);
        dispatch(getPurchaseOrderList.pending(pageInfo));
        window.scrollTo(0, 0);
      }
      if (
        purchaseOrder.putPurchaseOrderWIPModeIsSuccess === false &&
        purchaseOrder.isLoadingPutPurchaseOrderWIPMode === false
      ) {
        setWIPMsgAlarm(purchaseOrder.error.putPurchaseOrderWIPModeError);
        setWIPErrorAlarm(true);
        window.scrollTo(0, 0);
      }
    }
  }, [purchaseOrder, dispatch, pageInfo]);

  useEffect(() => {
    if (
      purchaseOrder.isLoadingPutPurchaseOrderCancelled !==
      prevPutCancelStatus.current
    ) {
      //check the previous ref with current state
      prevPutCancelStatus.current =
        purchaseOrder.isLoadingPutPurchaseOrderCancelled;

      if (
        purchaseOrder.putPurchaseOrderCancelledIsSuccess === true &&
        purchaseOrder.isLoadingPutPurchaseOrderCancelled === false
      ) {
        setCancelSuccessAlarm(true);
        dispatch(getPurchaseOrderList.pending(pageInfo));
        window.scrollTo(0, 0);
      }
      if (
        purchaseOrder.putPurchaseOrderCancelledIsSuccess === false &&
        purchaseOrder.isLoadingPutPurchaseOrderCancelled === false
      ) {
        setCancelMsgAlarm(purchaseOrder.error.putPurchaseOrderCancelledError);
        setCancelErrorAlarm(true);
        window.scrollTo(0, 0);
      }
    }
  }, [purchaseOrder, dispatch, pageInfo]);

  useEffect(() => {
    if (
      purchaseOrder.isLoadingPostSubmitPurchaseOrder !==
      prevPostSubmitStatus.current
    ) {
      //check the previous ref with current state
      prevPostSubmitStatus.current =
        purchaseOrder.isLoadingPostSubmitPurchaseOrder;

      if (
        purchaseOrder.postSubmitPurchaseOrderIsSuccess === true &&
        purchaseOrder.isLoadingPostSubmitPurchaseOrder === false
      ) {
        setSubmitSuccessAlarm(true);
        setSubmitMsgAlarm(
          t("translation:Purchase Receiving has been submitted")
        );
        if (!editView) {
          dispatch(getPurchaseOrderList.pending(pageInfo));
        }
        if (editView) {
          setEditView(false);
          setDetailView(true);
          setViewInfo(purchaseOrder.submitPurchaseOrder);
        }
      }

      if (
        purchaseOrder.postSubmitPurchaseOrderIsSuccess === false &&
        purchaseOrder.isLoadingPostSubmitPurchaseOrder === false
      ) {
        setErrorAlarm(true);
        setMsgAlarm(purchaseOrder.error.postSubmitPurchaseOrderError);
        window.scrollTo(0, 0);
      }
    }
  }, [purchaseOrder, dispatch, t, editView]);

  if (
    purchaseOrder.getPurchaseOrderListIsSuccess === false &&
    purchaseOrder.isLoadingGetPurchaseOrderList === false
  ) {
    return (
      <Alert severity="error">
        {purchaseOrder.error.getPurchaseOrderListError}
      </Alert>
    );
  } else if (
    common.getGeneralStatusIsSuccess === true &&
    !editView &&
    !detailView
  ) {
    return (
      <>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {submitlMsgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={errorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={WIPSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.WIP_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>
        <Collapse in={WIPErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {WIPMsgAlarm}
          </Alert>
        </Collapse>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmWIPOpen}
          setOpen={setConfirmWIPOpen}
          onConfirm={handleConfirmWIP}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <Collapse in={cancelSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setCancelSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.CANCELED_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>
        <Collapse in={cancelErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setCancelErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {cancelMsgAlarm}
          </Alert>
        </Collapse>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmCancelOpen}
          setOpen={setConfirmCancelOpen}
          onConfirm={handleConfirmCancelMode}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmProceedAllOpen}
          setOpen={setConfirmProceedAllOpen}
          onConfirm={onSubmit}>
          {t("translation:Are you sure you want to proceed?")}
          {defaultOrganisation?.organisations?.is_po_multiplier_required ===
            true && (
            <Grid item xs={12} className={classes.userInfoField}>
              <TextField
                required
                className={classes.userFieldColor}
                name="Multiplier"
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: "any",
                    style: { textAlign: "left" },
                  },
                }}
                onKeyDown={(event) => {
                  if (
                    event?.key === "-" ||
                    event?.key === "+" ||
                    event?.key === "e" ||
                    event?.key === "E"
                  ) {
                    event.preventDefault();
                  }
                }}
                label={"Multiplier"}
                variant="outlined"
                onBlur={(e) => {
                  setCostPriceMutiplier(e.target.value);
                }}
              />
            </Grid>
          )}
        </ConfirmDialog>
        <SortingSearchTable
          tableHeading={""}
          searchPlaceholder={t("translation:PO Order No")}
          statusPlaceholder={t("translation:Order Status")}
          statusOption={common.generalStatusList.status_general}
          searchValue={searchValue}
          columns={updateAccess ? columns : columnsViewOnly}
          data={
            purchaseOrder.purchaseOrderList?.purchase_order_headers !==
            undefined
              ? purchaseOrder.purchaseOrderList?.purchase_order_headers
              : []
          }
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={
            purchaseOrder.purchaseOrderList
              ? purchaseOrder.purchaseOrderList.total_pages
              : 0
          }
          loading={purchaseOrder.isLoadingGetPurchaseOrderList}
          totalItems={
            purchaseOrder.purchaseOrderList
              ? purchaseOrder.purchaseOrderList.total_items
              : 0
          }
          handleSearch={handleSearch}
        />
      </>
    );
  } else if (viewInfo && detailView) {
    return (
      <>
        {" "}
        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {submitlMsgAlarm}
          </Alert>
        </Collapse>{" "}
        <ProcessPurchaseOrderPage
          infoData={viewInfo}
          viewMode={true}
          setView={() => setDetailView(false)}
        />
      </>
    );
  } else if (viewInfo && editView) {
    return (
      <>
        <Collapse in={errorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <ProcessPurchaseOrderPage
          setView={() => setEditView(false)}
          infoData={viewInfo}
        />
      </>
    );
  } else {
    return <div>{t("translation:Loading")}...</div>;
  }
}
