//lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField, FormControlLabel } from "@mui/material";
//actions

import {
  putInvoiceMedicineCostingWIPMode,
  getInvoiceMedicineCostingSummaryList,
  putInvoiceMedicineCostingPendingStatus,
  postSubmitInvoiceMedicineCosting,
  getInvoiceMedicineCostingById,
} from "../../../actions/invoiceMedicineCostingActions";
import { getPaymentMethod } from "../../../actions/commonActions";

//components
import { SortingSearchTable } from "../../../components/SortingSearchTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { ReactComponent as EditLogo } from "../../../assets/coolicons/edit/edit.svg";
import { ReactComponent as DetailLogo } from "../../../assets/coolicons/file/file_blank_outline.svg";
import { ReactComponent as ProceedLogo } from "../../../assets/coolicons/media/fast_forward.svg";
import { ReactComponent as PendingLogo } from "../../../assets/coolicons/media/fast_rewind.svg";
import { ReactComponent as ProceedAllLogo } from "../../../assets/coolicons/media/skip_next.svg";
import * as pageConfigure from "../../../_constants/pageConstants";
import * as generalConstants from "../../../_constants/generalConstants";
import ProceedPage from "./ProceedPage";
import DetailPage from "./DetailPage";
import { useStyles } from "../../../components/globalStyles";
import { getTableDefaultLocalStorage } from "../../../components/functions/localStorage";
import StyledCheckbox from "../../../components/StyledCheckbox";
import Panel from "../../../components/Panel";

export default function InvoiceMedicineCostingListTable({
  setInformationView = () => null,
  setRefreshTimeStamp = () => null,
  refreshTimeStamp = null,
  dashboardView = false,
}) {
  //variables
  const { t } = useTranslation();
  const curUser = useSelector((state) => state.curUser);
  const localStorageNameKey = "IMCListTable";
  const updateAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.UPDATE_PERMISSION_TYPE
      )
    : false;

  const deleteAccess = curUser.allRightAccess
    ? curUser.allRightAccess.user_rights.some(
        (item) =>
          item.rights_type ===
            generalConstants.WORK_ORDER_INVOICE_MEDICINE_COSTING_RIGHT_TYPE &&
          item.max_permission_type >= generalConstants.DELETE_PERMISSION_TYPE
      )
    : false;

  const defaultOrganisation = curUser.organisationInfo?.user_organisations.find(
    (item) => item.is_default === true
  );

  // const options = {
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };
  const columns = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: t("translation:IMC Order No"),
      accessor: "order_number",
    },
    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsWUpdateAccess = [
    {
      Header: "",
      accessor: "Detail",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => (
        <>
          <Button onClick={() => handleRowDetail(row.original)}>
            <DetailLogo title={t("translation:Details")} />
          </Button>
        </>
      ),
    },
    {
      Header: "",
      accessor: "statusAction",

      //adjust sort
      sortable: false,
      //status type 1=new,2=pending,3=approved,4=WIP,5=PRINTOUT,6=Canceled,7=deleted,8=closed
      Cell: ({ row }) => {
        if (
          defaultOrganisation?.organisations?.id ===
          row.original.organisation?.id
        ) {
          if (
            row.original.status_type === generalConstants.PENDING_STATUS_TYPE
          ) {
            return (
              <Button onClick={() => handleRowProcess(row.original)}>
                <ProceedLogo title={t("translation:Proceed")} />
              </Button>
            );
          } else {
            return row.original.status_type ===
              generalConstants.WIP_STATUS_TYPE ? (
              <Button onClick={() => handleRowProcessDetail(row.original)}>
                <EditLogo title={t("translation:Edit")} />
              </Button>
            ) : (
              <></>
            );
          }
        }
        return <></>;
      },
    },
    {
      Header: "",
      accessor: "Pending",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        if (
          deleteAccess &&
          row.original.status_type === generalConstants.CLOSED_STATUS_TYPE &&
          defaultOrganisation.organisations.id === row.original.organisation.id
        ) {
          return (
            <Button onClick={() => handleRowProcess(row.original)}>
              <PendingLogo title={t("translation:WIP")} />
            </Button>
          );
        } else {
          return row.original.status_type ===
            generalConstants.WIP_STATUS_TYPE &&
            defaultOrganisation.organisations.id ===
              row.original.organisation.id ? (
            <>
              <Button onClick={() => handleRowPending(row.original)}>
                <PendingLogo title={t("translation:Pending")} />
              </Button>
            </>
          ) : (
            <></>
          );
        }
      },
    },

    {
      Header: "",
      accessor: "ProcessAll",

      //adjust sort
      sortable: false,
      Cell: ({ row }) =>
        row.original.status_type === generalConstants.WIP_STATUS_TYPE &&
        defaultOrganisation.organisations.id ===
          row.original.organisation.id ? (
          <>
            <Button onClick={() => handleRowProcessAll(row.original)}>
              <ProceedAllLogo title={t("translation:Close")} />
            </Button>
          </>
        ) : (
          <></>
        ),
    },
    {
      Header: t("translation:IMC Order No"),
      accessor: "order_number",
    },
    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const columnsWMultipleUpdateAccess = [
    {
      Header: "",
      accessor: "checkIMC",

      //adjust sort
      sortable: false,
      Cell: ({ row }) => {
        if (
          defaultOrganisation?.organisations?.id ===
            row.original.organisation?.id &&
          row.original.status_type !== generalConstants.CLOSED_STATUS_TYPE
        ) {
          return (
            <>
              <StyledCheckbox
                checked={IMCSubmissionList.some(
                  (e) => e.id === row.original.id
                )}
                name="checkIMC"
                onChange={(e) => handleIMCCheck(e, row)}
              />
            </>
          );
        }
        return <></>;
      },
    },
    {
      Header: t("translation:IMC Order No"),
      accessor: "order_number",
    },
    {
      Header: t("translation:Store Order No"),
      accessor: "store_header.order_number",
    },
    {
      Header: t("translation:Creation Date"),
      accessor: "created_on",
      Cell: ({ row }) => {
        if (row.original.created_on)
          return moment
            .utc(row.original.created_on)
            .local()
            .format("DD-MMM-YYYY HH:mm");
        else return "";
      },
    },

    {
      Header: t("translation:Order Status"),
      accessor: "status_type",
      Cell: ({ cell }) => {
        const cellValue = common.generalStatusList?.status_general?.find(
          (item) => item.id === cell.value
        );
        return cellValue ? cellValue.name : "";
      },
    },
  ];

  const [pageInfo, setPageInfo] = useState({
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGEOPTIONS.find(
      (size) =>
        size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
    )
      ? pageConfigure.DEFAULT_PAGEOPTIONS.find(
          (size) =>
            size === parseInt(getTableDefaultLocalStorage(localStorageNameKey))
        )
      : pageConfigure.DEFAULT_PAGESIZE,
    order_by_field: null,
    order_by_direction: null,

    is_pagination: true,

    search_branch_id_value: null,
    search_end_date_value: null,
    search_end_timestamp_value: null,
    search_invoice_medicine_costing_order_id_value: null,
    search_invoice_medicine_costing_order_number_value: null,
    search_start_date_value: null,
    search_start_timestamp_value: null,
    search_status_type_value: null,
    search_store_order_number_value: null,
    search_staff_name_value: null,
    search_staff_contact_number_value: null,
    search_physician_name_value: null,
  });

  const dispatch = useDispatch();
  const classes = useStyles();
  const invoiceMedicineCosting = useSelector(
    (state) => state.invoiceMedicineCosting
  );
  const common = useSelector((state) => state.common);

  const prevGetInvoiceMedicineCostingByIdOnStatus = useRef();
  const [isCompleteAll, setIsCompleteAll] = React.useState(false);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMultipleSelect, setConfirmMultipleSelect] = useState(false);
  const [confirmPendingOpen, setConfirmPendingOpen] = useState(false);
  const [confirmProcessAllOpen, setConfirmProcessAllOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [orderDetailView, setOrderDetailView] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");

  const [orderProcessView, setOrderProcessView] = React.useState(false);
  const [orderProcessInfo, setOrderProcessInfo] = React.useState(null);
  const prevWorkOrderWIPStatus = useRef();
  const prevWorkOrderPendingStatus = useRef();
  const prevSubmitInvoiceStatus = useRef();
  const prevRefreshTimeStamp = useRef();
  const [submitSuccessAlarm, setSubmitSuccessAlarm] = useState(false);
  const [submitErrorAlarm, setSubmitErrorAlarm] = useState(false);
  const [msgAlarm, setMsgAlarm] = React.useState([]);
  const [WIPSuccessAlarm, setWIPSuccessAlarm] = useState(false);
  const [WIPErrorAlarm, setWIPErrorAlarm] = useState(false);
  const [WIPmsgAlarm, setWIPMsgAlarm] = React.useState([]);
  const [PendingSuccessAlarm, setPendingSuccessAlarm] = useState(false);
  const [PendingErrorAlarm, setPendingErrorAlarm] = useState(false);
  const [PendingmsgAlarm, setPendingMsgAlarm] = React.useState([]);
  const [summaryProcess, setSummaryProcess] = React.useState(false);
  const [IMCSubmissionList, setIMCSubmissionList] = useState([]);
  const [paymentMethod, setPaymentMethod] = React.useState(null);
  //functions
  const handleIMCCheck = (e, row) => {
    if (e.target.checked) {
      if (IMCSubmissionList.length > 0) {
        const similarStatus = IMCSubmissionList.every(
          (item) => item.status_type === row.original.status_type
        );
        if (similarStatus) {
          setIMCSubmissionList((IMCSubmissionList) => [
            ...IMCSubmissionList,
            row.original,
          ]);
        } else {
          setSubmitErrorAlarm(true);
          setMsgAlarm(t("translation:Invalid Status Type"));
        }
      } else {
        setIMCSubmissionList((IMCSubmissionList) => [
          ...IMCSubmissionList,
          row.original,
        ]);
      }
    } else {
      setIMCSubmissionList((IMCSubmissionList) => [
        ...IMCSubmissionList.filter((e) => e.id !== row.original.id),
      ]);
    }
  };

  const handleRowProcess = (value) => {
    setConfirmOpen(true);
    setSelectedValue(value);
  };

  const handleRowPending = (value) => {
    setConfirmPendingOpen(true);
    setSelectedValue(value);
  };

  const handleRowProcessAll = (value) => {
    setConfirmProcessAllOpen(true);
    setSelectedValue(value);
    setSummaryProcess(true);
  };

  const onSubmitInvoiceMedicineCosting = () => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });

    setIsCompleteAll(true);
    if (selectedValue !== null) {
      dispatch(getInvoiceMedicineCostingById.pending(selectedValue.id));
    }
    if (selectedValue === null && IMCSubmissionList.length > 0) {
      dispatch(getInvoiceMedicineCostingById.pending(IMCSubmissionList[0].id));
    }
  };

  const handleConfirmProcess = () => {
    if (selectedValue !== null) {
      dispatch(putInvoiceMedicineCostingWIPMode.pending(selectedValue.id));
    }
    if (selectedValue === null && IMCSubmissionList.length > 0) {
      dispatch(
        putInvoiceMedicineCostingWIPMode.pending(IMCSubmissionList[0].id)
      );
    }
  };

  const handleConfirmPending = () => {
    if (selectedValue !== null) {
      dispatch(
        putInvoiceMedicineCostingPendingStatus.pending(selectedValue.id)
      );
    }
    if (selectedValue === null && IMCSubmissionList.length > 0) {
      dispatch(
        putInvoiceMedicineCostingPendingStatus.pending(IMCSubmissionList[0].id)
      );
    }
  };
  const handleRowDetail = (value) => {
    dispatch(getInvoiceMedicineCostingById.pending(value.id));
    //
    //setOrderDetailInfo(value);
    setOrderDetailView(true);
    setSubmitErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setPendingErrorAlarm(false);
    setPendingSuccessAlarm(false);
    //disable eidt view
    setOrderProcessView(false);
    setSummaryProcess(false);
  };

  const handleRowProcessDetail = (value) => {
    dispatch(getInvoiceMedicineCostingById.pending(value.id));
    //setOrderProcessInfo(value);
    setOrderProcessView(true);
    setSubmitErrorAlarm(false);
    setSubmitSuccessAlarm(false);
    setWIPErrorAlarm(false);
    setWIPSuccessAlarm(false);
    setPendingErrorAlarm(false);
    setPendingSuccessAlarm(false);
    //disable eidt view
    setOrderDetailView(false);
    setSummaryProcess(false);
  };

  //useEffects
  useEffect(() => {
    dispatch(getPaymentMethod.pending());
  }, [dispatch]);

  useEffect(() => {
    prevWorkOrderWIPStatus.current = false;
    prevWorkOrderPendingStatus.current = false;
    prevSubmitInvoiceStatus.current = false;
    prevRefreshTimeStamp.current = null;
  }, []);

  useEffect(() => {
    if (
      refreshTimeStamp !== prevRefreshTimeStamp.current &&
      dashboardView &&
      !orderDetailView &&
      !orderProcessView
    ) {
      //check the previous ref with current state
      prevRefreshTimeStamp.current = refreshTimeStamp;
      dispatch(getInvoiceMedicineCostingSummaryList.pending(pageInfo));
    }
  }, [
    refreshTimeStamp,
    dispatch,
    pageInfo,
    dashboardView,
    orderDetailView,
    orderProcessView,
  ]);

  useEffect(() => {
    if (dashboardView && refreshTimeStamp === prevRefreshTimeStamp.current) {
      //update the newest time after calling api
      prevRefreshTimeStamp.current = moment();
    }
  }, [refreshTimeStamp, dispatch, pageInfo, dashboardView]);

  useEffect(() => {
    if (
      invoiceMedicineCosting.isLoadingInvoiceMedicineCostingPendingStatus !==
      prevWorkOrderPendingStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderPendingStatus.current =
        invoiceMedicineCosting.isLoadingInvoiceMedicineCostingPendingStatus;

      if (
        invoiceMedicineCosting.putInvoiceMedicineCostingPendingStatusSuccess ===
          true &&
        invoiceMedicineCosting.isLoadingInvoiceMedicineCostingPendingStatus ===
          false
      ) {
        setPendingErrorAlarm(false);
        setPendingSuccessAlarm(true);
        dispatch(getInvoiceMedicineCostingSummaryList.pending(pageInfo));

        const newSubmissionList = IMCSubmissionList.filter(
          (item) =>
            item.id !==
            invoiceMedicineCosting.invoiceMedicineCostingPendingStatus
              .invoice_medicine_costing_header.id
        );

        setIMCSubmissionList(newSubmissionList);

        if (newSubmissionList.length > 0) {
          dispatch(
            putInvoiceMedicineCostingPendingStatus.pending(
              newSubmissionList[0].id
            )
          );
        }

        window.scrollTo(0, 0);
      }

      if (
        invoiceMedicineCosting.putInvoiceMedicineCostingPendingStatusSuccess ===
          false &&
        invoiceMedicineCosting.isLoadingInvoiceMedicineCostingPendingStatus ===
          false
      ) {
        setPendingErrorAlarm(true);
        setPendingSuccessAlarm(false);
        setPendingMsgAlarm(
          invoiceMedicineCosting.error.invoiceMedicineCostingPendingStatusError
        );
        window.scrollTo(0, 0);
      }
    }
  }, [dispatch, invoiceMedicineCosting, pageInfo]);

  useEffect(() => {
    if (
      invoiceMedicineCosting.isLoadingInvoiceMedicineCostingWIPMode !==
      prevWorkOrderWIPStatus.current
    ) {
      //
      //check the previous ref with current state
      prevWorkOrderWIPStatus.current =
        invoiceMedicineCosting.isLoadingInvoiceMedicineCostingWIPMode;

      if (
        invoiceMedicineCosting.putInvoiceMedicineCostingWIPModeSuccess ===
          true &&
        invoiceMedicineCosting.isLoadingInvoiceMedicineCostingWIPMode === false
      ) {
        setWIPErrorAlarm(false);
        setWIPSuccessAlarm(true);
        dispatch(getInvoiceMedicineCostingSummaryList.pending(pageInfo));
        const newSubmissionList = IMCSubmissionList.filter(
          (item) =>
            item.id !==
            invoiceMedicineCosting.invoiceMedicineCostingWIPMode
              .invoice_medicine_costing_header.id
        );

        setIMCSubmissionList(newSubmissionList);

        if (newSubmissionList.length > 0) {
          dispatch(
            putInvoiceMedicineCostingWIPMode.pending(newSubmissionList[0].id)
          );
        }
        window.scrollTo(0, 0);
      }

      if (
        invoiceMedicineCosting.putInvoiceMedicineCostingWIPModeSuccess ===
          false &&
        invoiceMedicineCosting.isLoadingInvoiceMedicineCostingWIPMode === false
      ) {
        setWIPErrorAlarm(true);
        setWIPSuccessAlarm(false);
        window.scrollTo(0, 0);
        setWIPMsgAlarm(
          invoiceMedicineCosting.error.invoiceMedicineCostingWIPModeError
        );
      }
    }
  }, [dispatch, invoiceMedicineCosting, pageInfo]);
  //
  useEffect(() => {
    if (
      invoiceMedicineCosting.isLoadingSubmitInvoiceMedicineCosting !==
      prevSubmitInvoiceStatus.current
    ) {
      //check the previous ref with current state
      prevSubmitInvoiceStatus.current =
        invoiceMedicineCosting.isLoadingSubmitInvoiceMedicineCosting;
      //
      if (
        invoiceMedicineCosting.postSubmitInvoiceMedicineCostingSuccess ===
          true &&
        invoiceMedicineCosting.isLoadingSubmitInvoiceMedicineCosting === false
      ) {
        setSubmitErrorAlarm(false);
        setSubmitSuccessAlarm(true);
        dispatch(getInvoiceMedicineCostingSummaryList.pending(pageInfo));

        setOrderProcessView(false);
        if (orderProcessView) {
          setOrderProcessInfo(
            invoiceMedicineCosting.submitInvoiceMedicineCosting
              .invoice_medicine_costing_header
          );

          setOrderDetailView(true);
        }

        if (!orderProcessView) {
          const newSubmissionList = IMCSubmissionList.filter(
            (item) =>
              item.id !==
              invoiceMedicineCosting.submitInvoiceMedicineCosting
                .invoice_medicine_costing_header.id
          );

          setIMCSubmissionList(newSubmissionList);

          if (newSubmissionList.length > 0) {
            dispatch(
              getInvoiceMedicineCostingById.pending(newSubmissionList[0].id)
            );
            setIsCompleteAll(true);
          }
        }
        window.scrollTo(0, 0);
      }
      if (
        invoiceMedicineCosting.postSubmitInvoiceMedicineCostingSuccess ===
          false &&
        invoiceMedicineCosting.isLoadingSubmitInvoiceMedicineCosting === false
      ) {
        setSubmitErrorAlarm(true);
        setSubmitSuccessAlarm(false);
        window.scrollTo(0, 0);
        setMsgAlarm(
          invoiceMedicineCosting.error.submitInvoiceMedicineCostingError
        );
      }
    }
  }, [dispatch, invoiceMedicineCosting, pageInfo, orderProcessView]);

  useEffect(() => {
    if (orderDetailView === true || orderProcessView) {
      setInformationView(true);
    } else {
      setInformationView(false);
    }
  }, [orderProcessView, orderDetailView, setInformationView]);

  useEffect(() => {
    //dispatch(getDispensaryList.pending(pageInfo));

    if (!orderDetailView && !orderProcessView && !dashboardView) {
      dispatch(getInvoiceMedicineCostingSummaryList.pending(pageInfo));
    }
  }, [dispatch, pageInfo, orderDetailView, orderProcessView, dashboardView]);

  useEffect(() => {
    if (
      invoiceMedicineCosting.isLoadingInvoiceMedicineCostingById !==
      prevGetInvoiceMedicineCostingByIdOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevGetInvoiceMedicineCostingByIdOnStatus.current =
        invoiceMedicineCosting.isLoadingInvoiceMedicineCostingById;

      if (
        invoiceMedicineCosting.getInvoiceMedicineCostingByIdSuccess === true &&
        invoiceMedicineCosting.isLoadingInvoiceMedicineCostingById === false
      ) {
        if (isCompleteAll) {
          const totalAmount =
            invoiceMedicineCosting.invoiceMedicineCostingById
              .invoice_medicine_costing_header.store_medicine_costing_header
              .amt_with_gst;

          const temAccAmount =
            invoiceMedicineCosting.invoiceMedicineCostingById.invoice_medicine_costing_header.invoice_medicine_costing_details.reduce(
              (sum, li) => {
                if (li.status_type === generalConstants.APPROVED_STATUS_TYPE) {
                  return parseFloat((sum + li.payment_amount).toFixed(2));
                } else {
                  return parseFloat(sum.toFixed(2));
                }
              },
              0
            );
          //

          const totalPayAmount = Number(
            (totalAmount - temAccAmount).toFixed(2)
          );
          const invoiceMedicineCostingHeader = {
            invoice_medicine_costing_header: {
              invoice_medicine_costing_details: [
                {
                  id: 0,
                  payment_amount: totalPayAmount,
                  status_type: generalConstants.APPROVED_STATUS_TYPE,
                  payment_method: paymentMethod,
                },
              ],
              id: invoiceMedicineCosting.invoiceMedicineCostingById
                .invoice_medicine_costing_header.id,
              order_number:
                invoiceMedicineCosting.invoiceMedicineCostingById
                  .invoice_medicine_costing_header.order_number,
            },
          };

          dispatch(
            postSubmitInvoiceMedicineCosting.pending(
              invoiceMedicineCostingHeader
            )
          );
          setIsCompleteAll(false);
        } else {
          setOrderProcessInfo(
            invoiceMedicineCosting.invoiceMedicineCostingById
              .invoice_medicine_costing_header
          );
        }
      }
    }
  }, [
    isCompleteAll,
    invoiceMedicineCosting,
    selectedValue,
    dispatch,
    setOrderProcessInfo,
  ]);

  useEffect(() => {
    //dispatch(getDispensaryList.pending(pageInfo));

    if (!orderDetailView && !orderProcessView) {
      setSubmitErrorAlarm(false);
      setSubmitSuccessAlarm(false);
      setWIPErrorAlarm(false);
      setWIPSuccessAlarm(false);
      setPendingErrorAlarm(false);
      setPendingSuccessAlarm(false);
    }
  }, [orderDetailView, orderProcessView]);
  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_invoice_medicine_costing_order_number_value: value,
        search_store_order_number_value: value,
        search_staff_name_value: value,
        search_staff_contact_number_value: value,
        search_physician_name_value: value,
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page_number: pageConfigure.DEFAULT_PAGENUMBER,
        search_invoice_medicine_costing_order_number_value: null,
        search_store_order_number_value: null,
        search_staff_name_value: null,
        search_staff_contact_number_value: null,

        search_physician_name_value: null,
      });
    }
  };
  if (
    invoiceMedicineCosting.getInvoiceMedicineCostingSummaryListSuccess ===
      false &&
    invoiceMedicineCosting.isLoadingInvoiceMedicineCostingSummaryList === false
  ) {
    return (
      <Alert severity="error">
        {invoiceMedicineCosting.error.invoiceMedicineCostingSummaryListError}
      </Alert>
    );
  } else if (
    invoiceMedicineCosting.getInvoiceMedicineCostingByIdSuccess === false &&
    invoiceMedicineCosting.isLoadingInvoiceMedicineCostingById === false &&
    (orderDetailView || orderProcessView)
  ) {
    return (
      <Alert severity="error">
        {invoiceMedicineCosting.error.invoiceMedicineCostingByIdError}
      </Alert>
    );
  } else if (
    (common.getGeneralStatusIsSuccess === true &&
      !orderDetailView &&
      !orderProcessView) ||
    summaryProcess
  ) {
    return (
      <>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Invoice Medicine Costing has been submitted")}
          </Alert>
        </Collapse>

        <Collapse in={submitErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={WIPSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.WIP_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={WIPErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setWIPErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {WIPmsgAlarm}
          </Alert>
        </Collapse>
        <Collapse in={PendingSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Order status has been changed to ") +
              common.generalStatusList.status_general.find(
                (item) => item.id === generalConstants.PENDING_STATUS_TYPE
              ).name}
          </Alert>
        </Collapse>

        <Collapse in={PendingErrorAlarm}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPendingErrorAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {PendingmsgAlarm}
          </Alert>
        </Collapse>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirmProcess}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmPendingOpen}
          setOpen={setConfirmPendingOpen}
          onConfirm={handleConfirmPending}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmProcessAllOpen}
          setOpen={setConfirmProcessAllOpen}
          onConfirm={onSubmitInvoiceMedicineCosting}>
          <Grid container>
            {t("translation:Are you sure you want to proceed?")}
            {common.paymentMethodList?.payment_methods && (
              <Grid item xs={12} className={classes.subTotalInfoField}>
                <Autocomplete
                  name="Payment_Methods"
                  disableClearable
                  options={common.paymentMethodList?.payment_methods}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  value={paymentMethod}
                  isOptionEqualToValue={(option, value) => {
                    if (option.id === value.id) return option;
                  }}
                  onChange={(e, option) => {
                    setPaymentMethod(option);
                  }}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      className={classes.userFieldColor}
                      {...params}
                      label={t("translation:Payment Methods")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </ConfirmDialog>

        {defaultOrganisation && (
          <>
            {/*only allow select own organisation*/}
            {invoiceMedicineCosting.invoiceMedicineCostingSummaryList?.invoice_medicine_costing_headers.find(
              (item) =>
                defaultOrganisation?.organisations?.id === item.organisation?.id
            ) && (
              <Panel>
                <Grid container>
                  <Grid item xs={12} className={classes.userInfoField}>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={confirmMultipleSelect ? true : false}
                          onChange={(e) => {
                            setConfirmMultipleSelect(e.target.checked);
                            setIMCSubmissionList([]);
                          }}
                          name="MultipleSelect"
                        />
                      }
                      label={t("translation:Multiple Select")}
                    />
                  </Grid>
                  {IMCSubmissionList.length > 0 && confirmMultipleSelect && (
                    <Grid item xs={12} className={classes.userInfoField}>
                      {IMCSubmissionList.every(
                        (item) =>
                          item.status_type ===
                          generalConstants.PENDING_STATUS_TYPE
                      ) ? (
                        <Button onClick={() => handleRowProcess(null)}>
                          <ProceedLogo title={t("translation:Proceed")} />
                        </Button>
                      ) : (
                        <></>
                      )}

                      {IMCSubmissionList.every(
                        (item) =>
                          item.status_type ===
                          generalConstants.CLOSED_STATUS_TYPE
                      ) && deleteAccess ? (
                        <Button onClick={() => handleRowProcess(null)}>
                          <PendingLogo title={t("translation:WIP")} />
                        </Button>
                      ) : (
                        <></>
                      )}

                      {IMCSubmissionList.every(
                        (item) =>
                          item.status_type === generalConstants.WIP_STATUS_TYPE
                      ) ? (
                        <Button onClick={() => handleRowPending(null)}>
                          <PendingLogo title={t("translation:Pending")} />
                        </Button>
                      ) : (
                        <></>
                      )}

                      {IMCSubmissionList.every(
                        (item) =>
                          item.status_type === generalConstants.WIP_STATUS_TYPE
                      ) ? (
                        <Button onClick={() => handleRowProcessAll(null)}>
                          <ProceedAllLogo title={t("translation:Close")} />
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Panel>
            )}

            <SortingSearchTable
              tableHeading=""
              searchPlaceholder={t("translation:IMC Order No/ Store Order No")}
              statusPlaceholder={t("translation:Order Status")}
              statusOption={common.generalStatusList.status_general}
              searchValue={searchValue}
              columns={
                updateAccess
                  ? confirmMultipleSelect
                    ? columnsWMultipleUpdateAccess
                    : columnsWUpdateAccess
                  : columns
              }
              data={
                invoiceMedicineCosting.invoiceMedicineCostingSummaryList
                  ? invoiceMedicineCosting.invoiceMedicineCostingSummaryList
                      .invoice_medicine_costing_headers
                  : []
              }
              pageInfo={pageInfo}
              setPageInfo={setPageInfo}
              pageCount={
                invoiceMedicineCosting.invoiceMedicineCostingSummaryList
                  ? invoiceMedicineCosting.invoiceMedicineCostingSummaryList
                      .total_pages
                  : 0
              }
              loading={
                invoiceMedicineCosting.isLoadingInvoiceMedicineCostingSummaryList
              }
              totalItems={
                invoiceMedicineCosting.invoiceMedicineCostingSummaryList
                  ? invoiceMedicineCosting.invoiceMedicineCostingSummaryList
                      .total_items
                  : 0
              }
              handleSearch={handleSearch}
              searchDate={true}
              searchDatePlaceholder={t("translation:Creation Date")}
              localStorageName={localStorageNameKey}
            />
          </>
        )}

        {!defaultOrganisation && (
          <Alert severity="error">
            {t("translation:Missing Organisation")}
          </Alert>
        )}
      </>
    );
  } else if (orderDetailView && orderProcessInfo !== null) {
    return (
      <>
        <Collapse in={submitSuccessAlarm}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSubmitSuccessAlarm(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {t("translation:Invoice Medicine Costing has been submitted")}
          </Alert>
        </Collapse>
        <DetailPage
          orderProcessInfo={orderProcessInfo}
          setOrderProcessInfo={setOrderProcessInfo}
          setOrderProcessView={() => {
            setOrderDetailView(false);
            setOrderProcessInfo(null);
            setRefreshTimeStamp(moment());
          }}></DetailPage>
      </>
    );
  } else if (orderProcessView && orderProcessInfo !== null) {
    return (
      <ProceedPage
        orderProcessInfo={orderProcessInfo}
        setOrderProcessInfo={setOrderProcessInfo}
        setOrderProcessView={() => {
          setOrderProcessView(false);
          setOrderProcessInfo(null);
          setRefreshTimeStamp(moment());
        }}
        submitSuccessAlarm={submitSuccessAlarm}
        setSubmitSuccessAlarm={setSubmitSuccessAlarm}
        submitErrorAlarm={submitErrorAlarm}
        setSubmitErrorAlarm={setSubmitErrorAlarm}
        msgAlarm={msgAlarm}
        setMsgAlarm={setMsgAlarm}
      />
    );
  } else {
    return <>{t("translation:Loading")}...</>;
  }
}
