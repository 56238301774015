import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Link } from "@mui/material";

import Alert from "@mui/material/Alert";
import { getReportingExportPurchaseOrderDetails } from "../../../../actions/reportingActions";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import Panel from "../../../../components/Panel";
import { MultiFieldSortingSearchTableWOData } from "../../../../components/MultiFieldSortingSearchTableWOData";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import * as pageConfigure from "../../../../_constants/pageConstants";
//style
import { useStyles } from "../../../../components/globalStyles";

export default function ExportPurchaseOrderDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const reporting = useSelector((state) => state.reporting);

  const prevSyncStatus = useRef();
  const [searchValue, setSearchValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [pageInfo, setPageInfo] = useState({
    is_pagination: true,
    order_by_direction: null,
    order_by_field: null,
    page_number: pageConfigure.DEFAULT_PAGENUMBER,
    page_size: pageConfigure.DEFAULT_PAGESIZE,
    search_end_date_value: null,
    search_end_timestamp_value: null,
    search_purchase_order_id_value: null,
    search_purchase_order_number_value: null,
    search_start_date_value: null,
    search_start_timestamp_value: null,
    search_status_type_value: null,
  });

  const handleSearch = (event) => {
    setPageInfo({
      ...pageInfo,
      page_number: pageConfigure.DEFAULT_PAGENUMBER,
      search_start_date_value: searchValue.startDate,
      search_end_date_value: searchValue.endDate,
    });
  };

  const handleConfirm = () => {
    if (pageInfo.search_start_date_value && pageInfo.search_end_date_value) {
      dispatch(getReportingExportPurchaseOrderDetails.pending(pageInfo));
    }
  };

  useEffect(() => {
    //set ref when first render
    prevSyncStatus.current = false;
  }, []);

  // useEffect(() => {
  //   if (pageInfo.search_start_date_value && pageInfo.search_end_date_value) {
  //     dispatch(getReportingInvoiceDetailsListByStore.pending(pageInfo));
  //   }
  // }, [dispatch, pageInfo]);

  return (
    <Panel heading={t("translation:Details")}>
      <Grid item xs={12}>
        <ConfirmDialog
          Yes={t("translation:Yes")}
          No={t("translation:No")}
          title={t("translation:Confirmation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleConfirm}>
          {t("translation:Are you sure you want to proceed?")}
        </ConfirmDialog>

        {reporting.getReportingExportPurchaseOrderDetailsSuccess === false &&
          reporting.isLoadingGetReportingExportPurchaseOrderDetails ===
            false && (
            <Alert severity="error">
              {reporting.error.getReportingExportPurchaseOrderDetailsError}
            </Alert>
          )}

        <MultiFieldSortingSearchTableWOData
          searchPlaceholder={""}
          columns={[]}
          data={[]}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          pageCount={0}
          loading={false}
          totalItems={0}
          handleSearch={handleSearch}
          disabledFooter>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              className={classes.userFieldColor}
              inputVariant="outlined"
              id="date"
              label={t("translation:Start Date")}
              autoOk={true}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              value={
                searchValue.startDate ? moment(searchValue.startDate) : null
              }
              format="DD MMM YYYY"
              onChange={(e) => {
                setSearchValue({
                  ...searchValue,
                  startDate: moment(e).isValid()
                    ? moment(e).format("YYYY-MM-DD")
                    : null,
                  endDate: null,
                });
                setPageInfo({
                  ...pageInfo,
                  search_start_date_value: null,
                  search_end_date_value: null,
                });
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              className={classes.userFieldColor}
              inputVariant="outlined"
              id="date"
              label={t("translation:End Date")}
              autoOk={true}
              slotProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              value={searchValue.endDate ? moment(searchValue.endDate) : null}
              format="DD MMM YYYY"
              maxDate={
                searchValue.startDate
                  ? moment(
                      moment(searchValue.startDate, "YYYY-MM-DD")
                        .add(1, "M")
                        .format("YYYY-MM-DD")
                    )
                  : ""
              }
              onChange={(e) => {
                setSearchValue({
                  ...searchValue,
                  endDate: moment(e).isValid()
                    ? moment(e).format("YYYY-MM-DD")
                    : null,
                });
                setPageInfo({
                  ...pageInfo,
                  search_start_date_value: null,
                  search_end_date_value: null,
                });
              }}
            />
          </LocalizationProvider>
        </MultiFieldSortingSearchTableWOData>
        {pageInfo.search_start_date_value && pageInfo.search_end_date_value && (
          <>
            {/* {reporting.reportingInvoiceDetailsListByStore &&
              common.generalStatusList && (
                <Button
                  title={t("translation:PRINT REPORT")}
                  onClick={() => {
                    setPrintDetail(new Date().valueOf());
                  }}
                  className={classes.grey}>
                  <Print />
                  {printDetail !== 0 && (
                    <StoreInvoiceReportPDF
                      orderDetailInfo={
                        reporting.reportingInvoiceDetailsListByStore
                          .store_headers
                      }
                      pageInfo={pageInfo}
                      title={"Store Invoice Report"}
                      t={t}
                      statusOption={common.generalStatusList.status_general}
                      print={printDetail}
                    />
                  )}
                </Button>
              )} */}

            {reporting.isLoadingGetReportingExportPurchaseOrderDetails ? (
              <>{t("translation:Loading")}... </>
            ) : (
              <Link
                className={classes.boldMaroon075}
                onClick={() => setConfirmOpen(true)}>
                <Button className={classes.boldMaroon075} name="synButton">
                  {t("translation:Download CSV FILE")}
                </Button>
              </Link>
            )}
          </>
        )}
      </Grid>
    </Panel>
  );
}
