import { createAction } from "../_helper/Utilities";
import * as types from "../_constants/workOrderConstants";

export const getDispensaryBranches = {
  pending: (data) =>
    createAction(types.GET_DISPENSARYBRANCHES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DISPENSARYBRANCHES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DISPENSARYBRANCHES_ASYNC.ERROR, { error }),
};

export const getMedicineTypes = {
  pending: (data) =>
    createAction(types.GET_MEDICINETYPES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_MEDICINETYPES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_MEDICINETYPES_ASYNC.ERROR, { error }),
};

export const getDispensaryBranchesAlliance = {
  pending: (data) =>
    createAction(types.GET_DISPENSARYBRANCHESALLIANCE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_DISPENSARYBRANCHESALLIANCE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_DISPENSARYBRANCHESALLIANCE_ASYNC.ERROR, { error }),
};

export const getMedicineTypesAlliance = {
  pending: (data) =>
    createAction(types.GET_MEDICINETYPESALLIANCE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_MEDICINETYPESALLIANCE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_MEDICINETYPESALLIANCE_ASYNC.ERROR, { error }),
};

export const postFormulasAlliance = {
  pending: (data) =>
    createAction(types.POST_FORMULASALLIANCE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_FORMULASALLIANCE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_FORMULASALLIANCE_ASYNC.ERROR, { error }),
};

export const postMedicineFormulasAlliance = {
  pending: (data) =>
    createAction(types.POST_MEDICINEFORMULASALLIANCE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_MEDICINEFORMULASALLIANCE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_MEDICINEFORMULASALLIANCE_ASYNC.ERROR, { error }),
};

export const postBranchMedicines = {
  pending: (data) =>
    createAction(types.POST_BRANCHMEDICINES_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_BRANCHMEDICINES_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_BRANCHMEDICINES_ASYNC.ERROR, { error }),
};

export const getCurrentOrganisation = {
  pending: (data) =>
    createAction(types.GET_CURRENTORGANISATION_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_CURRENTORGANISATION_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_CURRENTORGANISATION_ASYNC.ERROR, { error }),
};

export const postSaveStoreOrder = {
  pending: (data) =>
    createAction(types.POST_SAVESTOREORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SAVESTOREORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SAVESTOREORDER_ASYNC.ERROR, { error }),
};

export const postSubmitStoreOrder = {
  pending: (data) =>
    createAction(types.POST_SUBMITSTOREORDER_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.POST_SUBMITSTOREORDER_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.POST_SUBMITSTOREORDER_ASYNC.ERROR, { error }),
};

export const getStoreOrderList = {
  pending: (data) =>
    createAction(types.GET_STOREORDERLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_STOREORDERLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_STOREORDERLIST_ASYNC.ERROR, { error }),
};
export const getStoreOrderListLinkedDelivery = {
  pending: (data) =>
    createAction(types.GET_STOREORDERLISTLINKEDDELIVERY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_STOREORDERLISTLINKEDDELIVERY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_STOREORDERLISTLINKEDDELIVERY_ASYNC.ERROR, {
      error,
    }),
};
export const getStoreOrderSummaryList = {
  pending: (data) =>
    createAction(types.GET_STOREORDERSUMMARYLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_STOREORDERSUMMARYLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_STOREORDERSUMMARYLIST_ASYNC.ERROR, { error }),
};

export const getStoreOrderSummaryListLinkedDelivery = {
  pending: (data) =>
    createAction(types.GET_STOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_STOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_STOREORDERSUMMARYLISTLINKEDDELIVERY_ASYNC.ERROR, {
      error,
    }),
};

export const getStoreOrderById = {
  pending: (data) =>
    createAction(types.GET_STOREORDERBYID_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_STOREORDERBYID_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_STOREORDERBYID_ASYNC.ERROR, { error }),
};

export const getStoreOrderByIdLinkedDelivery = {
  pending: (data) =>
    createAction(types.GET_STOREORDERBYIDLINKEDDELIVERY_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_STOREORDERBYIDLINKEDDELIVERY_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_STOREORDERBYIDLINKEDDELIVERY_ASYNC.ERROR, { error }),
};

export const putStoreEditMode = {
  pending: (data) =>
    createAction(types.PUT_STOREEDITMODE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_STOREEDITMODE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_STOREEDITMODE_ASYNC.ERROR, { error }),
};

export const putStorePrintDate = {
  pending: (data) =>
    createAction(types.PUT_STOREPRINTDATE_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.PUT_STOREPRINTDATE_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.PUT_STOREPRINTDATE_ASYNC.ERROR, { error }),
};

export const getStorePrescriptionMsgList = {
  pending: (data) =>
    createAction(types.GET_STOREPRESCRIPTIONMSGLIST_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_STOREPRESCRIPTIONMSGLIST_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_STOREPRESCRIPTIONMSGLIST_ASYNC.ERROR, { error }),
};

export const getStoreMedicineInfo = {
  pending: (data) =>
    createAction(types.GET_STOREMEDICINEINFO_ASYNC.PENDING, { data }),
  success: (data) =>
    createAction(types.GET_STOREMEDICINEINFO_ASYNC.SUCCESS, { data }),
  error: (error) =>
    createAction(types.GET_STOREMEDICINEINFO_ASYNC.ERROR, { error }),
};

export const getStorePostalCodeSurchargeList = {
  pending: (data) =>
    createAction(types.GET_STOREPOSTALCODESURCHARGE_ASYNC.PENDING, {
      data,
    }),
  success: (data) =>
    createAction(types.GET_STOREPOSTALCODESURCHARGE_ASYNC.SUCCESS, {
      data,
    }),
  error: (error) =>
    createAction(types.GET_STOREPOSTALCODESURCHARGE_ASYNC.ERROR, {
      error,
    }),
};
