//lib
import React, { useState, useEffect, useCallback, useRef } from "react";
import QRCode from "qrcode";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, TextField, Link } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
//action

import {
  getMedicineMeasureUnit,
  getMedicineConsumeTime,
  getWaterAmount,
} from "../../../actions/commonActions";

//import { getOrganisationLogo } from "../../../actions/curUserActions";

import {
  putDispensaryPrintDate,
  putDispensaryStorePrintDate,
  putDispensaryVisitPrintDate,
  getDispensaryById,
} from "../../../actions/dispensaryActions";

import { postCreatePresignedUrlOrganisationLogo } from "../../../actions/cloudStorageActions";
//components
import Panel from "../../../components/Panel";
import ViewMedicineListTable from "./ViewMedicineListTable";
import DispensaryPDF from "../../../components/PDF/DispensaryPDF";
import PrescriptionLabelPDF from "../../../components/PDF/PrescriptionLabelPDF";

import NotoSansHansRegular from "../../../components/PDF/NotoSansHans-Regular-2.ttf";
import { Font } from "@react-pdf/renderer";
import { useStyles } from "../../../components/globalStyles";
import PatientPrescriptionPDF from "../../../components/PDF/PatientPrescriptionPDF";
import * as generalConstants from "../../../_constants/generalConstants";
import RequiredNote from "../../../components/RequiredNote";
import PageOnlyBackAction from "../../../components/PageOnlyBackAction";
import ViewDelivery from "../Store/components/viewOrder/ViewDelivery";
import ViewBrewing from "../Store/components/viewOrder/ViewBrewing";
import ViewSharedDelivery from "../Store/components/viewOrder/ViewSharedDelivery";
import InvoicePDFPage from "./components/InvoicePDFPage";
import S3OrganisationLogo, {
  setPrintingOrganisationLogo,
} from "../../../components/functions/S3OrganisationLogo";

import CheckerPhoto from "./components/CheckerPhoto";
//register font before calling pdf file
Font.register({
  family: "NotoSansSChinese",
  fonts: [{ src: NotoSansHansRegular }],
});

Font.registerHyphenationCallback((word) =>
  word.length === 1 ? [word] : Array.from(word).map((char) => char)
);
//style

const OrderDetailPage = ({
  setOrderDetailView,
  orderDetailInfo,
  setOrderDetailInfo = () => null,
}) => {
  const [pdfFontLoaded, setPdfFontLoaded] = useState(false);
  const loadFonts = useCallback(async () => {
    await Promise.all([Font.load({ fontFamily: "NotoSansSChinese" })]).then(
      () => {
        setPdfFontLoaded(true);
      }
    );
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);
  //variables
  const defaultMedicineGroup = orderDetailInfo.store_medicine_header;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prevSetPrintDateOnStatus = useRef();
  const prevSetPrintVisitDateOnStatus = useRef();
  const prevSetPrintStoreDateOnStatus = useRef();
  const prevGetDispensaryBuIdOnStatus = useRef();
  const [medicineConsumeInfo] = useState({
    number_of_days: defaultMedicineGroup.number_of_days,
    number_of_times: defaultMedicineGroup.number_of_times,
    when_consume: defaultMedicineGroup.when_consume,
    dispensary_instructions: defaultMedicineGroup.dispensary_instructions,
    patient_instructions: defaultMedicineGroup.patient_instructions,
    // is_brewing_services: defaultMedicineGroup.is_brewing_services,
    // brewing_instructions: defaultMedicineGroup.brewing_instructions,
    // water_amount: defaultMedicineGroup.water_amount,
    // is_delivery_services: defaultMedicineGroup.is_delivery_services,
    // delivery_address: defaultMedicineGroup.delivery_address,
    // delivery_contact_number: defaultMedicineGroup.delivery_contact_number,
    // delivery_name: defaultMedicineGroup.delivery_name,
    // delivery_remarks: defaultMedicineGroup.delivery_remarks,
    // delivery_incl_prescription: defaultMedicineGroup.delivery_incl_prescription,
    // delivery_incl_receipt: defaultMedicineGroup.delivery_incl_receipt,
  });

  const [medicineList] = useState(
    orderDetailInfo.dispensary_medicine_header.map((object) => {
      if (Object.keys(object.dispensary_medicine_details).length === 0) {
        return { ...object, dispensedDispensary: 0 };
      }
      const temDispensaryQuantity = object.dispensary_medicine_details.reduce(
        (sum, li) => {
          return parseFloat(
            (sum + li.dispensary_medicine_quantity).toFixed(10)
          );
        },
        0
      );
      return {
        ...object,
        dispensedDispensary: temDispensaryQuantity,
      };
    })
  );

  //redux variable
  const common = useSelector((state) => state.common);
  const userAuth = useSelector((state) => state.userAuth);
  const dispensary = useSelector((state) => state.dispensary);
  //const curUser = useSelector((state) => state.curUser);
  const user = userAuth.systemUserInfo;
  const [TCMLogo, setTCMLOGO] = useState(null);
  const [print, setPrint] = React.useState(0);
  const [printLabel, setPrintLabel] = React.useState(0);
  const [printDispensary, setPrintDispensary] = React.useState(0);
  const [allowPrint, setAllowPrint] = useState(false);
  const [storeAllowPrint, setStoreAllowPrint] = useState(false);
  const [invoiceAllowPrint, setInvoiceAllowPrint] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useState(
    dispensary.getDispensaryInvoiceOrder
      ? dispensary.getDispensaryInvoiceOrder.invoice_header
      : null
  );

  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const [open, setOpen] = React.useState(false);
  //functions

  const getMedicineCosumeTimeOpObj = (option) => {
    if (!option.id)
      option = common.medicineCosumeTimeList.when_consume_select_field.find(
        (op) => op.id === option
      );
    return option;
  };

  const setPrintingLogo = () => {
    setTCMLOGO(null);
    //const organisationId = orderDetailInfo.store_header.organisation.id;

    // dispatch(getOrganisationLogo.pending(organisationId));
    // dispatch(getOrganisation.pending(organisationId));

    setPrint(new Date().valueOf());
    setStoreAllowPrint(false);

    const submitJson = setPrintingOrganisationLogo(
      orderDetailInfo.store_header.organisation.codename,
      "organisation",
      orderDetailInfo.store_header.organisation.logo
    );
    dispatch(postCreatePresignedUrlOrganisationLogo.pending(submitJson));
  };

  const setStorePrintingDate = () => {
    if (
      orderDetailInfo.store_header.work_order_type ===
      generalConstants.STORE_WORK_ORDER_TYPE
    ) {
      dispatch(
        putDispensaryStorePrintDate.pending({
          store_header: { id: orderDetailInfo.store_header.id },
        })
      );
    }

    if (
      orderDetailInfo.store_header.work_order_type ===
      generalConstants.VISIT_WORK_ORDER_TYPE
    ) {
      dispatch(
        putDispensaryVisitPrintDate.pending({
          store_header: { id: orderDetailInfo.store_header.id },
        })
      );
    }
  };

  const setPrintingDispensary = () => {
    setPrintDispensary(new Date().valueOf());
    setAllowPrint(false);
  };

  const setDispensaryPrintingDate = () => {
    dispatch(
      putDispensaryPrintDate.pending({
        dispensary_header: { id: orderDetailInfo.id },
      })
    );
  };

  //useEffects

  useEffect(() => {
    const QrJSON = JSON.stringify({
      storeOrderNum: orderDetailInfo.store_header.order_number,
      groupName: orderDetailInfo.store_medicine_header.name,
    });
    QRCode.toDataURL(
      QrJSON,
      { errorCorrectionLevel: "H" },
      function (err, url) {
        if (!err) setQrCodeSrc(url);
      }
    );
  }, []);

  useEffect(() => {
    prevSetPrintDateOnStatus.current = false;
    prevSetPrintVisitDateOnStatus.current = false;
    prevGetDispensaryBuIdOnStatus.current = false;
    prevSetPrintStoreDateOnStatus.current = false;
  }, []);

  useEffect(() => {
    if (
      dispensary.isLoadingPutDispensaryPrintDate !==
      prevSetPrintDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintDateOnStatus.current =
        dispensary.isLoadingPutDispensaryPrintDate;

      if (
        dispensary.putDispensaryPrintDateIsSuccess === true &&
        dispensary.isLoadingPutDispensaryPrintDate === false
      ) {
        setAllowPrint(true);
        //setOrderDetailInfo(dispensary.putDispensaryPrintDate.dispensary_header);
      }
    }
  }, [dispensary, setOrderDetailInfo]);

  useEffect(() => {
    if (allowPrint) {
      setOrderDetailInfo(dispensary.putDispensaryPrintDate.dispensary_header);
    }
  }, [dispensary.putDispensaryPrintDate, allowPrint, setOrderDetailInfo]);

  useEffect(() => {
    if (
      dispensary.isLoadingPutDispensaryVisitPrintDate !==
      prevSetPrintVisitDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintVisitDateOnStatus.current =
        dispensary.isLoadingPutDispensaryVisitPrintDate;

      if (
        dispensary.putDispensaryVisitPrintDateIsSuccess === true &&
        dispensary.isLoadingPutDispensaryVisitPrintDate === false
      ) {
        // setOrderDetailInfo(
        //   dispensary.putDispensaryVisitPrintDate.dispensary_header
        // );
        setStoreAllowPrint(true);
        dispatch(getDispensaryById.pending(orderDetailInfo.id));
      }
    }
  }, [dispensary, orderDetailInfo, dispatch]);

  useEffect(() => {
    if (
      dispensary.isLoadingPutDispensaryStorePrintDate !==
      prevSetPrintStoreDateOnStatus.current
    ) {
      //
      //check the previous ref with current state
      prevSetPrintStoreDateOnStatus.current =
        dispensary.isLoadingPutDispensaryStorePrintDate;

      if (
        dispensary.putDispensaryStorePrintDateIsSuccess === true &&
        dispensary.isLoadingPutDispensaryStorePrintDate === false
      ) {
        // setOrderDetailInfo(
        //   dispensary.putDispensaryStorePrintDate.dispensary_header
        // );
        setStoreAllowPrint(true);
        dispatch(getDispensaryById.pending(orderDetailInfo.id));
      }
    }
  }, [dispensary, orderDetailInfo, dispatch]);

  // useEffect(() => {
  //   if (
  //     dispensary.isLoadingDispensaryById !==
  //     prevGetDispensaryBuIdOnStatus.current
  //   ) {
  //     //
  //     //check the previous ref with current state
  //     prevGetDispensaryBuIdOnStatus.current =
  //       dispensary.isLoadingDispensaryById;

  //     if (
  //       dispensary.getDispensaryByIdSuccess === true &&
  //       dispensary.isLoadingDispensaryById === false
  //     ) {
  //       setOrderDetailInfo(dispensary.dispensaryById.dispensary_header);
  //     }
  //   }
  // }, [dispensary, setOrderDetailInfo]);

  useEffect(() => {
    dispatch(getMedicineConsumeTime.pending());
    dispatch(getMedicineMeasureUnit.pending());
    dispatch(getWaterAmount.pending());
  }, [dispatch]);

  // useEffect(() => {
  //   if (curUser.OrganisationLogo) {
  //     setTCMLOGO(curUser.OrganisationLogo.data);
  //   } else if (curUser.error?.organisationLogoError) {
  //     setLogoError(true);
  //     if (
  //       curUser.error?.organisationLogoError === "Missing Organisation Logo"
  //     ) {
  //       setLogoErrorMsg(t("translation:Missing Organisation Logo"));
  //     } else {
  //       setLogoErrorMsg(curUser.error?.organisationLogoError);
  //     }
  //   } else {
  //     setTCMLOGO(null);
  //   }
  // }, [curUser, t]);
  return (
    <>
      <S3OrganisationLogo
        organisationCode={orderDetailInfo.store_header.organisation.codename}
        folderPath="organisation"
        setPhotoURL={setTCMLOGO}
      />

      <Typography display="block" variant="h4">
        {orderDetailInfo.store_header?.order_number}
      </Typography>
      <Typography display="block" variant="h4">
        {orderDetailInfo.store_medicine_header?.name}
      </Typography>
      <Link
        onClick={() => {
          setPrintingDispensary();
        }}
        className={classes.boldMaroon075}>
        <Button className={classes.boldMaroon075}>
          {t("translation:PRINT DISPENSARY PRESCRIPTION")}
        </Button>
      </Link>
      {printDispensary !== 0 &&
        pdfFontLoaded &&
        common.medicineMeasureUnitList &&
        common.medicineMeasureUnitList &&
        qrCodeSrc && (
          <DispensaryPDF
            user={user}
            qrCode={qrCodeSrc}
            print={printDispensary}
            orderDetailInfo={orderDetailInfo}
            t={t}
            measureUnit={
              common.medicineMeasureUnitList
                ? common.medicineMeasureUnitList.measure_units
                : []
            }
            waterAmount={
              common.waterAmountList
                ? common.waterAmountList.water_amount_select_field
                : []
            }
            setPrintingDate={setDispensaryPrintingDate}
            allowPrint={allowPrint}
            setAllowPrint={setAllowPrint}
          />
        )}
      <Link
        onClick={() => {
          setPrintLabel(new Date().valueOf());
        }}
        className={classes.boldMaroon075}>
        <Button className={classes.boldMaroon075}>
          {t("translation:PRINT PRESCRIPTION LABEL")}
        </Button>
      </Link>
      {pdfFontLoaded && printLabel !== 0 && (
        <PrescriptionLabelPDF
          orderDetailInfo={orderDetailInfo}
          t={t}
          print={printLabel}
        />
      )}

      {orderDetailInfo.store_medicine_header.delivery_incl_prescription && (
        <Link
          onClick={() => {
            setPrintingLogo();
          }}
          className={classes.boldMaroon075}>
          <Button className={classes.boldMaroon075}>
            {t("translation:PRINT PATIENT PRESCRIPTION")}
          </Button>

          {pdfFontLoaded &&
            TCMLogo &&
            print !== 0 &&
            common.medicineMeasureUnitList && (
              <PatientPrescriptionPDF
                storeMedicineHeader={orderDetailInfo.store_medicine_header}
                storeHeader={orderDetailInfo.store_header}
                dispensaryMedicineHeader={
                  orderDetailInfo.dispensary_medicine_header
                }
                t={t}
                measureUnit={common.medicineMeasureUnitList.measure_units}
                TCMLogo={TCMLogo}
                print={print}
                setPrintingDate={setStorePrintingDate}
                allowPrint={storeAllowPrint}
                setAllowPrint={setStoreAllowPrint}
              />
            )}
        </Link>
      )}

      {orderDetailInfo.store_medicine_header.delivery_incl_receipt && (
        <InvoicePDFPage
          organisationCode={orderDetailInfo.store_header.organisation.codename}
          folderPath="organisation"
          organisationLogoUrl={orderDetailInfo.store_header.organisation.logo}
          pdfFontLoaded={pdfFontLoaded}
          TCMLogo={TCMLogo}
          invoiceInfo={invoiceInfo}
          setTCMLOGO={setTCMLOGO}
          setInvoiceInfo={setInvoiceInfo}
          setPrintingOrganisationLogo={setPrintingOrganisationLogo}
          allowPrint={invoiceAllowPrint}
          setAllowPrint={setInvoiceAllowPrint}
        />
      )}

      <form>
        <br />

        <Grid>
          <div>
            <Panel>
              <Grid container>
                <Link className={classes.boldMaroon075}>
                  <Button
                    className={classes.boldMaroon075}
                    onClick={() => setOpen(true)}
                    cy_data="rejectButton">
                    {t("translation:Check Photo")}
                  </Button>
                </Link>
                {open && (
                  <CheckerPhoto
                    open={open}
                    setOpen={setOpen}
                    organisationCode={orderDetailInfo.organisation.codename}
                    folderPath="dispensary"
                    uploadFileObjects={orderDetailInfo}
                  />
                )}
                <Grid item xs={12} className={classes.userInfoField}>
                  <ViewMedicineListTable
                    data={medicineList.sort((a, b) => a.row_no - b.row_no)}
                    t={t}
                    isRawHerb={
                      orderDetailInfo.store_medicine_header.medicine_type
                        .is_for_brewing
                    }
                    measureUnit={
                      common.medicineMeasureUnitList
                        ? common.medicineMeasureUnitList.measure_units
                        : []
                    }
                  />
                </Grid>

                <Grid item xs={4} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                        style: { textAlign: "right" },
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "e" ||
                        event?.key === "E"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={
                      medicineConsumeInfo.number_of_days !== null
                        ? medicineConsumeInfo.number_of_days
                        : ""
                    }
                    disabled
                    required
                    id="Day"
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    name="number_of_days"
                    label={t("translation:Day")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                        style: { textAlign: "right" },
                      },
                    }}
                    onKeyDown={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "e" ||
                        event?.key === "E"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={
                      medicineConsumeInfo.number_of_times !== null
                        ? medicineConsumeInfo.number_of_times
                        : ""
                    }
                    type="number"
                    onWheel={(event) => event.target.blur()}
                    disabled
                    required
                    id="Times"
                    name="number_of_times"
                    label={t("translation:Times")}
                    variant="outlined"
                    onFocus={(event) => {
                      event.target.select();
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={classes.userInfoField}>
                  {common.medicineCosumeTimeList && (
                    <Autocomplete
                      value={medicineConsumeInfo.when_consume}
                      name="when_consume"
                      disabled
                      options={
                        common.medicineCosumeTimeList.when_consume_select_field
                      }
                      getOptionLabel={(option) =>
                        getMedicineCosumeTimeOpObj(option).name
                      }
                      style={{ width: "100%" }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value
                      }
                      renderInput={(params) => (
                        <TextField
                          required
                          className={classes.userFieldColor}
                          {...params}
                          name="when_consume"
                          label={t("translation:When")}
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      medicineConsumeInfo.dispensary_instructions
                        ? medicineConsumeInfo.dispensary_instructions
                        : ""
                    }
                    multiline
                    rows={3}
                    name="dispensary_instructions"
                    label={t("translation:Dispensary Instructions")}
                    variant="outlined"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} className={classes.userInfoField}>
                  <TextField
                    className={classes.userFieldColor}
                    value={
                      medicineConsumeInfo.patient_instructions
                        ? medicineConsumeInfo.patient_instructions
                        : ""
                    }
                    multiline
                    rows={3}
                    name="patient_instructions"
                    label={t("translation:Prescription Instructions")}
                    variant="outlined"
                    disabled
                  />
                </Grid>
              </Grid>
            </Panel>
            {(defaultMedicineGroup.is_delivery_services === true ||
              defaultMedicineGroup.is_brewing_services === true ||
              defaultMedicineGroup.delivery_owned?.status_type ===
                generalConstants.APPROVED_STATUS_TYPE) && (
              <Panel>
                {defaultMedicineGroup.is_brewing_services && (
                  <ViewBrewing memoSelectedGroup={defaultMedicineGroup} />
                )}
                {defaultMedicineGroup.is_delivery_services && (
                  <ViewDelivery
                    memoSelectedGroup={defaultMedicineGroup}
                    onlyViewDeliveryRemarks={true}
                  />
                )}

                {defaultMedicineGroup.delivery_owned?.status_type ===
                  generalConstants.APPROVED_STATUS_TYPE && (
                  <ViewSharedDelivery
                    memoSelectedGroup={defaultMedicineGroup}
                    viemOnly={true}
                    onlyViewDeliveryRemarks={true}
                  />
                )}
              </Panel>
            )}
          </div>
          <>
            <RequiredNote />
            <PageOnlyBackAction setView={() => setOrderDetailView(false)} />
          </>
        </Grid>
      </form>
    </>
  );
};

export default OrderDetailPage;
