import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getDeliveryOrderSummaryList,
  getDeliveryOrderList,
  postSaveDeliveryOrder,
  postSubmitDeliveryOrder,
  postSubmitAllDelivery,
  putDeliveryOrderWIPMode,
  putDeliveryOrderEditMode,
  putDeliveryOrderPendingMode,
  getDeliveryCourierList,
  getDeliveryCourierByBranch,
  getDeliveryOrderByStoreHeaderId,
  getStoreHeaderList,
  getStoreHeaderSummaryList,
  putDeliveryOrderPrintDate,
  putDeliveryOrderUpdateStoreDeliveryAddress,
  postExportDeliveryOrders,
  postExportDeliveryOrdersV2,
} from "../actions/deliveryOrderActions";
import * as types from "../_constants/deliveryOrderConstants";
import * as notification from "../_constants/notificationConstants";
import deliveryOrderApis from "../_apis/deliveryOrderApis";
export default function* deliveryOrderSagas() {
  yield all([
    takeLatest(
      types.GET_DELIVERYORDERSUMMARYLIST_ASYNC.PENDING,
      getDeliveryOrderSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYORDERLIST_ASYNC.PENDING,
      getDeliveryOrderListCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SAVEDELIVERYORDER_ASYNC.PENDING,
      postSaveDeliveryOrderCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITDELIVERYORDER_ASYNC.PENDING,
      postSubmitDeliveryOrderCall
    ),
  ]);
  yield all([
    takeLatest(
      types.POST_SUBMITALLDELIVERY_ASYNC.PENDING,
      postSubmitAllDeliveryCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYORDERWIPMODE_ASYNC.PENDING,
      putDeliveryOrderWIPModeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYORDEREDITMODE_ASYNC.PENDING,
      putDeliveryOrderEditModeCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYORDERPENDINGMODE_ASYNC.PENDING,
      putDeliveryOrderPendingCall
    ),
  ]);
  yield all([
    takeLatest(
      types.GET_DELIVERYCOURIERLIST_ASYNC.PENDING,
      getDeliveryCourierListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYCOURIERBYBRANCH_ASYNC.PENDING,
      getDeliveryCourierByBranchCall
    ),
  ]);

  yield all([
    takeLatest(
      types.GET_DELIVERYORDERBYSTOREHEADERID_ASYNC.PENDING,
      getDeliveryOrderByStoreHeaderIdCall
    ),
  ]);

  yield all([
    takeLatest(types.GET_STOREHEADERLIST_ASYNC.PENDING, getStoreHeaderListCall),
  ]);

  yield all([
    takeLatest(
      types.GET_STOREHEADERSUMMARYLIST_ASYNC.PENDING,
      getStoreHeaderSummaryListCall
    ),
  ]);

  yield all([
    takeLatest(
      types.PUT_DELIVERYORDERPRINTDATE_ASYNC.PENDING,
      putDeliveryOrderPrintDateCall
    ),
  ]);
  yield all([
    takeLatest(
      types.PUT_DELIVERYORDERUPDATESTOREDELIVERYADDRESS_ASYNC.PENDING,
      putDeliveryOrderUpdateStoreDeliveryAddressCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_EXPORTDELIVERYORDERS_ASYNC.PENDING,
      postExportDeliveryOrdersCall
    ),
  ]);

  yield all([
    takeLatest(
      types.POST_EXPORTDELIVERYORDERSV2_ASYNC.PENDING,
      postExportDeliveryOrdersV2Call
    ),
  ]);
}

function* getDeliveryOrderSummaryListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderSummaryListApi,
      model.data
    );
    yield put(getDeliveryOrderSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOrderSummaryList.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryOrderSummaryList.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryOrderListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderListApi,
      model.data
    );
    yield put(getDeliveryOrderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryOrderList.error(error.response?.data?.message));
    else yield put(getDeliveryOrderList.error(notification.CONST_NOINTERNET));
  }
}

function* postSaveDeliveryOrderCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSaveDeliveryOrderApi,
      model.data
    );
    yield put(postSaveDeliveryOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSaveDeliveryOrder.error(error.response?.data?.message));
    else yield put(postSaveDeliveryOrder.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitDeliveryOrderCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSubmitDeliveryOrderApi,
      model.data
    );
    yield put(postSubmitDeliveryOrder.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitDeliveryOrder.error(error.response?.data?.message));
    else
      yield put(postSubmitDeliveryOrder.error(notification.CONST_NOINTERNET));
  }
}

function* postSubmitAllDeliveryCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.postSubmitAllDeliveryApi,
      model.data
    );
    yield put(postSubmitAllDelivery.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postSubmitAllDelivery.error(error.response?.data?.message));
    else yield put(postSubmitAllDelivery.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryOrderWIPModeCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderWIPModeApi,
      model.data
    );
    yield put(putDeliveryOrderWIPMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDeliveryOrderWIPMode.error(error.response?.data?.message));
    else
      yield put(putDeliveryOrderWIPMode.error(notification.CONST_NOINTERNET));
  }
}
function* putDeliveryOrderEditModeCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderEditModeApi,
      model.data
    );
    yield put(putDeliveryOrderEditMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDeliveryOrderEditMode.error(error.response?.data?.message));
    else
      yield put(putDeliveryOrderEditMode.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryOrderPendingCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderPendingModeApi,
      Model.data
    );
    yield put(putDeliveryOrderPendingMode.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryOrderPendingMode.error(error.response?.data?.message)
      );
    else
      yield put(
        putDeliveryOrderPendingMode.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryCourierListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryCourierListApi,
      model.data
    );
    yield put(getDeliveryCourierList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getDeliveryCourierList.error(error.response?.data?.message));
    else yield put(getDeliveryCourierList.error(notification.CONST_NOINTERNET));
  }
}

function* getDeliveryCourierByBranchCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryCourierByBranchApi,
      model.data
    );
    yield put(getDeliveryCourierByBranch.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryCourierByBranch.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryCourierByBranch.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getDeliveryOrderByStoreHeaderIdCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getDeliveryOrderByStoreHeaderIdApi,
      model.data
    );
    yield put(getDeliveryOrderByStoreHeaderId.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        getDeliveryOrderByStoreHeaderId.error(error.response?.data?.message)
      );
    else
      yield put(
        getDeliveryOrderByStoreHeaderId.error(notification.CONST_NOINTERNET)
      );
  }
}

function* getStoreHeaderListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getStoreHeaderListApi,
      model.data
    );
    yield put(getStoreHeaderList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getStoreHeaderList.error(error.response?.data?.message));
    else yield put(getStoreHeaderList.error(notification.CONST_NOINTERNET));
  }
}

function* getStoreHeaderSummaryListCall(model) {
  try {
    const data = yield call(
      deliveryOrderApis.getStoreHeaderSummaryListApi,
      model.data
    );
    yield put(getStoreHeaderSummaryList.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(getStoreHeaderSummaryList.error(error.response?.data?.message));
    else
      yield put(getStoreHeaderSummaryList.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryOrderPrintDateCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderPrintDateApi,
      Model.data
    );
    yield put(putDeliveryOrderPrintDate.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(putDeliveryOrderPrintDate.error(error.response?.data?.message));
    else
      yield put(putDeliveryOrderPrintDate.error(notification.CONST_NOINTERNET));
  }
}

function* putDeliveryOrderUpdateStoreDeliveryAddressCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.putDeliveryOrderUpdateStoreDeliveryAddressApi,
      Model.data
    );
    yield put(putDeliveryOrderUpdateStoreDeliveryAddress.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        putDeliveryOrderUpdateStoreDeliveryAddress.error(
          error.response?.data?.message
        )
      );
    else
      yield put(
        putDeliveryOrderUpdateStoreDeliveryAddress.error(
          notification.CONST_NOINTERNET
        )
      );
  }
}

function* postExportDeliveryOrdersCall(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.postExportDeliveryOrdersApi,
      Model.data
    );
    yield put(postExportDeliveryOrders.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(postExportDeliveryOrders.error(error.response?.data?.message));
    else
      yield put(postExportDeliveryOrders.error(notification.CONST_NOINTERNET));
  }
}

function* postExportDeliveryOrdersV2Call(Model) {
  try {
    const data = yield call(
      deliveryOrderApis.postExportDeliveryOrdersV2Api,
      Model.data
    );
    yield put(postExportDeliveryOrdersV2.success(data));
  } catch (error) {
    if (error.response?.data?.message)
      yield put(
        postExportDeliveryOrdersV2.error(error.response?.data?.message)
      );
    else
      yield put(
        postExportDeliveryOrdersV2.error(notification.CONST_NOINTERNET)
      );
  }
}
