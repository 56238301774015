import * as types from "../_constants/deliveryConstants";

const initialState = {};
export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case types.GET_DELIVERYLIST_ASYNC.PENDING:
      return {
        ...state,
        getDeliveryListIsSuccess: false,
        isLoadingDeliveryList: true,
        deliveryList: null,
      };

    case types.GET_DELIVERYLIST_ASYNC.ERROR:
      return {
        ...state,
        getDeliveryListIsSuccess: false,
        isLoadingDeliveryList: false,
        deliveryList: null,
        error: { ...state.error, deliveryListError: action.error },
      };

    case types.GET_DELIVERYLIST_ASYNC.SUCCESS:
      return {
        ...state,
        getDeliveryListIsSuccess: true,
        isLoadingDeliveryList: false,
        deliveryList: action.data,
      };

    case types.POST_SUBMITDELIVERY_ASYNC.PENDING:
      return {
        ...state,
        postSubmitDeliverySuccess: false,
        isLoadingSubmitDelivery: true,
        submitDelivery: null,
      };

    case types.POST_SUBMITDELIVERY_ASYNC.ERROR:
      return {
        ...state,
        postSubmitDeliverySuccess: false,
        isLoadingSubmitDelivery: false,
        submitDelivery: null,
        error: {
          ...state.error,
          submitDeliveryError: action.error,
        },
      };

    case types.POST_SUBMITDELIVERY_ASYNC.SUCCESS:
      return {
        ...state,
        postSubmitDeliverySuccess: true,
        isLoadingSubmitDelivery: false,
        submitDelivery: action.data,
      };
    case types.PUT_DELIVERYPRINTDATE_ASYNC.PENDING:
      return {
        ...state,
        putDeliveryPrintDateIsSuccess: false,
        isLoadingDeliveryPrintDate: true,
        deliveryPrintDate: null,
      };

    case types.PUT_DELIVERYPRINTDATE_ASYNC.ERROR:
      return {
        ...state,
        putDeliveryPrintDateIsSuccess: false,
        isLoadingDeliveryPrintDate: false,
        deliveryPrintDate: null,
        error: { ...state.error, deliveryPrintDateError: action.error },
      };

    case types.PUT_DELIVERYPRINTDATE_ASYNC.SUCCESS:
      return {
        ...state,
        putDeliveryPrintDateIsSuccess: true,
        isLoadingDeliveryPrintDate: false,
        deliveryPrintDate: action.data,
      };
    default:
      return state;
  }
};
